import { queryClient } from '@/libs/config/react-query'
import { wagmi } from '@/libs/config/wagmi'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { SDKProvider, useLaunchParams } from '@telegram-apps/sdk-react'
import { App } from 'components/App.tsx'
import { type FC, useEffect } from 'react'
import { Toaster } from 'sonner'
import { WagmiProvider, deserialize, serialize } from 'wagmi'

// const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
//   <div>
//     <p>An unhandled error occurred:</p>
//     <blockquote>
//       <code>
//         {error instanceof Error
//           ? error.message
//           : typeof error === 'string'
//             ? error
//             : JSON.stringify(error)}
//       </code>
//     </blockquote>
//   </div>
// )

const persister = createSyncStoragePersister({
  serialize,
  storage: window.localStorage,
  deserialize,
})

const Inner: FC = () => {
  const debug = useLaunchParams().startParam === 'bearcage_debug'

  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    if (debug) {
      import('eruda').then((lib) => lib.default.init())
    }
  }, [debug])

  return (
    <SDKProvider acceptCustomStyles debug={debug}>
      <WagmiProvider config={wagmi}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <App />
          <Toaster richColors position="top-right" />
        </PersistQueryClientProvider>
      </WagmiProvider>
    </SDKProvider>
  )
}

export const Root: FC = () => (
  // <ErrorBoundary fallback={ErrorBoundaryError}>
  <Inner />
  // </ErrorBoundary>
)
