import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { useWalletAddress } from '@/libs/hooks'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { useUtils } from '@telegram-apps/sdk-react'
import { Text } from 'react-aria-components'
import QRCode from 'react-qr-code'
import { toast } from 'sonner'

const QrCode: React.FC = () => {
  const utils = useUtils()
  const address = useWalletAddress()
  const copyAddress = () => {
    navigator.clipboard.writeText(address as string)
    toast.success('Copied successfully')
  }

  const share = () => {
    utils.shareURL(address as string)
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between">
        <div className="inline-block mt-[50px]">
          <div className="flex flex-col items-center">
            <div className="p-8 bg-white rounded-lg">
              <QRCode value={address as string} />
            </div>
          </div>
          <div className="flex items-center border-[1.5px] border-[#3892CF] px-3 py-4 mt-10 rounded-xl">
            <div className="w-3/4">
              <Text className="break-words text-[14px]">
                {formatWalletAddress(address as string, 20)}
              </Text>
            </div>
            <WalletButton className="h-8 ml-3" onPress={copyAddress}>
              Copy
            </WalletButton>
          </div>
        </div>

        <div className="flex mb-12">
          <WalletButton
            className="max-h-12 h-[48px]"
            color="blue-800"
            onPress={share}
          >
            Share QR code
          </WalletButton>
        </div>
      </Content>
    </Container>
  )
}

export { QrCode }
