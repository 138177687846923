import { useQuery } from '@tanstack/react-query'
import { isAddressEqual } from 'viem'
import { NULL_ADDRESS } from '../constant/addresses'
import {
  getErc20TokenTransactionHistory,
  getNativeTokenTransactionHistory,
} from '../utils/wallet/action'

export function useTransactionHistory(
  walletAddress: string,
  contractAddress: string,
) {
  const { data, ...res } = useQuery({
    queryKey: ['transaction-history', contractAddress],
    queryFn: () =>
      isAddressEqual(contractAddress as `0x${string}`, NULL_ADDRESS)
        ? getNativeTokenTransactionHistory(walletAddress)
        : getErc20TokenTransactionHistory(walletAddress, contractAddress),
    staleTime: 1000 * 60,
  })

  return { histories: data ?? [], ...res }
}
