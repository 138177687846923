import Bend from '@/assets/Images/DAPPS/bend-icon.png'
import BERPS from '@/assets/Images/DAPPS/berps-icon.png'
import BEX from '@/assets/Images/DAPPS/bex-icon.png'
import Honey from '@/assets/Images/DAPPS/honey-icon.png'
import Station from '@/assets/Images/DAPPS/station-icon.png'
import Swap from '@/assets/Images/DAPPS/swap.png'
import CloseModalSvg from '@/assets/svg/close-modal.svg'
import RightArrorw from '@/assets/svg/right-arrow.svg'
import { WalletButton } from '@/components/Button'
import { useViewport } from '@telegram-apps/sdk-react'
import { useState } from 'react'
import { Button } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { Drawer } from 'vaul'
import { CarouselDApps } from '../components/TabDApps/CarouselDApps'

const TabDApps = () => {
  const navigate = useNavigate()
  const [openBEX, setOpenBEX] = useState<boolean>(false)

  const DAppsList = [
    {
      image: BEX,
      name: 'BEX',
      label: "Berachain's Dex",
      detail: 'Swap a variety of tokens effortlessly',
    },
    {
      image: Station,
      name: 'BGT Station',
      label: 'The Hub for BGT Governance',
      detail: 'Engage directly in the govenance of BGT',
    },
    {
      image: Honey,
      name: 'Honey',
      label: "Berachain's Native Stablecoin",
      detail: "A stablecoint that's integral to the Berachain scosystem",
    },
    {
      image: Bend,
      name: 'BEND',
      label: 'Supply Assets & Borrow HONEY',
      detail: 'Supply assets and unlock the potential to borrow HONEY',
    },
    {
      image: BERPS,
      name: 'BERPS',
      label: 'Trade Your Favorite Pairs',
      detail: 'Experience the thrill of high leverage trading',
    },
  ]

  const handleCloseDialog = () => {
    setOpenBEX(false)
  }

  const vp = useViewport()
  const height = vp?.height && vp.height * 0.5
  return (
    <div className="w-full">
      <CarouselDApps />

      <div className="flex items-center justify-between w-full pt-[10px] px-[10px]">
        <p className="text-[#ffffff] text-[16px] font-bold">Bera ecosystem</p>
        <p className="text-[#FFDE5E] text-[14px] font-bold">AII</p>
      </div>
      <div className=" flex flex-col w-[calc(100%-20px)] rounded-[18px] border-[#3892CF] border-solid border-[1.5px] bg-[#52A7E73D] overflow-auto max-h-[calc(100vh-370px)] p-[20px] gap-[10px] mt-[10px] ml-[10px]">
        {DAppsList.map((element) => {
          return (
            <div
              key={element.name}
              onClick={() => element.name === 'BEX' && navigate({
                pathname: '/swap',
              })}
              onKeyDown={() => {}}
              className="flex items-center justify-between border-b-[#52A7E733] border-b-solid border-b-[1px] pb-[10px]"
            >
              <div className="flex gap-[10px]">
                <img
                  src={element.image}
                  alt={element.name}
                  className="w-[48px] h-[48px] rounded-[12px]"
                />
                <div className="flex flex-col items-start justify-center">
                  <p className="text-[#ffffff] text-[14px] font-[500]">
                    {element.name}
                  </p>
                  <p className="text-[#ffffffA3] text-[12px] font-[400]">
                    {element.label}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <img
                  src={RightArrorw}
                  alt="right-arrow"
                  className="h-[16px] w-[16px]"
                />
              </div>
            </div>
          )
        })}
      </div>
      <Drawer.Root
        shouldScaleBackground={false}
        setBackgroundColorOnScale={false}
        noBodyStyles
        open={openBEX}
        onClose={handleCloseDialog}
      >
        <Drawer.Portal>
          <Drawer.Overlay className="fixed inset-0 bg-blue/40 backdrop-blur-sm z-50" />
          <Drawer.Content
            className=" flex flex-col rounded-t-[10px] fixed bottom-5 left-3 right-3 z-50"
            style={{
              height: height,
            }}
          >
            <div className="bg-[#003F60] rounded-2xl h-full overflow-hidden">
              <div className="flex flex-col max-w-md mx-auto relative h-full">
                <Button
                  onPress={handleCloseDialog}
                  className="absolute top-[5px] right-[5px]"
                >
                  <img
                    src={CloseModalSvg}
                    className="w-[28px] h-[28px]"
                    alt="Close"
                  />
                </Button>
                <div
                  className={
                    'overflow-y-auto h-full p-[20px] flex flex-col items-center justify-center gap-[10px]'
                  }
                >
                  <img src={Swap} alt="swap" className="w-full h-[auto]" />
                  <p className="text-[20px] text-[#ffffff] font-bold">
                    Connect to{' '}
                  </p>
                  <p className="text-[20px] text-[#52A7E7] font-bold">
                    bartio.bex.berachain.com ?
                  </p>
                  <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
                    BEX is requesting access to your wallet address
                    0x3bfB...1E07
                  </p>
                  <WalletButton
                    className="w-full max-h-[48px]"
                    onPress={() =>
                      navigate({
                        pathname: '/swap',
                      })
                    }
                  >
                    <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                      Connect wallet
                    </p>
                  </WalletButton>
                </div>
              </div>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </div>
  )
}

export { TabDApps }
