import { useQuery } from '@tanstack/react-query'
import type { Token } from '../types'
import { previewMultiSwap } from '../utils/wallet/swap'

// refresh 3s
const refetchInterval = 5000

export function usePreviewSwap(
  fromToken: Token,
  toToken: Token,
  fromAmount?: number,
) {
  return useQuery({
    queryKey: [
      'get preview swap',
      fromToken?.contract_address,
      toToken?.contract_address,
      fromAmount,
    ],
    queryFn: () =>
      previewMultiSwap({
        mode: 'preview',
        from: fromToken,
        to: toToken,
        amount: Number(fromAmount),
      }),
    enabled: !!fromToken && !!toToken,
    refetchInterval,
  })
}
