import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { NATIVE_TOKEN_ADDRESS } from '@/libs/constant/addresses'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { sendTokenStore } from '@/store/SendTokenStore'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { erc20Abi, isAddressEqual, parseEther, parseUnits } from 'viem'
import { privateKeyToAccount } from 'viem/accounts'
import {
  useSendTransaction,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi'
import { BalanceChange } from './BalanceChange'
import { ModalConfirmTransaction } from './ModalConfirmTransaction'

const ConfirmTransactionPage: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [open, setOpen] = useState(false)

  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)
  const { transactionData } = useSelector(
    sendTokenStore,
    (state) => state.context,
  )

  const {
    data: hashSendNativeToken,
    isPending: isSendingNativeToken,
    sendTransaction: sendNativeToken,
    error: errorSendNativeToken,
  } = useSendTransaction()
  const {
    data: hashSendErc20Token,
    isPending: isSendingErc20Token,
    error: errorSendErc20Token,
    writeContract,
  } = useWriteContract()

  console.log({ hashSendErc20Token })

  const { data: transactionReceipt, isFetching: isFetchingTxReceipt } =
    useWaitForTransactionReceipt({
      hash: hashSendNativeToken || (hashSendErc20Token as `0x${string}`),
      confirmations: 1,
    })

  const handleSendTransaction = () => {
    setOpen(true)
    const { selectedToken, receiver, amount } = transactionData

    const { contract_address } = selectedToken

    if (
      isAddressEqual(contract_address as `0x${string}`, NATIVE_TOKEN_ADDRESS)
    ) {
      sendNativeToken({
        account: signer,
        to: receiver as `0x${string}`,
        value: parseEther(amount.toString()),
      })
    } else {
      writeContract({
        abi: erc20Abi,
        address: contract_address as `0x${string}`,
        functionName: 'transfer',
        args: [
          receiver as `0x${string}`,
          parseUnits(amount.toString(), selectedToken.decimals),
        ],
        account: signer,
      })
    }
  }

  useEffect(() => {
    if (errorSendErc20Token || errorSendNativeToken) {
      setOpen(false)
      toast.error('Transaction failed, please try again', {
        duration: 3000,
      })
    }
  }, [errorSendErc20Token, errorSendNativeToken])

  const closeModal = () => {
    if (transactionReceipt) {
      const { selectedToken } = transactionData
      const { contract_address } = selectedToken

      navigate('/', {
        replace: true,
      })
      queryClient.invalidateQueries({
        queryKey: ['transaction-history', contract_address],
      })
    }
    setOpen(false)
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between">
        <div className="grid gap-4">
          <BalanceChange transactionData={transactionData} />
          <div className="flex justify-between">
            <Text className="text-white text-[14px] font-medium">
              Wallet used
            </Text>
            <Text className="text-white opacity-64 text-[14px] font-medium">
              {formatWalletAddress(activeAccount?.address as string)}
            </Text>
          </div>
          <div className="flex justify-between">
            <Text className="text-white text-[14px] font-medium">
              Network fee
            </Text>
            <Text className="text-white opacity-64 text-[14px] font-medium">
              {Number(transactionData.txFee).toFixed(8)} BERA
            </Text>
          </div>
        </div>
        <WalletButton
          className="max-h-12 mb-12"
          onPress={handleSendTransaction}
        >
          Confirm and Send
        </WalletButton>
        <ModalConfirmTransaction
          open={open}
          setOpen={setOpen}
          title={`Send ${transactionData.selectedToken.symbol}`}
          isSendingTx={
            isSendingErc20Token || isSendingNativeToken || isFetchingTxReceipt
          }
          onClose={closeModal}
        />
      </Content>
    </Container>
  )
}

export { ConfirmTransactionPage }
