import IcInfoSvg from '@/assets/svg/ic-info.svg'
import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { useState } from 'react'
import { Text } from 'react-aria-components'
import { toast } from 'sonner'

const ShowMnemonicPage: React.FC = () => {
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const [show, setShow] = useState(false)

  const copyMnemonic = () => {
    setShow(true)
    navigator.clipboard.writeText(activeAccount?.mnemonic as string)
    toast.success('Copied successfully')
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <Text className="text-white text-xl font-semibold">
              Seed phrase
            </Text>
            <Text className="text-white opacity-64 text-sm font-normal mt-1">
              Write down your seed phrase so you don't lose access to your
              wallet. It is very important!
            </Text>
          </div>

          <div className="flex flex-col">
            <div className="grid grid-cols-3 gap-2">
              {activeAccount?.mnemonic?.split(' ').map((word, index) => (
                <div key={word} className="p-2 rounded-xl bg-[#0C1E2B] ">
                  <Text
                    className={`text-[14px] ${show ? 'blur-none' : 'blur-sm'}`}
                  >
                    {index + 1}. {word}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full p-3 border items-center rounded-xl border-[#FAAD1466]">
            <img src={IcInfoSvg} className="w-6 h-6" alt="info" />
            <Text className="ml-3 text-xs">
              Do not share your Seed Phrase with anyone,{' '}
              <Text className="text-yellow-700 font-semibold">
                and don't import to unverified wallets or bots.
              </Text>
            </Text>
          </div>
        </div>
      </Content>
      <WalletButton className="mb-12 max-h-12" onPress={copyMnemonic}>
        Copy
      </WalletButton>
    </Container>
  )
}

export { ShowMnemonicPage }
