import { useQuery } from '@tanstack/react-query'
import { getRoundData } from '../utils/wallet/nft'

export function useGetRoundInfo(roundIdx: number) {
  const { data, ...res } = useQuery({
    queryKey: ['get-round-info', roundIdx.toString()],
    queryFn: () => getRoundData({ roundIdx }),
  })

  return { roundInfo: data, ...res }
}
