import request from '@/libs/config/request'

export async function createOrUpdateWallet({
  walletAddress,
  createType,
  refCode,
}: {
  walletAddress: string
  createType: 'create' | 'import'
  refCode?: string
}) {
  try {
    await request.post('/wallet', {
      wallet_address: walletAddress,
      create_type: createType,
      ref_code: refCode,
    })
  } catch (error) {
    // biome-ignore lint/complexity/noUselessCatch: <explanation>
    throw error
  }
}
