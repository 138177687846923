import {
  TIME_SHOW_RESULT,
  gameStore,
  useIntervalId,
  useTimer,
} from '@/store/Game'
import { motion } from 'framer-motion'
import { useEffect } from 'react'

const textStyle = {
  fontSize: '50px',
  fontWeight: '700',
  lineHeight: '60px',
  letterSpacing: '-0.03em',
}

const CountdownTimer = () => {
  const timer = useTimer()
  const timerIntervalId = useIntervalId()

  useEffect(() => {
    if (timer === -TIME_SHOW_RESULT || timerIntervalId) return

    const intervalId = setInterval(() => {
      gameStore.send({
        type: 'updateTimer',
      })
    }, 1000)

    gameStore.send({
      type: 'setIntervalId',
      intervalId,
    })
  }, [timer, timerIntervalId])

  const timerText = !timer || timer < 0 ? 0 : timer

  return (
    <div className="flex text-[#FFDE5E]">
      <p style={textStyle}>00:0</p>
      <motion.div
        key={timerText}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          ...textStyle,
          width: '20px',
        }}
      >
        {timerText}
      </motion.div>
    </div>
  )
}

export { CountdownTimer }
