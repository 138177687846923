import { useQuery } from '@tanstack/react-query'
import { checkIsApproved } from '../utils/wallet/stake'

export function useCheckApprovedNft(walletAddress: string) {
  const { data, ...res } = useQuery({
    queryKey: ['check-approved-nft', walletAddress],
    queryFn: () => checkIsApproved({ walletAddress }),
  })

  return { isApproved: data ?? false, ...res }
}
