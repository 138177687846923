import { http, createConfig } from 'wagmi'
import { berachainTestnetbArtio, mainnet } from 'wagmi/chains'
import { walletConnect } from 'wagmi/connectors'

const chain =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? mainnet
    : berachainTestnetbArtio

const projectId = import.meta.env.VITE_WC_PROJECT_ID

export const wagmi = createConfig({
  chains: [chain],
  connectors: [walletConnect({ projectId })],
  transports: {
    [mainnet.id]: http(),
    [berachainTestnetbArtio.id]: http('https://bera-testnet.nodeinfra.com'),
  },
})
