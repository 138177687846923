import request from '@/libs/config/request'
import type { User } from '@/libs/types'

export const getMe = async (startParam: string | undefined = undefined) => {
  try {
    const res = await request.get<User>('/auth/me', {
      ...(startParam && {
        params: {
          start_param: startParam,
        },
      }),
    })
    return res.data
  } catch (error) {
    // biome-ignore lint/complexity/noUselessCatch: <explanation>
    throw error
  }
}
