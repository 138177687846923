import type { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export interface ContentProps extends HTMLAttributes<HTMLDivElement> {}

const Content: React.FC<ContentProps> = (props) => {
  return (
    <div
      {...props}
      className={twMerge('flex flex-1 flex-col pt-5', props.className)}
    />
  )
}

export { Content }
