import { Container } from '@/components/Layout'
import { useTokenValue } from '@/libs/hooks'
import { transactionDetailStore } from '@/store/TransactionDetailStore'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { Text } from 'react-aria-components'
import { History } from './components/TransactionHistory'
import { WalletDetailFeature } from './components/WalletDetailFeature'

const TokenDetailPage: React.FC = () => {
  const { selectedToken } = useSelector(
    transactionDetailStore,
    (state) => state.context,
  )
  const { tokenValueInUSD, balance } = useTokenValue(selectedToken)

  return (
    <Container>
      <Text className="text-center mt-3">Detail</Text>
      <Text className="text-center text-[32px] font-bold mt-5">
        {balance} {selectedToken.symbol}
      </Text>
      <WalletDetailFeature
        token={selectedToken}
        tokenBalanceInUSD={tokenValueInUSD}
      />
      <History token={selectedToken} />
    </Container>
  )
}

export { TokenDetailPage }
