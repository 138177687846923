import { Container, Content } from '@/components/Layout'
import { TransactionType } from '@/libs/types'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { transactionDetailStore } from '@/store/TransactionDetailStore'
import { Title } from '@telegram-apps/telegram-ui'
import { useSelector } from '@xstate/store/react'
import { format } from 'date-fns'
import type React from 'react'
import { Text } from 'react-aria-components'

const TransactionDetailPage: React.FC = () => {
  const { transactionData: transactionDetail } = useSelector(
    transactionDetailStore,
    (state) => state.context,
  )
  return (
    <Container>
      <Text className="text-center mt-3">{transactionDetail.type}</Text>
      <Content className="flex gap-6">
        <Title level="2" plain weight="2" className="text-center">
          {transactionDetail.type === TransactionType.Send
            ? `-${transactionDetail.value}`
            : `+${transactionDetail.value}`}{' '}
          {transactionDetail.symbol}
        </Title>
        <div className="bg-[#52A7E73D] rounded-lg px-[10px] py-4">
          <div className="flex justify-between items-center">
            <Text>Date</Text>
            <Text>
              {format(
                new Date(transactionDetail.timestamp * 1000),
                'E dd, yyyy HH:mm',
              )}
            </Text>
          </div>
          <div className="h-[1px] bg-[#3892CF] my-4" />
          <div className="flex justify-between items-center">
            <Text>Status</Text>
            <Text
              className={`${transactionDetail.status === 0 ? 'text-[#FF3232]' : 'text-[#2FD47B]'}`}
            >
              {transactionDetail.status === 1 ? 'Confirmed' : 'Failed'}
            </Text>
          </div>
          <div className="h-[1px] bg-[#3892CF] my-4" />
          <div className="flex justify-between items-center">
            <Text>From</Text>
            <Text>{formatWalletAddress(transactionDetail.from)}</Text>
          </div>
          <div className="h-[1px] bg-[#3892CF] my-4" />
          <div className="flex justify-between items-center">
            <Text>To</Text>
            <Text>{formatWalletAddress(transactionDetail.to)}</Text>
          </div>
        </div>
      </Content>
    </Container>
  )
}

export { TransactionDetailPage }
