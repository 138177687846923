import type { HTMLAttributes } from 'react'

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  center?: React.ReactNode
  left?: React.ReactNode
  right?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({ center, left, right, ...props }) => {
  const isTitle = typeof center === 'string'
  return (
    <div
      className="h-14 flex items-center justify-between px-1 py-4 z-50"
      style={{
        background: 'linear-gradient(0deg, #52A7E7 0%, #3295DF 100%)',
      }}
      {...props}
    >
      <div className="w-[20%] flex justify-start">{left}</div>
      <div className="w-[70%] flex justify-center">
        {isTitle ? (
          <h1 className="text-white text-base font-semibold">{center}</h1>
        ) : (
          center
        )}
      </div>
      <div className="w-[20%] flex justify-end">{right}</div>
    </div>
  )
}

export { Header }
