import { WalletButton } from '@/components/Button/WalletButton'
import { useWalletAddress } from '@/libs/hooks'
import type { Token } from '@/libs/types'
import { sendTokenStore } from '@/store/SendTokenStore'
import { IconDownload, IconSend } from '@tabler/icons-react'
import type React from 'react'
import { Text } from 'react-aria-components'
import { createSearchParams, useNavigate } from 'react-router-dom'

type Props = {
  token: Token
  tokenBalanceInUSD: number
}

const WalletDetailFeature: React.FC<Props> = ({ token, tokenBalanceInUSD }) => {
  const navigate = useNavigate()
  const activeWalletAddress = useWalletAddress()

  const handleSend = () => {
    sendTokenStore.send({
      type: 'setSelectedToken',
      token: token,
    })

    navigate('/send-token')
  }

  const handleReceive = () => {
    navigate({
      pathname: '/qr-code',
      search: createSearchParams({
        address: activeWalletAddress as string,
      }).toString(),
    })
  }

  return (
    <div
      id="wallet-detail-feature"
      className="flex flex-col justify-center items-center mb-3 pt-2"
    >
      <Text className="text-[#A9A9A9] font-normal">${tokenBalanceInUSD}</Text>
      <div className="w-full flex justify-evenly gap-2 mt-4">
        <WalletButton
          leftIcon={<IconDownload className="size-5" />}
          onPress={handleReceive}
        >
          Receive
        </WalletButton>
        <WalletButton
          leftIcon={<IconSend className="size-5" />}
          onPress={handleSend}
        >
          Send
        </WalletButton>
      </div>
    </div>
  )
}

export { WalletDetailFeature }
