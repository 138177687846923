import CloseSvg from '@/assets/svg/close.svg'
import { WalletButton } from '@/components/Button/WalletButton'
import type React from 'react'
import { Button, Dialog, Modal, Text } from 'react-aria-components'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  handleDeleteAccount: () => void
}

const ModalDeleteAccount: React.FC<Props> = ({
  open,
  setOpen,
  handleDeleteAccount,
}) => {
  return (
    <Modal
      isOpen={open}
      className="w-[90%] rounded-lg bg-blue-200 py-6 px-4"
      onOpenChange={(isOpen) => {
        setOpen(isOpen)
      }}
    >
      <Dialog>
        {({ close }) => (
          <div className="flex flex-col gap-4">
            <div className="text-center relative">
              <Text className="text-center font-semibold">Log Out</Text>
              <Button className="absolute top-0 right-0" onPress={close}>
                <img src={CloseSvg} className="w-[27px] h-[27px]" alt="Close" />
              </Button>
            </div>
            <div>
              <Text className="text-center text-[14px]">
                Log Out will remove your wallet from BearCage. Remember to save
                your Secret Recovery Phrase and Private Key before removing the
                wallet.
              </Text>
            </div>
            <div className="flex justify-center">
              <WalletButton
                className="w-full"
                color="blue-800"
                onPress={() => {
                  setOpen(false)
                }}
              >
                Cancel
              </WalletButton>
              <WalletButton
                className="w-full ml-3"
                onPress={handleDeleteAccount}
              >
                Log Out
              </WalletButton>
            </div>
          </div>
        )}
      </Dialog>
    </Modal>
  )
}

export default ModalDeleteAccount
