import { NFTCard } from '@/components/Token'
import { useGetHoldNfts, useWalletAddress } from '@/libs/hooks'

const NFTAssets: React.FC = () => {
  const walletAddress = useWalletAddress()
  const { nfts } = useGetHoldNfts(walletAddress as string)

  return (
    <div className="flex flex-wrap gap-4 pt-4 pb-2">
      {nfts?.map((nft) => (
        <NFTCard nft={nft} key={`${nft.contract_address}-${nft.token_id}`} />
      ))}
    </div>
  )
}

export { NFTAssets }
