import { useClosingBehavior } from '@telegram-apps/sdk-react'
import { useLayoutEffect } from 'react'

export const useWarningClose = () => {
  const closeBehavior = useClosingBehavior()
  closeBehavior.enableConfirmation()

  useLayoutEffect(() => {
    return () => {
      closeBehavior.disableConfirmation()
    }
  }, [closeBehavior])
}
