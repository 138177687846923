import EthereumSvg from '@/assets/svg/ethereum.svg'
import {
  type Token,
  type TransactionHistory,
  TransactionType,
} from '@/libs/types'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { transactionDetailStore } from '@/store/TransactionDetailStore'
import type React from 'react'
import { Button } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
type Props = {
  transactionHistory: TransactionHistory
  token: Token
}

const HistoryAsset: React.FC<Props> = ({ transactionHistory, token }) => {
  const navigate = useNavigate()

  const transactionDetail = () => {
    transactionDetailStore.send({
      type: 'setTransactionData',
      txData: transactionHistory,
    })
    navigate('/transaction-detail')
  }

  return (
    <Button
      className="flex justify-between items-center border-[1.5px] border-blue-400 rounded-lg  bg-[#52A7E73D] hover:bg-opacity-50 pressed:bg-opacity-70 p-4"
      onPress={transactionDetail}
    >
      <div className="flex justify-center items-center gap-4">
        <div>
          <img
            src={token?.image_url || EthereumSvg}
            alt="logo"
            className="w-6 h-6"
          />
        </div>

        <div className="flex flex-col justify-start items-start text-left">
          <span className="text-base font-semibold">
            {transactionHistory.type}
          </span>
          <span className="text-sm font-normal text-gray-500">
            To: {formatWalletAddress(transactionHistory.to)}
          </span>
        </div>
      </div>
      <div className="flex flex-col text-right">
        <span
          className={`text-base font-medium ${transactionHistory.type === TransactionType.Send ? 'text-[#FF3232]' : 'text-[#2FD47B]'}`}
        >
          {transactionHistory.type === TransactionType.Receive
            ? `+${transactionHistory.value}`
            : `-${transactionHistory.value}`}{' '}
          {transactionHistory.symbol}
        </span>
      </div>
    </Button>
  )
}

export { HistoryAsset }
