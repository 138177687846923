import {
  BOTTOM_BAR_HEIGHT,
  EMBLA_CAROUSEL_HEIGHT,
  HEADER_HEIGHT,
  TAB_HEIGHT,
  WALLET_FEATURE_HEIGHT,
} from '@/libs/utils/constans'

export function calculateListHeight(vpHeight: number | undefined) {
  const walletFeature = document.getElementById('wallet-feature')
  const embleCarousel = document.getElementById('embla-carousel')
  const walletFeatureHeight =
    walletFeature?.offsetHeight ?? WALLET_FEATURE_HEIGHT
  const embleCarouselHeight =
    embleCarousel?.offsetHeight ?? EMBLA_CAROUSEL_HEIGHT

  return (
    Number(vpHeight ?? 0) -
    HEADER_HEIGHT -
    BOTTOM_BAR_HEIGHT -
    walletFeatureHeight -
    embleCarouselHeight -
    TAB_HEIGHT -
    16
  )
}

export function calculateHeight(vpHeight: number | undefined) {
  return Number(vpHeight ?? 0) - HEADER_HEIGHT - BOTTOM_BAR_HEIGHT
}
