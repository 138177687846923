import { useMutation } from '@tanstack/react-query'
import request from '../config/request'
import type { StakeInfo } from '../types'

async function stakeNft(data: {
  tokenId: number
}) {
  const res = await request.post<StakeInfo>('/bera-bonds/stake', data)
  return res.data
}

export const useMutationStakeNft = () => {
  return useMutation({
    mutationFn: (data: { tokenId: number }) => stakeNft(data),
  })
}
