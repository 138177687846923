import { useQuery } from '@tanstack/react-query'
import { useInitData } from '@telegram-apps/sdk-react'
import request from '../config/request'
import type { WhiteListInfo } from '../types'

export const getWhiteListInfo = async () => {
  const res = await request.get<WhiteListInfo>('/bera-bonds')
  return res.data
}

export function useWhiteListInfo() {
  const initData = useInitData()
  const { data, ...res } = useQuery({
    queryKey: ['whitelist-info', initData?.user?.id],
    queryFn: () => getWhiteListInfo(),
    // refetchOnWindowFocus: true,
  })

  return { ...res, whiteListInfo: data }
}
