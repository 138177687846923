import type { Nft } from '@/libs/types'
import { contractAddressList } from '@/libs/utils/contractAddress'
import { stakeStore } from '@/store/StakeStore'
import { useNavigate } from 'react-router-dom'
import { isAddressEqual } from 'viem'

export type NFTCardProps = { nft: Nft }

const NFTCard: React.FC<NFTCardProps> = ({ nft }) => {
  const { name, image_url } = nft
  const navigate = useNavigate()
  const isBeraBondNft = isAddressEqual(
    nft.contract_address as `0x${string}`,
    contractAddressList.BeraBonds,
  )

  const redirectToStake = (nft: Nft) => {
    stakeStore.send({
      type: 'setSelectedNft',
      nft,
    })
    stakeStore.send({
      type: 'setStakeStep',
      step: 'create',
    })
  }

  const redirectToDetail = (nft: Nft) => {
    stakeStore.send({
      type: 'setSelectedNft',
      nft,
    })
    stakeStore.send({
      type: 'setStakeStep',
      step: 'detail',
    })
  }

  const handleClickNft = () => {
    if (isBeraBondNft) {
      if (nft.is_staked) {
        redirectToDetail(nft)
      } else {
        redirectToStake(nft)
      }
      navigate({ pathname: '/stake' })
    }
  }

  return (
    <div
      key={`${nft.contract_address}-${nft.token_id}`}
      className={
        'rounded-[18px] p-[1.5px] w-[calc(50%-10px)] bg-[#52A7E7] relative'
      }
      onClick={handleClickNft}
      onKeyDown={handleClickNft}
    >
      <img
        src={image_url}
        alt={`NFT ${name} ${nft.token_id}`}
        className="w-full h-[auto] rounded-[18px]"
        loading="lazy"
        style={{
          imageRendering: '-webkit-optimize-contrast',
          backfaceVisibility: 'hidden',
        }}
      />
      <div className="p-[10px]">
        <p className="text-[#000000] text-[14px] font-[700]">{name}</p>
        <p className="text-[#000000CC] text-[12px] font-[400]">Bera Chain</p>
      </div>

      {isBeraBondNft && (
        <div className="px-[12px] py-[8px]">
          {nft.is_staked && (
            <div className="px-[6px] py-[4px] bg-[#52C41A] flex items-center justify-center gap-[10px] rounded-[16px]">
              <p className="text-white text-[14px] font-[700]">Staked</p>
            </div>
          )}
          {!nft.is_staked && (
            <div
              className="px-[16px] py-[12px] bg-[#3892CF] flex items-center justify-center gap-[10px] rounded-[16px] h-[32px] current-pointer"
              style={{ boxShadow: '2px 2px 0px 0px #00000040' }}
            >
              <p className="text-[#ffffff] text-[14px] font-[700] m-[0px]">
                Stake Now
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export { NFTCard }
