import { useBackButton } from '@telegram-apps/sdk-react'
import { useLayoutEffect } from 'react'

export const useDisabledBackButtonOnThisPage = () => {
  const backButton = useBackButton()
  if (backButton.supports('hide')) {
    backButton.hide()
  }

  useLayoutEffect(() => {
    return () => {
      if (backButton.supports('show')) {
        backButton.show()
      }
    }
  }, [backButton])
}
