import type { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import type { HeaderProps } from './Header'

export interface ContainerProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<HeaderProps, 'center' | 'left' | 'right'> {}

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  title,
  center,
  left,
  right,
  ...props
}) => {
  const centerHeaderComponent = center ?? title
  const hasHeader = centerHeaderComponent || left || right

  return (
    <div
      className={twMerge('h-screen flex flex-1 flex-col px-4', className)}
      {...props}
    >
      {hasHeader && centerHeaderComponent}

      {children}
    </div>
  )
}

export { Container }
