import type { MemeForm } from '../types'

export const memeForm: MemeForm[] = [
  {
    label: 'Token Name',
    placeholder: 'Token Name',
    field: 'name',
    show: true,
    row: 1,
  },
  {
    label: 'Token Symbol',
    placeholder: 'Token Symbol',
    field: 'symbol',
    show: true,
    row: 1,
  },
  {
    label: 'Description',
    placeholder: 'Description (Optional)',
    field: 'description',
    show: true,
    row: 4,
  },
  {
    label: 'Twitter link',
    placeholder: 'Twitter link',
    field: 'x_url',
    show: false,
    row: 1,
  },
  {
    label: 'Telegram link',
    placeholder: 'Telegram link',
    field: 'telegram_url',
    show: false,
    row: 1,
  },
  {
    label: 'Website',
    placeholder: 'Website',
    field: 'website',
    show: false,
    row: 1,
  },
]
