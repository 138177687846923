import type { TaskInfo } from '@/libs/types/task'
import { generateImageByTask } from '@/libs/utils/task/task'
import { useMemo } from 'react'
import { Card } from './Card'

interface Props {
  tasks: TaskInfo[]
  handleChangeTask: (id: number) => void
  handleClaimTask: (id: number) => void
}

export function StandardTask({
  tasks,
  handleChangeTask,
  handleClaimTask,
}: Props) {
  const oneTimeTasks = useMemo(() => {
    return tasks.filter(
      (task) =>
        task.task.frequency_type === 'one_time' &&
        task.task.task_type !== 'partner',
    )
  }, [tasks])

  const otherTasks = useMemo(() => {
    return tasks.filter(
      (task) =>
        task.task.frequency_type !== 'one_time' &&
        task.task.task_type !== 'partner',
    )
  }, [tasks])

  return (
    <div className="w-full max-h-[calc(100vh-280px)] overflow-auto">
      {otherTasks?.length > 0 && (
        <p className="text-[#52A7E7] text-[18px] font-bold mb-[10px] font-['DM Sans']">
          Daily
        </p>
      )}
      {otherTasks?.length > 0 && (
        <div className="border-[1.5px] border-[#3892CF] rounded-3xl bg-[#52A7E73D] flex flex-col gap-[10px] p-[10px] mb-[20px]">
          {otherTasks.map((element, index: number) => {
            return (
              <Card
                key={element.task.id}
                avatar={
                  element?.task?.image_url
                    ? element?.task?.image_url
                    : generateImageByTask(element)
                }
                name={element.task.title}
                value={`+${element.task.reward_point.toLocaleString('en-US')}`}
                unit="$BECA"
                status={
                  element.task.statusTask as
                    | 'GO'
                    | 'CLAIM'
                    | 'LOADING'
                    | 'SUCCESS'
                }
                handleChangeTask={handleChangeTask}
                index={index}
                task={element}
                id={element.task.id}
                handleClaimTask={handleClaimTask}
              />
            )
          })}
        </div>
      )}
      {oneTimeTasks?.length > 0 && (
        <p className="text-[#52A7E7] text-[18px] font-bold mb-[10px] font-[DM Sans]">
          Basic Tasks
        </p>
      )}
      {oneTimeTasks?.length > 0 && (
        <div className="border-[1.5px] border-[#3892CF] rounded-3xl bg-[#52A7E73D] flex flex-col gap-[10px] p-[10px] ">
          {oneTimeTasks.map((element, index: number) => {
            return (
              <Card
                key={element.task.id}
                avatar={
                  element?.task?.image_url
                    ? element?.task?.image_url
                    : generateImageByTask(element)
                }
                name={element.task.title}
                value={`+${element.task.reward_point.toLocaleString('en-US')}`}
                unit="$BECA"
                status={
                  element.task.statusTask as
                    | 'GO'
                    | 'CLAIM'
                    | 'LOADING'
                    | 'SUCCESS'
                }
                handleChangeTask={handleChangeTask}
                index={index}
                task={element}
                id={element.task.id}
                handleClaimTask={handleClaimTask}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
