import WalletDefaultAvt from '@/assets/Images/wallet-default.png'
import ArrowDownSvg from '@/assets/svg/arrow-chervon-down.svg'
import EthereumSvg from '@/assets/svg/ethereum.svg'
import type { TransactionInfo } from '@/libs/types'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import type React from 'react'
import { Text } from 'react-aria-components'

type Props = {
  transactionData: TransactionInfo
}

const BalanceChange: React.FC<Props> = ({ transactionData }) => {
  return (
    <div className="flex flex-col border-[1.5px] border-blue-400 p-4 rounded-xl bg-[#52A7E73D] gap-3">
      <div className="flex justify-between">
        <div className="flex items-center">
          <img
            src={transactionData.selectedToken?.image_url || EthereumSvg}
            alt="logo"
            className="w-6 h-6"
          />
          <Text className="text-[14px] text-white ml-3 font-semibold">
            {transactionData.selectedToken.symbol}
          </Text>
        </div>
        <Text>{transactionData.amount}</Text>
      </div>
      <img src={ArrowDownSvg} alt="arrow" className="w-6 h-6" />
      <div className="flex justify-between">
        <div className="flex items-center">
          <img src={WalletDefaultAvt} alt="wallet" className="w-6 h-6" />
          <Text className="text-[14px] text-white ml-3 font-semibold">
            {formatWalletAddress(transactionData.receiver)}
          </Text>
        </div>
      </div>
    </div>
  )
}

export { BalanceChange }
