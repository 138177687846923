import { stakeStore } from '@/store/StakeStore'
import { useQuery } from '@tanstack/react-query'
import { useInitData } from '@telegram-apps/sdk-react'
import { useSelector } from '@xstate/store/react'
import { type Nft, TokenType } from '../types'
import { BERA_BOND_IMAGE_URL } from '../utils/constans'
import { contractAddressList } from '../utils/contractAddress'
import { getBeraBondsNftByWalletAddress } from '../utils/wallet/nft'
import { getStakedNft } from '../utils/wallet/stake'

const stakedNft = async (
  walletAddress: `0x${string}`,
  unstakedNft: Nft | null,
) => {
  const nftIds = await getStakedNft(walletAddress)
  const nfts = await getBeraBondsNftByWalletAddress(walletAddress)
  const nftsFromOnChain: Nft[] = nftIds.map((id) => {
    return {
      id: Number(id),
      erc1155_id: Number(id),
      is_display: true,
      token_id: Number(id),
      name: `BeraBonds #${id}`,
      contract_address: contractAddressList.BeraBonds,
      image_url: BERA_BOND_IMAGE_URL,
      token_type: TokenType.ERC721,
      symbol: 'BERABONDS',
      is_staked: true,
    }
  })

  // merge nfts and nftsFromOnChain without duplicate token_id, if has duplicate token_id, keep the nft from nftsFromOnChain
  const listNfts = [...nftsFromOnChain, ...nfts].filter(
    (nft, index, self) =>
      index === self.findIndex((t) => t.token_id === nft.token_id),
  )

  // check if unstakedNft is not in listNfts
  if (
    unstakedNft &&
    !listNfts.some((nft) => nft.token_id === unstakedNft.token_id)
  ) {
    listNfts.push({
      ...unstakedNft,
      is_staked: false,
    })
  }

  // sort listNfts by token_id
  listNfts.sort((a, b) => a.token_id - b.token_id)
  return listNfts
}

export function useStakeNfts(walletAddress: `0x${string}`) {
  const initData = useInitData()
  const { unstakedNft } = useSelector(stakeStore, (state) => state.context)

  const userId = initData?.user?.id

  const { data, ...res } = useQuery({
    queryKey: ['stake-nfts', userId],
    queryFn: () => stakedNft(walletAddress, unstakedNft),
  })

  return { data: data ?? [], ...res }
}
