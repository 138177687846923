import type React from 'react'
import { Text } from 'react-aria-components'
import QRCode from 'react-qr-code'

type Props = {
  privateKey: string
}

const PrivateKeyQr: React.FC<Props> = ({ privateKey }) => {
  return (
    <div className="flex flex-col justify-center items-center mt-5 gap-5">
      <div className="bg-white rounded-lg py-[32px] px-[16px]">
        <QRCode value={privateKey as string} className="h-[200px]" />
      </div>
      <div className="w-full text-center px-8">
        <Text className="break-words text-[14px] font-normal">
          {privateKey}
        </Text>
      </div>
    </div>
  )
}

export { PrivateKeyQr }
