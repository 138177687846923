import type { CloudStorage } from '@telegram-apps/sdk-react'

export type AppStorageType = CloudStorage | Storage

export const isTelegramCloudStorage = (
  storage: AppStorageType,
): storage is CloudStorage => {
  return 'get' in storage
}

export class AppStorage {
  private static instance: AppStorage | null = null
  private constructor(private readonly storage: AppStorageType) {}

  public static getInstance(storage: AppStorageType): AppStorage {
    if (!AppStorage.instance) {
      AppStorage.instance = new AppStorage(storage)
    }
    return AppStorage.instance
  }

  async get(key: string) {
    if (isTelegramCloudStorage(this.storage)) {
      return await this.storage.get(key)
    }

    return this.storage.getItem(key)
  }

  async set(key: string, value: string): Promise<void> {
    if (isTelegramCloudStorage(this.storage)) {
      await this.storage.set(key, value)
    } else {
      this.storage.setItem(key, value)
    }
  }

  async remove(key: string): Promise<void> {
    if (isTelegramCloudStorage(this.storage)) {
      await this.storage.delete(key)
    } else {
      this.storage.removeItem(key)
    }
  }
}
