import { useQuery } from '@tanstack/react-query'
import { useInitData } from '@telegram-apps/sdk-react'
import request from '../config/request'
import type { GameInfo } from '../types'

async function checkGame() {
  const res = await request.get<GameInfo>('tasks/count')
  return res.data
}

export const useCheckGame = () => {
  const initData = useInitData()
  return useQuery({
    queryKey: ['check-game', initData?.user?.id],
    queryFn: () => checkGame(),
    refetchOnWindowFocus: true,
  })
}
