import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { LoadingFullScreen } from '@/components/Loading'
import { NATIVE_TOKEN_ADDRESS } from '@/libs/constant/addresses'
import { useKeyboardVisibility } from '@/libs/hooks/useKeyboardVisibility'
import type { Token } from '@/libs/types'
import { addCommasToNumber } from '@/libs/utils/number'
import {
  estimateGasSendToken,
  getBalance,
  getTokens,
} from '@/libs/utils/wallet/action'
import { sendTokenStore } from '@/store/SendTokenStore'
import { walletStore } from '@/store/WalletStore'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useState } from 'react'
import { Button, Text } from 'react-aria-components'
import { NumericFormat } from 'react-number-format'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { isAddressEqual } from 'viem'
import { ModalSelectToken } from './ModalSelectToken'

const InputAmount: React.FC = () => {
  useKeyboardVisibility()
  const navigate = useNavigate()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const { selectedToken } = useSelector(
    sendTokenStore,
    (state) => state.context,
  )

  const [open, setOpen] = useState(false)
  const [amount, setAmount] = useState('')
  const [errAmount, setErrAmount] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['tokens-send', activeAccount?.address],
    queryFn: () => getTokens(),
    refetchOnWindowFocus: false,
  })
  const { data: balance } = useQuery({
    queryKey: [
      'balance',
      selectedToken?.contract_address,
      activeAccount?.address,
    ],
    queryFn: () => getBalance(activeAccount?.address as string, selectedToken),
    refetchOnWindowFocus: false,
  })

  const { data: transactionFee, isLoading: isLoadingEstimateGas } = useQuery({
    queryKey: [
      'estimate-gas',
      selectedToken?.contract_address,
      activeAccount?.address,
      amount,
    ],
    queryFn: () =>
      estimateGasSendToken(
        activeAccount?.address as string,
        import.meta.env.VITE_ESTIMATE_GAS_WALLET,
        Number(amount),
        selectedToken as Token,
      ),
    enabled: !!selectedToken && !!amount,
    refetchOnWindowFocus: false,
  })

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrAmount(undefined)
    let value = event.target.value
    // remove commas
    value = value.replace(/,/g, '')
    // Only allow digits and at most one dot
    value = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1')
    // If the value starts with a dot, prepend a zero
    if (value.startsWith('.')) {
      value = `0${value}`
    }
    if (
      isAddressEqual(
        selectedToken?.contract_address as `0x${string}`,
        NATIVE_TOKEN_ADDRESS,
      )
    ) {
      if (Number(value) > Number(balance) + Number(transactionFee || 0)) {
        setErrAmount('Insufficient balance to pay transaction fee')
      }
    } else if (Number(value) > Number(balance)) {
      setErrAmount('Insufficient balance')
    }

    setAmount(value)
  }

  const handlePressMax = () => {
    if (balance && Number(balance) > 0) {
      if (
        isAddressEqual(
          selectedToken?.contract_address as `0x${string}`,
          NATIVE_TOKEN_ADDRESS,
        )
      ) {
        const maxBalance = Number(balance) - Number(transactionFee)
        setAmount(maxBalance.toString())
      } else {
        setAmount(balance.toString())
      }
    }
  }

  return isLoading && !data ? (
    <LoadingFullScreen />
  ) : (
    data && data?.length > 0 && selectedToken && (
      <Container>
        <Content className="flex flex-1 flex-col justify-between py-5">
          <div className="grid gap-4 mt-20">
            <div className="flex justify-center">
              <Text className="text-center opacity-64">
                Available: {balance} {selectedToken.symbol}
              </Text>
              <Button className="text-yellow ml-2" onPress={handlePressMax}>
                MAX
              </Button>
            </div>
            <div className="flex items-center justify-center">
              <NumericFormat
                autoFocus
                allowedDecimalSeparators={['.', ',']}
                thousandSeparator=","
                allowNegative={false}
                allowLeadingZeros={false}
                value={addCommasToNumber(amount)}
                onChange={handleInput}
                inputMode="decimal"
                placeholder="0"
                className="flex flex-1 w-full text-5xl font-semibold text-center bg-transparent placeholder-white"
              />
              <Text className="text-white text-5xl font-semibold ml-2 opacity-20">
                {selectedToken.symbol}
              </Text>
            </div>
            {errAmount && <p className="text-red-500 text-sm">{errAmount}</p>}
          </div>

          <WalletButton
            isDisabled={
              !selectedToken || !amount || !!errAmount || isLoadingEstimateGas
            }
            className="max-h-12 mb-12"
            onPress={() => {
              navigate({
                pathname: 'input-receiver',
                search: createSearchParams({
                  amount: amount,
                  txFee: Number(transactionFee).toString(),
                }).toString(),
              })
            }}
          >
            Continue
          </WalletButton>
        </Content>
        <ModalSelectToken
          data={
            data.filter(
              (token) =>
                !isAddressEqual(
                  token.contract_address as `0x${string}`,
                  selectedToken.contract_address as `0x${string}`,
                ),
            ) as Token[]
          }
          open={open}
          setOpen={setOpen}
        />
      </Container>
    )
  )
}

export { InputAmount }
