import CupSvg from '@/assets/svg/cup.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import type { ResultType } from '@/libs/types'
import { formatNumber } from '@/libs/utils/number'
import { gameStore, useGameStatus, useResult } from '@/store/Game'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import styles from '../styles.module.css'

interface IResultData {
  title: string
  className: string
}

interface IStreakData {
  className: string
}

const resultData: Record<ResultType, IResultData> = {
  win: {
    title: 'WIN',
    className: 'text-[#52C41A]',
  },
  lose: {
    title: 'MISS',
    className: 'text-[#F5222D]',
  },
}

const streakClassName: Record<ResultType, IStreakData> = {
  win: {
    className: 'text-[#52C41A] border-[#52C41A]',
  },
  lose: {
    className: 'text-[#F5222D] border-[#F5222D]',
  },
}

const renderResultClassName = (percentageDiff: number) => {
  if (percentageDiff === 0) {
    return 'opacity-65'
  }

  return percentageDiff > 0 ? 'text-[#52C41A]' : 'text-[#FAC808]'
}

export const ResultTransition = () => {
  const gameStatus = useGameStatus()
  const result = useResult()
  const [isHolding, setIsHolding] = useState(false)

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  const elementVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '-10vh',
      opacity: 1,
      transition: { type: 'spring', stiffness: 50 },
    },
    exit: {
      y: '-100vh',
      opacity: 0,
      transition: { type: 'spring', stiffness: 50 },
    },
  }

  const handleTouchStart = () => {
    setIsHolding(true)
    gameStore.send({
      type: 'setHolding',
      isHolding: true,
    })
  }

  const handleTouchEnd = () => {
    setIsHolding(false)
    gameStore.send({
      type: 'setHolding',
      isHolding: false,
    })
  }

  const isShowResult = !!((gameStatus === 'RESULT' || isHolding) && result)

  return (
    <div className="flex justify-center items-center h-screen select-none">
      {isShowResult ? (
        <AnimatePresence>
          <>
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={overlayVariants}
              className={twMerge('fixed inset-0 z-[1000]', styles.overlay)}
            />

            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={elementVariants}
              className={twMerge(
                'fixed inset-0 w-full flex items-center justify-center p-6 z-[1001]',
              )}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="flex flex-col gap-2 items-center bg-red">
                <div className="flex items-center">
                  <p
                    className={twMerge(
                      'text-[64px] font-bold leading-[76.8px] tracking-[-0.03em]',
                      resultData[result.result].className,
                    )}
                  >
                    {resultData[result.result].title}
                  </p>
                  <div
                    className={twMerge(
                      'border-[2px] rounded-2xl px-3 py-[2px] ml-2 text-[14px]',
                      streakClassName[result.result].className,
                    )}
                  >
                    X{result.streak}
                  </div>
                </div>
                <div className="flex gap-2">
                  <img src={CupSvg} className="w-5 h-5" alt="Cup" />
                  <p className="font-bold text-center text-sm leading-6 tracking-tight">
                    BTC Price{' '}
                    <span className="text-[#FFDE5E]">
                      ${formatNumber(result.currentPrice)}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2 justify-center items-center">
                  <span
                    className={renderResultClassName(result.percentageDiff)}
                  >
                    {result.percentageDiff > 0 && '+'}
                    {result.percentageDiff.toFixed(6)}%
                  </span>
                </div>
                {result.result === 'win' && result.winPoint ? (
                  <div className="flex flex-col items-center justify-center gap-2 border rounded-xl border-[#FFFFFF33] max-w-[163px] w-full py-4 mt-2">
                    <p className="text-white text-base font-semibold opacity-64">
                      Reward
                    </p>
                    <div className="flex gap-2 items-center justify-center">
                      <p className="text-[#FFDE5E] text-base font-semibold">
                        +{formatNumber(result.winPoint, 0)}
                      </p>
                      <img
                        src={BeraCoinImg}
                        className="w-6 h-6"
                        alt="BeraCoin"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </motion.div>
          </>
        </AnimatePresence>
      ) : null}
    </div>
  )
}
