import { useQuery } from '@tanstack/react-query'
import { erc1155BalanceOfBatch } from '../utils/wallet/action'
import { useStakeNfts } from './useStakeNfts'

export function useGetHoldNfts(walletAddress: string) {
  const { data: beraBondNfts } = useStakeNfts(walletAddress as `0x${string}`)
  const { data, ...res } = useQuery({
    queryKey: ['getHoldNfts', walletAddress],
    queryFn: () => erc1155BalanceOfBatch(walletAddress),
  })

  const nfts = [...beraBondNfts, ...(data ?? [])]

  return { nfts, ...res }
}
