import request from '@/libs/config/request'
import { NATIVE_TOKEN_ADDRESS } from '@/libs/constant/addresses'
import { PREFIX_API_EVM } from '@/libs/constant/prefixApiEvm'
import { becaNftAbi } from '@/libs/evm/nft/beca-nft'
import { getNfts } from '@/libs/hooks'
import {
  type AddLiquidityTransactionInfo,
  type ChainBaseTokenPriceResponse,
  type ListAccount,
  type MemeToken,
  type Nft,
  type Referral,
  type ReferralBonus,
  type SwapTransactionInfo,
  type Token,
  type TokenPriceBinanceResponse,
  TokenType,
  type TransactionHistory,
  TransactionType,
} from '@/libs/types'
import type { TaskInfo } from '@/libs/types/task'
import { Alchemy, Network } from 'alchemy-sdk'
import axios from 'axios'
import { format } from 'date-fns'
import { sortBy } from 'es-toolkit'
import {
  http,
  bytesToHex,
  createPublicClient,
  decodeFunctionData,
  erc20Abi,
  formatEther,
  formatGwei,
  formatUnits,
  isAddressEqual,
  parseUnits,
} from 'viem'
import { mnemonicToAccount, privateKeyToAccount } from 'viem/accounts'
import { berachainTestnetbArtio, mainnet } from 'viem/chains'
import { NFT_IDS } from '../constans'

const clientMainnet = createPublicClient({
  chain: mainnet,
  transport: http(),
})

const client = createPublicClient({
  chain:
    import.meta.env.VITE_NODE_ENV === 'production'
      ? mainnet
      : berachainTestnetbArtio,
  transport: http(),
})

const config = {
  apiKey: import.meta.env.VITE_ALCHEMY_API_KEY,
  network: Network.ETH_SEPOLIA,
}
const alchemy = new Alchemy(config)

export function isNativeToken(token: Token) {
  if (token?.contract_address) {
    return isAddressEqual(
      token.contract_address as `0x${string}`,
      NATIVE_TOKEN_ADDRESS,
    )
  }
  return false
}

export function formatWalletAddress(address: string, max = 10) {
  let formattedAddress = ''
  if (address) {
    const lastCharacters = address.slice(-4)

    formattedAddress = `${address.substring(0, max)}...${lastCharacters}`
  }

  return formattedAddress
}

export function loadWalletFromMnemonics(mnemonics: string) {
  const account = mnemonicToAccount(mnemonics.trim())
  const hdKey = account.getHdKey()
  const privateKey = bytesToHex(hdKey.privateKey as Uint8Array)
  return { account: account, privateKey: privateKey }
}

export function loadWalletFromPrivateKey(privateKey: string) {
  //check if private key starts with 0x
  const _privateKey = privateKey.startsWith('0x')
    ? privateKey
    : `0x${privateKey}`

  const account = privateKeyToAccount(_privateKey as `0x${string}`)
  return { account: account, privateKey: _privateKey }
}

export function decodeTransaction(data: string) {
  const { args } = decodeFunctionData({
    abi: erc20Abi,
    data: data as `0x${string}`,
  })
  return args
}

export async function getUsdPrice(contractAddress: string) {
  // https://api.binance.com/api/v3/ticker/24hr?symbol=ETHUSDT
  const res = await axios.get<ChainBaseTokenPriceResponse>(
    `https://api.chainbase.online/v1/token/price?chain_id=1&contract_address=${contractAddress}`,
    {
      headers: {
        'x-api-key': import.meta.env.VITE_CHAINBASE_API_KEY,
      },
    },
  )
  return res.data.data
}

export async function getUsdPriceBinance(symbol: string) {
  const _symbol = symbol === 'USDT' ? 'TUSD' : symbol
  const res = await axios.get<TokenPriceBinanceResponse>(
    `https://api.binance.com/api/v3/ticker/24hr?symbol=${_symbol}USDT`,
  )
  return res.data
}

export async function getTokensByWallet() {
  const tokenBeraChain: Token[] = [
    {
      id: 1,
      contract_address: NATIVE_TOKEN_ADDRESS,
      name: 'BERA',
      symbol: 'BERA',
      decimals: 18,
      image_url: null,
      token_type: TokenType.ERC20,
      is_display: true,
      is_native_token: true,
      is_sendable: true,
    },
    {
      id: 2,
      contract_address: '0x05D0dD5135E3eF3aDE32a9eF9Cb06e8D37A6795D',
      name: 'USDT',
      symbol: 'USDT',
      decimals: 6,
      image_url: null,
      token_type: TokenType.ERC20,
      is_display: true,
      is_native_token: true,
      is_sendable: true,
    },
  ]

  //wait 5 seconds
  await new Promise((resolve) => setTimeout(resolve, 5000))

  return tokenBeraChain
}

export async function getTokens(sendable = true) {
  try {
    const res = await request.get<Token[]>('/wallet/token')
    if (sendable) {
      return res.data.filter((token) => token.is_sendable)
    }

    const tokenData = res.data
    const tokenSorted = sortBy(tokenData, ['is_sendable'])
    return tokenSorted
  } catch (error) {
    console.log('get token error', error)
    return []
  }
}

export async function getReferral() {
  try {
    const res = await request.get<Referral[]>('/referral')
    return res.data
  } catch (error) {
    console.log('get referral error', error)
    return []
  }
}

export async function getReferralBonus() {
  try {
    const res = await request.get<ReferralBonus>('/referral/count')
    return res.data
  } catch (error) {
    console.log('get referral bonus error', error)
  }
}

export async function getCheckInInfo() {
  try {
    const res = await request.get('/check-in')
    return res.data
  } catch (error) {
    console.log('get referral error', error)
    return []
  }
}

export async function checkIn() {
  try {
    const res = await request.post('/check-in')
    return res.data
  } catch (error) {
    // biome-ignore lint/complexity/noUselessCatch: <explanation>
    throw error
  }
}

export async function getRankAccount(type: string) {
  try {
    const res = await request.get<ListAccount[]>(`/leader-board?type=${type}`)
    return res.data
  } catch (error) {
    console.log('get token error', error)
    return []
  }
}

export async function getTaskList() {
  try {
    const res = await request.get<TaskInfo[]>('/tasks')
    return res.data
  } catch (error) {
    console.log('get token error', error)
    return []
  }
}

export async function claimTask({
  id,
  claimByValue,
}: { id: number; claimByValue?: string }) {
  try {
    const res = await request.post(`/tasks/${id}/claim`, {
      claim_by_value: claimByValue,
    })
    return res.data
  } catch (error) {
    // biome-ignore lint/complexity/noUselessCatch: <explanation>
    throw error
  }
}

export async function completeTask(id: number) {
  try {
    const res = await request.post(`/tasks/${id}/complete`)
    return res.data
  } catch (error) {
    // biome-ignore lint/complexity/noUselessCatch: <explanation>
    throw error
  }
}

export async function getNativeTokenTransactionHistory(walletAddress: string) {
  let history: TransactionHistory[] = []

  const scanExplorerApi = `${PREFIX_API_EVM}?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=desc&apikey=YourApiKeyToken`
  const data = await axios.get(scanExplorerApi)
  if (data?.data?.status === '0') {
    history = []
  } else {
    const result = data?.data?.result
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const filterResult = result.filter((item: any) => item.methodId === '0x')
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    history = filterResult.map((item: any) => {
      return {
        from: item.from,
        to: item.to,
        value: Math.floor(Number(formatEther(item.value)) * 1e6) / 1e6,
        hash: item.hash,
        timestamp: Number(item.timeStamp),
        nonce: Number(item.nonce),
        contractAddress: null,
        type: isAddressEqual(item.from, walletAddress as `0x${string}`)
          ? TransactionType.Send
          : TransactionType.Receive,
        symbol: 'ETH',
        decimal: 18,
        status: Number(item.txreceipt_status),
      }
    })
  }
  return history
}

export async function getErc20TokenTransactionHistory(
  walletAddress: string,
  contractAddress: string,
) {
  let history: TransactionHistory[] = []

  const scanExplorerApi = `${PREFIX_API_EVM}?module=account&action=tokentx&address=${walletAddress}&contractAddress=${contractAddress}&startblock=0&endblock=99999999&sort=desc&apikey=YourApiKeyToken`
  const data = await axios.get(scanExplorerApi)
  if (data?.data?.status === '0') {
    history = []
  } else {
    const result = data?.data?.result

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    history = result.map((item: any) => {
      return {
        from: item.from,
        to: item.to,
        value:
          Math.floor(Number(formatUnits(item.value, item.tokenDecimal)) * 1e6) /
          1e6,
        hash: item.hash,
        timestamp: Number(item.timeStamp),
        nonce: Number(item.nonce),
        contractAddress: null,
        type: isAddressEqual(item.from, walletAddress as `0x${string}`)
          ? TransactionType.Send
          : TransactionType.Receive,
        symbol: item.tokenSymbol,
        decimal: item.tokenDecimal,
        status: item?.confirmations !== '' ? 1 : 0,
      }
    })
  }
  return history
}

export async function getBalance(walletAddress: string, token?: Token) {
  let balance = 0
  if (
    isAddressEqual(
      token?.contract_address as `0x${string}`,
      NATIVE_TOKEN_ADDRESS,
    )
  ) {
    const balanceBN = await client.getBalance({
      address: walletAddress as `0x${string}`,
    })
    balance = Number(formatEther(balanceBN))
  } else {
    const data = await client.readContract({
      address: token?.contract_address as `0x${string}`,
      abi: erc20Abi,
      functionName: 'balanceOf',
      args: [walletAddress as `0x${string}`],
    })
    balance = Number(formatUnits(data, Number(token?.decimals)))
  }
  balance = Math.floor(balance * 1e8) / 1e8
  return balance
}

export async function getBeraBalance(walletAddress: string) {
  const balanceBN = await client.getBalance({
    address: walletAddress as `0x${string}`,
  })
  const balance = Number(formatEther(balanceBN))
  const formatBalance = Math.floor(balance * 1e8) / 1e8
  return formatBalance
}

export async function getBalanceNativeToken(walletAddress: string) {
  const balance = await client.getBalance({
    address: walletAddress as `0x${string}`,
  })
  const formatBalance = formatEther(balance)
  return formatBalance
}

export async function getBalanceMemeToken(
  walletAddress: string,
  token?: MemeToken,
) {
  const data = await client.readContract({
    address: token?.address as `0x${string}`,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [walletAddress as `0x${string}`],
  })
  const balance = Number(formatEther(data))

  const formatBalance = Math.floor(balance * 1e8) / 1e8
  return formatBalance
}

export async function getNftsByWallet(walletAddress: string) {
  const nfts = await alchemy.nft.getNftsForOwner(walletAddress)
  return nfts
}

export async function estimateGasSendToken(
  walletAddress: string,
  receiver: string,
  amount: number,
  token: Token,
) {
  try {
    let gasLimit = '0'
    const { maxFeePerGas } = await client.estimateFeesPerGas()

    const maxFee = formatGwei(maxFeePerGas)
    if (
      isAddressEqual(
        token?.contract_address as `0x${string}`,
        NATIVE_TOKEN_ADDRESS,
      )
    ) {
      gasLimit = '21000'
    } else {
      const gas = await client.estimateContractGas({
        address: token.contract_address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'transfer',
        args: [
          receiver as `0x${string}`,
          parseUnits(amount.toString(), token.decimals),
        ],
        account: walletAddress as `0x${string}`,
      })
      gasLimit = gas.toString()
    }
    const estimateFee = (Number(gasLimit) * Number(maxFee)) / 1e9
    const formatFee = Math.floor(estimateFee * 1e8) / 1e8
    return formatFee
  } catch (error) {
    console.log({ error })
  }
}

export async function checkFaucet(walletAddress: string): Promise<boolean> {
  const faucetContractAddress = import.meta.env.VITE_FAUCET_CONTRACT
  let check = false

  const scanExplorerApi = `${PREFIX_API_EVM}?module=account&action=txlistinternal&address=${walletAddress}&startblock=0&endblock=99999999&sort=desc&apikey=YourApiKeyToken`
  const data = await axios.get(scanExplorerApi)
  if (data?.data?.status === '0') {
    check = false
  } else {
    const result = data?.data?.result
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const filterResult = result.filter((item: any) =>
      isAddressEqual(item.from, faucetContractAddress as `0x${string}`),
    )
    const today = format(new Date(), 'yyyy-MM-dd')
    // check if timestamp is in day
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    check = filterResult.some((item: any) => {
      const timestamp = Number(item.timeStamp)
      console.log({ timestamp })
      const date = format(new Date(timestamp * 1000), 'yyyy-MM-dd')
      return date === today
    })
  }
  return check
}

export async function checkSendToken(walletAddress: string): Promise<boolean> {
  const beraWalletAddress = import.meta.env.VITE_BERA_WALLET
  let check = false

  const scanExplorerApi = `${PREFIX_API_EVM}?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=desc&apikey=YourApiKeyToken`
  const data = await axios.get(scanExplorerApi)
  if (data?.data?.status === '0') {
    check = false
  } else {
    const result = data?.data?.result
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const filterResult = result.filter((item: any) =>
      isAddressEqual(item.to, beraWalletAddress as `0x${string}`),
    )
    const today = format(new Date(), 'yyyy-MM-dd')

    // check if timestamp is in day
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    check = filterResult.some((item: any) => {
      const timestamp = Number(item.timeStamp)
      const date = format(new Date(timestamp * 1000), 'yyyy-MM-dd')
      return date === today
    })
  }
  return check
}

export async function checkMintedNft(walletAddress: string): Promise<boolean> {
  const beraCollectionAddress = import.meta.env.VITE_BECA_NFT_COLLECTION_ADDRESS
  let check = false

  const scanExplorerApi = `${PREFIX_API_EVM}?module=account&action=token1155tx&address=${walletAddress}&contractAddress=${beraCollectionAddress}&startblock=0&endblock=99999999&sort=desc&apikey=YourApiKeyToken`
  const data = await axios.get(scanExplorerApi)
  if (data?.data?.status === '0') {
    check = false
  } else {
    const result = data?.data?.result
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const filterResult = result.filter((item: any) =>
      isAddressEqual(item.to, walletAddress as `0x${string}`),
    )

    const today = format(new Date(), 'yyyy-MM-dd')
    // check if timestamp is in day
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    check = filterResult.some((item: any) => {
      const timestamp = Number(item.timeStamp)
      const date = format(new Date(timestamp * 1000), 'yyyy-MM-dd')
      return date === today
    })
  }
  return check
}

export async function getBalanceETHMainnet(walletAddress: string) {
  const balance = await clientMainnet.getBalance({
    address: walletAddress as `0x${string}`,
  })
  const formatBalance = formatEther(balance)
  return formatBalance
}

export async function erc1155BalanceOfBatch(walletAddress: string) {
  try {
    const accounts = NFT_IDS.map(() => walletAddress as `0x${string}`)
    const contractAddress = import.meta.env.VITE_BECA_NFT_COLLECTION_ADDRESS
    const data = (await client.readContract({
      address: contractAddress as `0x${string}`,
      abi: becaNftAbi,
      functionName: 'balanceOfBatch',
      args: [accounts, NFT_IDS],
    })) as number[]

    // remove "0" from data
    const dataFilter = data.filter((item: number) => Number(item) !== 0)
    // convert data to {tokenId: number, balance: number}
    const ownerNfts = dataFilter.map((_: number, index: number) => ({
      tokenId: NFT_IDS[index],
    }))
    const nftsFromServer = await getNfts()
    const nfts = nftsFromServer.filter((item: Nft) =>
      ownerNfts.some((nft) => nft.tokenId === item.token_id),
    )
    return nfts
  } catch (error) {
    console.log({ error })
    return []
  }
}

export function checkSwapTokenToday(
  txData: SwapTransactionInfo[],
  fromToken: Token,
  toToken: Token,
  minimumAmount: number,
) {
  const txSwap = txData.find(
    (item) =>
      isAddressEqual(
        item.fromTokenAddress as `0x${string}`,
        fromToken.contract_address as `0x${string}`,
      ) &&
      isAddressEqual(
        item.toTokenAddress as `0x${string}`,
        toToken.contract_address as `0x${string}`,
      ) &&
      Number(formatUnits(BigInt(item.amount), Number(fromToken.decimals))) >=
        minimumAmount,
  )

  if (txSwap) {
    const today = format(new Date(), 'yyyy-MM-dd')
    const timestamp = Number(txSwap?.ts)
    const date = format(new Date(timestamp * 1000), 'yyyy-MM-dd')
    return date === today
  }
  return false
}

export function checkAddLiquidityToday(
  txData: AddLiquidityTransactionInfo[],
  fromToken: Token,
  toToken: Token,
) {
  const txAddLiquidity = txData.find(
    (item) =>
      isAddressEqual(
        item.fromTokenAddress as `0x${string}`,
        fromToken.contract_address as `0x${string}`,
      ) &&
      isAddressEqual(
        item.toTokenAddress as `0x${string}`,
        toToken.contract_address as `0x${string}`,
      ),
  )
  if (txAddLiquidity) {
    const today = format(new Date(), 'yyyy-MM-dd')
    const timestamp = Number(txAddLiquidity?.ts)
    const date = format(new Date(timestamp * 1000), 'yyyy-MM-dd')
    return date === today
  }
  return false
}
