import WalletDefaultAvt from '@/assets/Images/wallet-default.png'
import AddSvg from '@/assets/svg/ic-add.svg'
import CheckedSvg from '@/assets/svg/ic-checked.svg'
import { Container, Content } from '@/components/Layout'
import { useWalletAddress } from '@/libs/hooks'
import type { WalletAccount } from '@/libs/types'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { Button, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { isAddressEqual } from 'viem'

const ListAccounts: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { accounts } = useSelector(walletStore, (state) => state.context)
  const activeWalletAddress = useWalletAddress()

  const activeWallet = (account: WalletAccount) => {
    walletStore.send({
      type: 'activeWallet',
      account,
    })
    queryClient.invalidateQueries({
      queryKey: ['list-tokens'],
    })

    navigate('/')
  }

  return (
    <Container className="w-full p-0">
      <Content className="flex flex-1 flex-col justify-between ">
        <div className="flex flex-col px-4 gap-4">
          <Text className="text-xl font-semibold text-white">
            Select account
          </Text>

          <div className="overflow-auto flex flex-col gap-3 max-h-[calc(100vh-150px)]">
            {accounts.map((account) => {
              const isActiveWallet = isAddressEqual(
                activeWalletAddress as `0x${string}`,
                account.address,
              )
              return (
                <Button
                  onPress={() => activeWallet(account)}
                  key={account.address}
                  className={`flex items-center justify-between px-3 py-4 border-[1.5px] rounded-xl ${
                    isActiveWallet ? 'border-yellow' : 'border-blue-200'
                  }
             )}`}
                >
                  <div className="flex items-center">
                    <img
                      src={WalletDefaultAvt}
                      alt="wallet"
                      className="w-6 h-6"
                    />
                    <div className="flex flex-col items-start ml-3">
                      <Text className="text-[14px] font-semibold text-left">
                        {account.name}
                      </Text>
                      <Text className="text-[12px] font-semibold opacity-64">
                        {formatWalletAddress(account.address)}
                      </Text>
                    </div>
                  </div>
                  {isActiveWallet && (
                    <img src={CheckedSvg} alt="checked" className="w-6 h-6" />
                  )}
                </Button>
              )
            })}
            <Button
              onPress={() => navigate('/on-board')}
              className={`flex items-center justify-between px-3 py-3 border-[1.5px] rounded-xl border-blue-200
             )}`}
            >
              <div className="flex items-center">
                <div className="bg-[#C7E7FF] w-8 h-8 flex flex-col justify-center items-center rounded-full">
                  <img src={AddSvg} alt="wallet" className="w-6 h-6" />
                </div>
                <Text className="ml-3 text-[14px] font-semibold">
                  Add account
                </Text>
              </div>
            </Button>
          </div>
        </div>
      </Content>
    </Container>
  )
}

export { ListAccounts }
