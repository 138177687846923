import { stakeStore } from '@/store/StakeStore'
import { useSelector } from '@xstate/store/react'
import { CongratulationsStake } from './CongratulationsStake'
import { CreateStake } from './CreateStake'
import { DetailStake } from './DetailStake'
import { ListNfts } from './ListNfts'

const StakeContainer = () => {
  const { stakeStep } = useSelector(stakeStore, (state) => state.context)
  return (
    <div className="w-full h-full">
      {stakeStep === 'list' && <ListNfts />}
      {stakeStep === 'create' && <CreateStake />}
      {stakeStep === 'detail' && <DetailStake />}
      {stakeStep === 'congratulation' && <CongratulationsStake />}
    </div>
  )
}

export { StakeContainer }
