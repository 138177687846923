import { balanceStore } from '@/store/balanceStore'
import { useSelector } from '@xstate/store/react'
import { useEffect } from 'react'
import { useTokenBalance, useTokenPriceBinance, useWalletAddress } from '.'
import type { Token } from '../types'

export const useTokenValue = (token: Token) => {
  const walletAddress = useWalletAddress()
  const { balance } = useTokenBalance(walletAddress!, token)
  const { usdPrice } = useTokenPriceBinance(token.symbol)
  const tokens = useSelector(balanceStore, (state) => state.context.tokens)

  const tokenValueInUSD = Number(balance ?? 0) * Number(usdPrice ?? 0)

  useEffect(() => {
    if (!tokens.length) return

    balanceStore.send({
      type: 'updateTokenPrice',
      symbol: token.symbol,
      valueInUSD: tokenValueInUSD,
      balance,
      usdPrice: Number(usdPrice),
    })
  }, [balance, token.symbol, tokenValueInUSD, usdPrice, tokens.length])

  return { tokenValueInUSD, balance, usdPrice }
}
