import type { PropsWithChildren } from 'react'

const TokenAssetsContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="flex flex-col gap-4 pt-4 pb-2 rounded-lg"
    >
      {children}
    </div>
  )
}

export { TokenAssetsContainer }
