import EthereumSvg from '@/assets/svg/ethereum.svg'
import type { Token } from '@/libs/types'
import { sendTokenStore } from '@/store/SendTokenStore'
import type React from 'react'
import { Button } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'

type Props = {
  token: Token
}

const TokenSend: React.FC<Props> = ({ token }) => {
  const navigate = useNavigate()

  const handleSelectToken = () => {
    sendTokenStore.send({
      type: 'setSelectedToken',
      token: token,
    })
    navigate('/send-token')
  }

  return (
    <Button
      className="flex justify-between p-4 items-center border-[1.5px] border-[#3892CF] rounded-2xl bg-[#52A7E73D]  hover:bg-opacity-100 pressed:bg-opacity-90"
      onPress={handleSelectToken}
    >
      <div className="flex justify-center items-center gap-4">
        <div>
          <img
            src={token?.image_url || EthereumSvg}
            alt="logo"
            className="w-6 h-6"
          />
        </div>

        <div className="flex flex-col justify-start items-start">
          <span className="text-base font-semibold">{token.symbol}</span>
        </div>
      </div>
    </Button>
  )
}

export { TokenSend }
