import { useShowRefill, useTimeToRefill } from '@/store/Game'
import { twMerge } from 'tailwind-merge'
import styles from '../styles.module.css'

export const NextRefill = () => {
  const isShow = useShowRefill()
  const time = useTimeToRefill()

  return isShow ? (
    <div
      className={twMerge(
        'flex items-center gap-1 py-1 px-4 w-fit self-center h-10',
        styles.gradient_border_refill,
      )}
    >
      <p className="text-sm font-normal leading-6 tracking-tight opacity-65">
        Next refill in
      </p>
      <span className="text-sm font-normal leading-6 tracking-tight min-w-10">
        {time}
      </span>
    </div>
  ) : (
    <div className="h-10" />
  )
}
