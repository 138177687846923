import BEX from "@/assets/Images/DAPPS/bex-icon.png"
import SlideImage1 from "@/assets/Images/DAPPS/slide-image-1.png"
import type { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import type React from 'react'
import { useNavigate } from 'react-router-dom'

type PropType = {
  options?: EmblaOptionsType
}

const CarouselDApps: React.FC<PropType> = (props) => {
  const { options } = props
  const navigate = useNavigate()
  const [emblaRef] = useEmblaCarousel(options)

  const classSlideElement = "w-[calc(100%-20px)] h-[auto] absolute bottom-[0px] left-[10px]"
  const classSlideElementInfo = "flex gap-[10px] absolute bottom-[10%] left-[20px]"

  return (
    <section className="embla" id="embla-carousel">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          <div 
            className="flex items-center justify-center w-full relative mt-[5px] min-h-[160px] embla__slide"
            onClick={() => navigate({
              pathname: '/swap',
            })}
            onKeyDown={() => {}}
          >
            <img src={SlideImage1} alt="slide 1" className={classSlideElement} />
            <div className={classSlideElementInfo}>
              <img src={BEX} alt="BEX" className="w-[48px] h-[48px] rounded-[12px]" />
              <div className="flex flex-col items-start justify-center">
                <p className="text-[#ffffff] text-[14px] font-[500]">BEX</p>
                <p className="text-[#ffffffA3] text-[12px] font-[400]">Swap a variety of tokens effortlessly</p>
              </div>
            </div>
          </div>

          <div 
            className="flex items-center justify-center w-full relative mt-[5px] min-h-[160px] embla__slide"
            onClick={() => navigate({
              pathname: '/swap',
            })}
            onKeyDown={() => {}}
          >
            <img src={SlideImage1} alt="slide 1" className={classSlideElement} />
            <div className={classSlideElementInfo}>
              <img src={BEX} alt="BEX" className="w-[48px] h-[48px] rounded-[12px]" />
              <div className="flex flex-col items-start justify-center">
                <p className="text-[#ffffff] text-[14px] font-[500]">BEX</p>
                <p className="text-[#ffffffA3] text-[12px] font-[400]">Swap a variety of tokens effortlessly</p>
              </div>
            </div>
          </div>

          <div 
            className="flex items-center justify-center w-full relative mt-[5px] min-h-[160px] embla__slide"
            onClick={() => navigate({
              pathname: '/swap',
            })}
            onKeyDown={() => {}}
          >
            <img src={SlideImage1} alt="slide 1" className={classSlideElement} />
            <div className={classSlideElementInfo}>
              <img src={BEX} alt="BEX" className="w-[48px] h-[48px] rounded-[12px]" />
              <div className="flex flex-col items-start justify-center">
                <p className="text-[#ffffff] text-[14px] font-[500]">BEX</p>
                <p className="text-[#ffffffA3] text-[12px] font-[400]">Swap a variety of tokens effortlessly</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { CarouselDApps }
