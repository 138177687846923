import type { Token } from '@/libs/types'
import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'

interface WalletBalanceContext {
  balance: number
  updated_at: string
  tokens: Array<Token & TokenPrice>
}

type TokenPrice = {
  usdPrice?: number
  balance?: number
  valueInUSD?: number
  updated_at?: string
}

const initialContext: WalletBalanceContext = {
  balance: 0,
  updated_at: Date.now().toLocaleString(),
  tokens: [],
}

export const balanceStore = createStore(initialContext, {
  setBalance: (
    _,
    { balance, updated_at }: { balance: number; updated_at: string },
  ) => ({ balance, updated_at }),
  updateTokenPrice: (
    context,
    { symbol, ...price }: { symbol: string } & TokenPrice,
  ) => {
    const tokens = context.tokens.map((token) =>
      token.symbol === symbol ? { ...token, ...price } : token,
    )

    const balance = tokens.reduce(
      (acc, token) => acc + (token.valueInUSD ?? 0),
      0,
    )

    return {
      tokens,
      balance,
    }
  },
  setTokens: (_, { tokens }: { tokens: WalletBalanceContext['tokens'] }) => ({
    tokens,
  }),
  setUpdatedAt: () => ({ updated_at: Date.now().toLocaleString() }),
})

export const useWalletBalance = () =>
  useSelector(balanceStore, (state) => state.context.balance)
