import CopySvg from '@/assets/svg/copy-wallet.svg'
import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { useCreateWalletMutation } from '@/libs/hooks'
import { useKeyboardVisibility } from '@/libs/hooks/useKeyboardVisibility'
import type { WalletAccount } from '@/libs/types'
import { loadWalletFromMnemonics } from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import { useMemo, useState } from 'react'
import { Button, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { english, generateMnemonic } from 'viem/accounts'

const NewWalletPage: React.FC = () => {
  useKeyboardVisibility()
  const navigate = useNavigate()
  const { accounts } = useSelector(walletStore, (state) => state.context)
  const [blurMnemonic, setBlurMnemonic] = useState(true)
  // const [name, setName] = useState('')
  const { mnemonicGenerated } = useSelector(
    walletStore,
    (state) => state.context,
  )
  const mnemonic = useMemo(() => {
    return mnemonicGenerated?.length > 0
      ? mnemonicGenerated
      : generateMnemonic(english)
  }, [mnemonicGenerated])

  const { mutate } = useCreateWalletMutation()

  // const saveMnemonic = () => {
  //   walletStore.send({
  //     type: 'setMnemonic',
  //     mnemonic,
  //   })
  //   navigate('/confirm-mnemonic')
  // }

  const loadWallet = () => {
    // check if wallet name exist

    // generate wallet name by accounts length
    const walletName = `BearCage${accounts.length + 1}`
    const walletNameExist = accounts.find(
      (account: WalletAccount) =>
        account.name?.trim().toLocaleLowerCase() ===
      walletName.trim().toLocaleLowerCase(),
    )
    if (walletNameExist) {
      toast.error('Wallet name already exist')
      return
    }
    const { account, privateKey } = loadWalletFromMnemonics(mnemonic)
    const walletAccount: WalletAccount = {
      ...account,
      mnemonic,
      privateKey,
      name: walletName,
    }
    walletStore.send({
      type: 'addWallet',
      account: walletAccount,
    })

    mutate({
      walletAddress: account.address,
      createType: 'create',
    })

    navigate('/')
    toast.success('Create wallet successfully')
  }

  const copyMnemonic = () => {
    setBlurMnemonic(false)
    navigator.clipboard.writeText(mnemonic as string)
    toast.success('Copied successfully')
  }

 

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between">
        <div>
          <Text className="text-white text-xl font-semibold">
            Enter Bera world, Create new Wallet
          </Text>
          <div className="flex flex-col mt-4">
            <div className="flex flex-col">
              <Text className="text-base font-semibold">Address</Text>
              <Text className="text-[14px] font-normal">
                We have created a unique{' '}
              </Text>
            </div>
            
            <div className="flex flex-col gap-2 mt-5">
              <div>
                <div className="flex">
                  <Text className="text-base font-semibold">Seed phrase</Text>
                  <Button onPress={copyMnemonic}>
                    <img src={CopySvg} alt="copy" className="ml-2" />
                  </Button>
                </div>
                <Text className="text-[14px] font-normal">
                  We have created a unique{' '}
                </Text>
              </div>
              <div className="bg-[#0C1E2B] px-4 py-3 rounded-xl">
                <Text className={blurMnemonic ? 'blur-sm' : 'blur-none'}>
                  {mnemonic}
                </Text>
              </div>
            </div>
          </div>
        </div>

        <WalletButton
          className="mb-12 max-h-12"
          onPress={loadWallet}
        >
          Create
        </WalletButton>
      </Content>
    </Container>
  )
}

export { NewWalletPage }
