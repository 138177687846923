import CheckedSvg from '@/assets/svg/checked.svg'
import { Container } from '@/components/Layout'
import { PREFIX_SCAN } from '@/libs/constant/prefixApiEvm'
import type React from 'react'
import { Text } from 'react-aria-components'
import { useNavigate, useSearchParams } from 'react-router-dom'

const TransactionSuccessPage: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const txHash = searchParams.get('hash')
  return (
    <Container>
      <div className="h-screen flex flex-col flex-1 justify-center items-center">
        <img src={CheckedSvg} className="w-[70px] h-[70px]" alt="checked" />
        <div className="text-center mt-5">
          <Text className="text-center w-full text-[16px] text-[#A9A9A9]">
            The transaction has sucessfully finished
          </Text>
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div
            className="mt-[10px] cursor-pointer"
            onClick={() => {
              window.open(`${PREFIX_SCAN}/tx/${txHash}`, '_blank')
              navigate('/', {
                replace: true,
              })
            }}
          >
            <Text className="text-[#A874FF] font-semibold">
              View transaction
            </Text>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { TransactionSuccessPage }
