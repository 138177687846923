import ReactGA from 'react-ga4'

export const handleReactGASend = (title: string) => {
  if (!import.meta.env.VITE_GOOGLE_ANALYTICS_ID) return

  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname,
    title,
  })
}

export const handleReactGASendEvent = (category: string) => {
  if (!import.meta.env.VITE_GOOGLE_ANALYTICS_ID) return

  ReactGA.send({
    hitType: 'event',
    eventCategory: category,
    eventAction: category,
    title: category,
  })
}
