interface Props {
  activeTab: number,
  handleChangeActiveTab:(value: number) => void
}

const SwapAndPoolTab = (props: Props) => {
  return (
    <div className="w-full rounded-[24px] h-[44px] bg-[#0C1E2B] flex p-[2px]">
      <div 
        className={`rounded-3xl w-3/6 flex justify-center items-center ${props.activeTab === 0 ? "bg-[#52A7E7]" : "bg-transparent"}`}
        onClick={() => props.handleChangeActiveTab(0)}
        onKeyDown={() => {}}
      >
        <p className="text-sm font-bold text-[#ffffff] uppercase">Swap</p>
      </div>
      <div
        className={`rounded-3xl w-3/6 flex justify-center items-center ${props.activeTab === 1 ? "bg-[#52A7E7]" : "bg-transparent"}`}
        onClick={() => props.handleChangeActiveTab(1)}
        onKeyDown={() => {}}
      >
        <p className="text-sm font-bold text-[#ffffff] uppercase">liquidity</p>
      </div>
    </div>
  )
}

export {SwapAndPoolTab}