import { useQuery } from '@tanstack/react-query'
import { checkIsWhitelist } from '../utils/wallet/nft'

export function useCheckWhiteList({
  roundIdx,
  walletAddress,
}: {
  roundIdx: number
  walletAddress: `0x${string}`
}) {
  const { data, ...res } = useQuery({
    queryKey: ['check-white-list', walletAddress, roundIdx],
    queryFn: () => checkIsWhitelist(walletAddress, roundIdx),
  })
  return { isWhitelist: data, ...res }
}
