import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { useKeyboardVisibility } from '@/libs/hooks/useKeyboardVisibility'
import type { WalletAccount } from '@/libs/types'
import { normalizePhrase } from '@/libs/utils/ui'
import {
  loadWalletFromMnemonics,
  loadWalletFromPrivateKey,
} from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import { type ChangeEvent, useEffect, useState } from 'react'
import { Input, Text, TextArea, TextField } from 'react-aria-components'
import { Tab, TabList, TabPanel, Tabs as TabsView } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import '@/styles/aria-tabs.css'
import { useCreateWalletMutation } from '@/libs/hooks'
import SwipeableViews from 'react-swipeable-views'

enum SecretType {
  Mnemonic = 'mnemonic',
  PrivateKey = 'privateKey',
}

enum SecretIndex {
  Mnemonic = 0,
  PrivateKey = 1,
}

const ImportWalletPage: React.FC = () => {
  useKeyboardVisibility()
  const { accounts } = useSelector(walletStore, (state) => state.context)
  const navigate = useNavigate()
  const [secretString, setSecretString] = useState('')
  const [name, setName] = useState('')
  const [error, setError] = useState<string | undefined>()

  const [swipeIndex, setSwipeIndex] = useState(SecretIndex.Mnemonic)

  const { mutate } = useCreateWalletMutation()

  const onTabChange = (key: SecretType) => {
    if (key === SecretType.Mnemonic) {
      setSwipeIndex(SecretIndex.Mnemonic)
    } else {
      setSwipeIndex(SecretIndex.PrivateKey)
    }
  }

  const tabSelectedKey =
    swipeIndex === SecretIndex.Mnemonic
      ? SecretType.Mnemonic
      : SecretType.PrivateKey

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setSecretString('')
    setError(undefined)
  }, [tabSelectedKey])

  const handleChangeSecretString = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setError(undefined)
    const inputValue = event.target.value
    if (inputValue.endsWith('  ')) {
      return
    }

    setSecretString(inputValue)
  }

  const handleBlurSecretString = () => {
    const phrase = normalizePhrase(secretString)
    setSecretString(phrase)
  }

  const loadWallet = () => {
    try {
      const { account, privateKey } =
        tabSelectedKey === SecretType.Mnemonic
          ? loadWalletFromMnemonics(secretString)
          : loadWalletFromPrivateKey(secretString)
      const walletAccount: WalletAccount = {
        ...account,
        mnemonic:
          tabSelectedKey === SecretType.Mnemonic ? secretString : undefined,
        privateKey,
        name,
      }
      // check if wallet already exists
      const existingWallet = accounts.find(
        (acc) => acc.address === walletAccount.address,
      )
      if (existingWallet) {
        toast.error('Wallet already exists')
        return
      }
      // check if wallet name exist

      const walletNameExist = accounts.find(
        (account: WalletAccount) =>
          account.name?.trim().toLocaleLowerCase() ===
          name.trim().toLocaleLowerCase(),
      )
      if (walletNameExist) {
        toast.error('Wallet name already exist')
        return
      }

      walletStore.send({
        type: 'addWallet',
        account: walletAccount,
      })

      mutate({
        walletAddress: account.address,
        createType: 'import',
      })

      navigate({
        pathname: '/',
      })
      toast.success('Wallet loaded successfully')
    } catch (error) {
      setError('Invalid recovery phrase')
    }
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between">
        <div>
          <div className="flex flex-col mb-2">
            <Text className="text-white text-xl font-semibold">
              Import account
            </Text>
            <Text className="text-white opacity-64 text-sm font-normal mt-1">
              Enter backup seed phrase associated with the account.
            </Text>
          </div>

          <TabsView
            onSelectionChange={(key) => onTabChange(key as SecretType)}
            selectedKey={tabSelectedKey}
          >
            <TabList aria-label="Wallet">
              <Tab id={SecretType.Mnemonic}>SEED PHRASE</Tab>
              <Tab id={SecretType.PrivateKey}>PRIVATE KEY</Tab>
            </TabList>

            <SwipeableViews index={swipeIndex} onChangeIndex={setSwipeIndex}>
              <TabPanel shouldForceMount id={SecretType.Mnemonic} />

              <TabPanel shouldForceMount id={SecretType.PrivateKey} />
            </SwipeableViews>
          </TabsView>

          <TextField>
            <TextArea
              value={secretString}
              onChange={handleChangeSecretString}
              onBlur={handleBlurSecretString}
              className="bg-transparent border-[1.5px] border-blue-200 mt-2 rounded-xl text-white px-3 py-4 w-full placeholder:text-white placeholder:opacity-64 text-sm"
              placeholder={
                tabSelectedKey === SecretType.Mnemonic
                  ? 'Enter seed phrase'
                  : 'Enter private key'
              }
              rows={5}
            />
          </TextField>
          {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
          <div className="flex flex-col gap-2 mt-3">
            <Text className="text-[14px] font-semibold opacity-64">
              Nickname
            </Text>
            <Input
              className="w-full bg-transparent border-[1px] border-blue-200 focus:border-yellow px-4 py-3 rounded-xl text-white placeholder-white placeholder-opacity-64 text-sm"
              placeholder="Enter wallet name"
              onChange={handleChangeName}
              value={name}
            />
          </div>
        </div>

        <WalletButton
          className="mb-12 max-h-12"
          onPress={loadWallet}
          isDisabled={!secretString || !name}
        >
          Load wallet
        </WalletButton>
      </Content>
    </Container>
  )
}

export { ImportWalletPage }
