import { retrieveLaunchParams } from '@telegram-apps/sdk-react'
import { initDataRaw as _initDataRaw } from '../../mockEnv'
import { isUseTelegramApp } from './constans'

export const getInitDataRaw = () => {
  let initDataRaw = _initDataRaw
  if (isUseTelegramApp) {
    initDataRaw = retrieveLaunchParams().initDataRaw!
  }
  return initDataRaw
}
