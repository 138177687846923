import { useMutation } from '@tanstack/react-query'
import request from '../config/request'

async function turnOnNotify() {
  const res = await request.post('/bera-bonds/subscribe')
  return res.data
}

export const useMutationTurnOnNotify = () => {
  return useMutation({
    mutationFn: () => turnOnNotify(),
  })
}
