import { useQuery } from '@tanstack/react-query'
import request from '../config/request'

export const getPoint = async () => {
  const res = await request.get<{ point: number }>('/my-point')
  return res.data
}

export const useMyPoint = () => {
  const { data } = useQuery({
    queryKey: ['my-point'],
    queryFn: () => getPoint(),
  })

  return data?.point ?? 0
}
