import Back from '@/assets/svg/back-icon.svg'
import LagerBeca from '@/assets/svg/large-icon-beca.svg'
import { Container } from '@/components/Layout'
import { useStakeNfts } from '@/libs/hooks'
import type { Nft } from '@/libs/types'
import { stakeStore } from '@/store/StakeStore'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import { useNavigate } from 'react-router-dom'
import { SelectWallet } from '../Mint/SelectWallet'

const ListNfts = () => {
  const navigate = useNavigate()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)

  const { data: nfts } = useStakeNfts(activeAccount?.address as `0x${string}`)

  const redirectToStake = (nft: Nft) => {
    stakeStore.send({
      type: 'setSelectedNft',
      nft,
    })
    stakeStore.send({
      type: 'setStakeStep',
      step: 'create',
    })
  }

  const redirectToDetail = (nft: Nft) => {
    stakeStore.send({
      type: 'setSelectedNft',
      nft,
    })
    stakeStore.send({
      type: 'setStakeStep',
      step: 'detail',
    })
  }

  return (
    <Container className="px-0 w-full min-h-[100vh]" center={<SelectWallet />}>
      <div className="w-full flex flex-col items-center justify-between py-[30px] px-[20px]">
        <div className="w-full flex flex-col gap-[20px] items-center justify-center">
          <div
            className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px]"
            onClick={() =>
              navigate({
                pathname: '/',
              })
            }
            onKeyDown={() => {}}
          >
            <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
            <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
              Back
            </p>
          </div>
          <div className="flex flex-col w-full gap-[10px]">
            <p className="text-[24px] text-[#ffffff] font-bold text-left">
              Stake Your NFT
            </p>
            <div className="flex flex-wrap gap-[10px] overflow-auto max-h-[calc(100vh-200px)]">
              {nfts.map((nft: Nft) => {
                return (
                  <div
                    key={`${nft.id}-${nft.name}`}
                    className="rounded-[18px] p-[1.5px] w-[calc(50%-10px)] bg-[#52A7E73D] relative"
                    onClick={() =>
                      nft?.is_staked
                        ? redirectToDetail(nft)
                        : redirectToStake(nft)
                    }
                    onKeyDown={() => {}}
                  >
                    <img
                      src={nft.image_url}
                      alt={`NFT ${nft.name}`}
                      className="w-full h-[auto] rounded-[18px] border-[1.5px] border-solid border-[#3892CF]"
                    />
                    <div className="p-[10px] text-center">
                      <p className="text-[#FFFFFF] text-[14px] font-[700]">
                        {nft.name}
                      </p>
                    </div>
                    <div className="px-[12px] py-[8px]">
                      {nft.is_staked && (
                        <div className="px-[6px] py-[4px] bg-[#52C41A1A] flex items-center justify-center gap-[10px] rounded-[16px]">
                          <p className="text-[#52C41A] text-[14px] font-[700]">
                            Staked
                          </p>
                        </div>
                      )}
                      {!nft.is_staked && (
                        <div
                          className="px-[16px] py-[12px] bg-[#3892CF] flex items-center justify-center gap-[10px] rounded-[16px] h-[32px] current-pointer"
                          style={{ boxShadow: '2px 2px 0px 0px #00000040' }}
                        >
                          <p className="text-[#ffffff] text-[14px] font-[700] m-[0px]">
                            Stake Now
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
              <div className="rounded-[18px] p-[1.5px] w-[calc(50%-10px)] bg-[#52A7E73D] relative flex items-center justify-center flex-col min-h-[240px]">
                <img
                  src={LagerBeca}
                  alt="lager icon beca"
                  className="w-[80px] h-[auto] rounded-[18px]"
                />
                <div className="p-[10px] text-center">
                  <p className="text-[#FFFFFFA3] text-[14px] font-[400]">
                    End of collection
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { ListNfts }
