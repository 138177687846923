import { useQuery } from '@tanstack/react-query'
import type { MemeToken } from '../types'
import { getBalanceMemeToken } from '../utils/wallet/action'

export function useMemeTokenBalance(walletAddress: string, token: MemeToken) {
  const { data, ...res } = useQuery({
    queryKey: ['memeTokenBalance', token?.address, walletAddress],
    queryFn: () => getBalanceMemeToken(walletAddress, token),
    enabled: !!token,
  })

  return { balance: data ?? 0, ...res }
}
