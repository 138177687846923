export type ResultType = 'win' | 'lose'

export enum Prediction {
  BEAR = 0, // Down
  BULL = 1, // Up
}

export interface WSPlayGameArgs {
  prediction: Prediction
  timestamp: number
}

export interface WSPlayGame {
  currentPrice: string
  previousPrice: string
  remainingFuel: number
  lastUpdatedFuelAt: Date
  percentageDiff: number
  result: ResultType
  streak: number
  winPoint: number | null
}

export interface WSRefresh {
  point: number
  remainingFuel: number
  lastUpdatedFuelAt: string
  timestamp: number
  price: string
  marketDirection: 'bear' | 'bull'
}

export interface WSConsumeFuel {
  timestamp: number
  remainingFuel: number
  lastUpdatedFuelAt: string
}

export type EventName = 'REFRESH' | 'PLAY_GAME' | 'CONSUME_FUEL'

export interface WSPlayGameResponse {
  event: 'PLAY_GAME'
  data: WSPlayGame
}

export interface WSRefreshResponse {
  event: 'REFRESH'
  data: WSRefresh
}

export interface WSConsumeFuelResponse {
  event: 'CONSUME_FUEL'
  data: WSConsumeFuel
}

export type WSMessageResponse =
  | WSPlayGameResponse
  | WSRefreshResponse
  | WSConsumeFuelResponse

export type PlayGameFn = (args: WSPlayGameArgs) => void
