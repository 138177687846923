import { TokenCardSkeleton } from '@/components/Skeleton'
import { useTransactionHistory, useWalletAddress } from '@/libs/hooks'
import type { Token, TransactionHistory } from '@/libs/types'
import {
  BOTTOM_BAR_HEIGHT,
  HEADER_HEIGHT,
  TAB_HEIGHT,
} from '@/libs/utils/constans'
import { useViewport } from '@telegram-apps/sdk-react'
import type React from 'react'
import { Text } from 'react-aria-components'
import { HistoryAsset } from './HistoryAsset'

type Props = {
  token: Token
}

function calculateHeight(vpHeight: number | undefined) {
  const walletFeature = document.getElementById('wallet-detail-feature')
  const walletFeatureHeight = walletFeature?.offsetHeight ?? 0

  return (
    Number(vpHeight ?? 0) -
    HEADER_HEIGHT -
    BOTTOM_BAR_HEIGHT -
    walletFeatureHeight -
    TAB_HEIGHT
  )
}

const History: React.FC<Props> = ({ token }) => {
  const activeWalletAddress = useWalletAddress()
  const { histories, isLoading } = useTransactionHistory(
    activeWalletAddress as string,
    token.contract_address,
  )
  const vp = useViewport()
  const height = calculateHeight(vp?.height)
  return (
    <div className="flex flex-col">
      <Text className="font-semibold text-base gap-4">Asset Records</Text>
      <div
        className="flex flex-col gap-4 mt-4 pb-2 overflow-y-auto rounded-lg"
        style={{ height }}
      >
        {isLoading ? (
          <TokenCardSkeleton />
        ) : histories.length > 0 ? (
          histories.map((history: TransactionHistory) => (
            <HistoryAsset
              key={history.hash}
              transactionHistory={history}
              token={token}
            />
          ))
        ) : (
          <Text className="text-center text-[#A9A9A9]">No history records</Text>
        )}
      </div>
    </div>
  )
}

export { History }
