import WalletDefaultAvt from '@/assets/Images/wallet-default.png'
import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import { Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'

const ConfirmWalletPage: React.FC = () => {
  const navigate = useNavigate()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)

  const redirect = () => {
    navigate('/')
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between">
        <div>
          <Text className="text-white text-base text-center block font-semibold">
            You're connecting this wallet
          </Text>

          <div className="flex flex-row items-center bg-transparent border-[1.5px] border-blue-200 rounded-xl text-white p-2 w-full placeholder:text-gray-700 text-sm mt-4">
            <img src={WalletDefaultAvt} alt="wallet" className="w-6 h-6" />
            <Text className="text-white text-base block ml-2">
              {formatWalletAddress(activeAccount?.address as string)}
            </Text>
          </div>
        </div>

        <WalletButton className="mb-12 max-h-12" onPress={redirect}>
          Continue
        </WalletButton>
      </Content>
    </Container>
  )
}

export { ConfirmWalletPage }
