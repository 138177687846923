import type { Nft } from '@/libs/types'
import { createStore } from '@xstate/store'

const stakeStore = createStore(
  {
    selectedNft: null as Nft | null,
    showStakeModal: true,
    unstakedNft: null as Nft | null,
    stakeStep: 'list' as 'list' | 'create' | 'detail' | 'congratulation',
  },
  {
    setSelectedNft: (_, { nft }: { nft: Nft }) => ({
      selectedNft: nft,
    }),
    setShowStakeModal: (_, { show }: { show: boolean }) => ({
      showStakeModal: show,
    }),
    setUnstakedNft: (_, { nft }: { nft: Nft }) => ({
      unstakedNft: nft,
    }),
    setStakeStep: (
      _,
      { step }: { step: 'list' | 'create' | 'detail' | 'congratulation' },
    ) => ({
      stakeStep: step,
    }),
    clearUnstakedNft: () => ({
      unstakedNft: null,
    }),
  },
)

export { stakeStore }
