import { useQuery } from '@tanstack/react-query'
import { sortBy } from 'es-toolkit'
import request from '../config/request'
import type { Token } from '../types'

async function getSwapTokens() {
  try {
    const res = await request.get<Token[]>('/wallet/token', {
      params: {
        can_swap: true,
      },
    })
    const tokenData = res.data
    const tokenSorted = sortBy(tokenData, ['is_native_token']).reverse()
    return tokenSorted
  } catch (error) {
    console.log('get token error', error)
    return []
  }
}

export const useSwapTokens = () => {
  const { data, ...res } = useQuery({
    queryKey: ['swap-tokens'],
    queryFn: () => getSwapTokens(),
  })

  return { tokens: data ?? [], ...res }
}
