import { useQuery } from '@tanstack/react-query'
import {checkFaucet} from '../utils/wallet/action'

export function useCheckFaucet(
  walletAddress: string,
) {
  const { data, ...res } = useQuery({
    queryKey: ['check-faucet', walletAddress],
    queryFn: () => checkFaucet(walletAddress),
    enabled: !!walletAddress,
  })

  return { isFaucet: data ?? false, ...res }
}
