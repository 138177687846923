import { BottomSheetModal } from "@/components/Modal/BottomSheetModal"
import { Input } from "react-aria-components"

interface Props {
  open: boolean,
  setOpen:(value: boolean) => void,
  slippageType: number,
  handleChangeSlippageType:(value: number) => void,
  slippageValue: string,
  setSlippageValue:(value:string) => void
}

const SettingModal = (props: Props) => {
  return (
    <BottomSheetModal
        open={props.open}
        title={'Setting'}
        onClose={() => props.setOpen(false)}
        heightPercent={0.5}
      >
        <div className="flex flex-col w-full max-h-[calc(100vh-200px)] overflow-auto p-[20px] gap-4">
          <p className='flex items-center gap-1 font-medium leading-none'>Slippage tolerance</p>
          <div className='flex items-center justify-between gap-[10px]'>
            <div className='flex p-[5px] gap-[5px] rounded-[12px] bg-[#1f1c1980]'>
              <div 
                className='rounded-[12px] p-[10px]' 
                style={{ backgroundColor: props.slippageType === 1 ? "#DC2828" : "transparent"}}
                onClick={() => props.handleChangeSlippageType(1)}
                onKeyDown={() => {}}
              >
                <p className='text-[14px] font-bold' style={{ color: props.slippageType === 1 ? "#000000" : "#ffffffa0"}}>Auto</p>
              </div>
              <div
                className='rounded-[12px] p-[10px]' 
                style={{ backgroundColor: props.slippageType === 2 ? "#DC2828" : "transparent"}}
                onClick={() => props.handleChangeSlippageType(2)}
                onKeyDown={() => {}}
              >
                <p className='text-[14px] font-bold' style={{ color: props.slippageType === 2 ? "#000000" : "#ffffffa0"}}>Custom</p>
              </div>
              <div
                className='rounded-[12px] p-[10px]' 
                style={{ backgroundColor: props.slippageType === 3 ? "#DC2828" : "transparent"}}
                onClick={() => props.handleChangeSlippageType(3)}
                onKeyDown={() => {}}
              >
                <p className='text-[14px] font-bold' style={{ color: props.slippageType === 3 ? "#000000" : "#ffffffa0"}}>Degen</p>
              </div>
            </div>
            <div className="w-full bg-[#1f1c1980] border-[1px] border-[#3b3b3b4d] focus:border-[#3b3b3b4d] rounded-xl text-white placeholder-white placeholder-opacity-64 flex items-center justify-center">
              <Input
                autoFocus
                className="w-full bg-[transparent] border-[0px] border-[#3b3b3b4d] focus:border-[#3b3b3b4d] px-4 py-3 rounded-xl text-white placeholder-white placeholder-opacity-64 text-right pr-[2px] cursor-not-allowed"
                placeholder=""
                disabled={true}
                value={props.slippageValue}
              />
              <span className='pr-[5px] text-[12px] text-[#ffffff80]'>%</span>
            </div>
          </div>
        </div>
      </BottomSheetModal>
  )
}

export {SettingModal}