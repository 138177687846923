import WalletDefaultAvt from '@/assets/Images/wallet-default.png'
import ClaimGif from '@/assets/gifs/claim.gif'
import Back from '@/assets/svg/back-icon.svg'
import CheckedSvg from '@/assets/svg/ic-checked.svg'
import NotificationModalImage from '@/assets/svg/notification-modal-image.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import {
  useMutationClamWL,
  useWalletAddress,
  useWhiteListInfo,
} from '@/libs/hooks'
import { useMyPoint } from '@/libs/hooks/usePoint'
import { Tabs, type WalletAccount } from '@/libs/types'
import { ERROR_MESSAGE } from '@/libs/utils/constans'
import { formatNumber } from '@/libs/utils/number'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { bottomTabStore } from '@/store/BottomTabStore'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useUtils } from '@telegram-apps/sdk-react'
import { useSelector } from '@xstate/store/react'
import { useState } from 'react'
import { Button, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { isAddressEqual } from 'viem'

const ClaimPage = () => {
  const point = useMyPoint()
  const utils = useUtils()
  const { whiteListInfo } = useWhiteListInfo()
  const [openSelectWallet, setOpenSelectWallet] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const { mutateAsync, isPending } = useMutationClamWL()
  const activeWalletAddress = useWalletAddress()
  const { accounts } = useSelector(walletStore, (state) => state.context)
  const queryClient = useQueryClient()
  const activeWallet = (account: WalletAccount) => {
    walletStore.send({
      type: 'activeWallet',
      account,
    })
    queryClient.invalidateQueries({
      queryKey: ['list-tokens'],
    })

    setOpenSelectWallet(false)
    setOpenConfirm(true)
  }

  const navigate = useNavigate()

  const exchange = async () => {
    await mutateAsync(
      {
        address: activeWalletAddress as `0x${string}`,
      },
      {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onError: (response: any) => {
          if (response.response.data.error === ERROR_MESSAGE.WHITE_LIST_FULL) {
            setOpenErrorModal(true)
          }
        },
      },
    )
    queryClient.invalidateQueries({
      queryKey: ['whitelist-info'],
    })

    setOpenConfirm(false)
    navigate({
      pathname: '/claim-success',
    })
  }

  const redirectToHome = () => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Wallet,
    })
    navigate({
      pathname: '/',
    })
  }

  const redirectToTwitter = () => {
    utils.openLink('https://x.com/bearcage_xyz')
    redirectToHome()
  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col items-center justify-between py-[30px] px-[20px]">
      <div className="w-full flex flex-col gap-[20px] items-center justify-center">
        <div
          className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px]"
          onClick={() =>
            navigate({
              pathname: '/',
            })
          }
          onKeyDown={() => {}}
        >
          <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
          <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
            Back
          </p>
        </div>
        <p className="text-[24px] text-[#ffffff] font-bold text-center">
          BeraBonds
        </p>
        <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
          The Only 3,333 Proof of BearCage Contributors
        </p>
        <div className="w-full flex items-center justify-center gap-[10px] mt-[20px]">
          <img src={BeraCoinImg} className="w-[24px] h-[24px]" alt="icon" />
          <p className="text-[20px] text-[#FFDE5E] font-[700]">
            {formatNumber(point, 0)}
          </p>
          <p className="text-[20px] text-[#FFFFFF] font-[700]">$BECA</p>
        </div>
        <img
          src={ClaimGif}
          className="w-[160px] h-[160px] rounded-[16px]"
          alt="icon"
        />
        <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
          Exchange {formatNumber(whiteListInfo?.requirementPoint || 0, 0)} $BECA
          to claim BeraBonds GTD WL
        </p>
      </div>
      <div className="w-full">
        <WalletButton
          onPress={() => setOpenSelectWallet(true)}
          className="w-[100%] h-[48px]"
        >
          <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
            Claim Now
          </p>
        </WalletButton>
      </div>
      <BottomSheetModal
        open={openSelectWallet}
        title="Select wallet"
        onClose={() => setOpenSelectWallet(false)}
        heightPercent={0.52}
      >
        <div className="flex flex-col px-4 py-5 gap-3">
          {accounts.map((account) => {
            const isActiveWallet = isAddressEqual(
              activeWalletAddress as `0x${string}`,
              account.address,
            )
            return (
              <Button
                key={account.address}
                className={`flex items-center justify-between px-3 py-4 border-[1.5px] rounded-xl ${
                  isActiveWallet ? 'border-yellow' : 'border-white'
                }
             )}`}
                onPress={() => activeWallet(account)}
              >
                <div className="flex items-center">
                  <img
                    src={WalletDefaultAvt}
                    alt="wallet"
                    className="w-6 h-6"
                  />
                  <div className="flex flex-col ml-3">
                    <Text className="text-[14px] font-semibold text-left">
                      {account.name}
                    </Text>
                    <Text className="text-[12px] font-semibold opacity-64">
                      {formatWalletAddress(account.address)}
                    </Text>
                  </div>
                </div>
                {isActiveWallet && (
                  <img src={CheckedSvg} alt="checked" className="w-6 h-6" />
                )}
              </Button>
            )
          })}
        </div>
      </BottomSheetModal>
      <BottomSheetModal
        open={openConfirm}
        title="Confirmation"
        onClose={() => setOpenConfirm(false)}
        heightPercent={0.4}
      >
        <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
          <div className="flex flex-col px-4 py-5 gap-4 w-full">
            <div className="w-full flex flex-col items-center justify-center">
              <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
                Do you want to exchange{' '}
                {formatNumber(whiteListInfo?.requirementPoint || 0, 0)} $BECA
                for <span className="text-[#FFDE5E]"> 1 BeraBonds GTD WL </span>{' '}
                slot?
              </p>
            </div>
          </div>
          {isPending ? (
            <div className="pb-[10px]">
              <WalletButton
                isDisabled={isPending}
                // onPress={() => console.log('Exchanging...')}
                color="not-shadow"
                className="w-full h-[48px] opacity-50"
              >
                <p className="text-[16px] text-[#FFFFFFa0] font-[700] leading-[24px] font-[DM Sans]">
                  Exchanging...
                </p>
              </WalletButton>
            </div>
          ) : (
            <div className="pb-[10px] flex gap-[20px]">
              <WalletButton
                onPress={() => setOpenConfirm(false)}
                color="not-shadow"
                className="w-[calc(50%-20px)] h-[48px]"
              >
                <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                  Cancel
                </p>
              </WalletButton>
              <WalletButton
                onPress={exchange}
                className="w-[calc(50%-20px)] h-[48px]"
              >
                <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                  Exchange
                </p>
              </WalletButton>
            </div>
          )}
        </div>
      </BottomSheetModal>

      <BottomSheetModal
        open={openErrorModal}
        title="Notification"
        onClose={() => setOpenErrorModal(false)}
        heightPercent={0.63}
      >
        <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
          <div className="flex flex-col px-4 py-5 gap-4 w-full">
            <div className="w-full flex items-center justify-center">
              <img
                src={NotificationModalImage}
                alt="notification modal"
                className="h-[64px] w-[64px]"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center">
              <p className="text-[20px] text-[#ffffff] font-bold">BeraBonds</p>
              <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
                The Only 3,333 Proof of BearCage Contributors
              </p>
            </div>
            <div className="w-full flex items-center justify-center">
              <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
                We have run out of GTD WL. Follow our twitter to claim your FCFS
                slot Beras.
              </p>
            </div>
          </div>
          <div className="pb-[10px]">
            <WalletButton
              onPress={redirectToTwitter}
              className="w-[100%] h-[48px]"
            >
              <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                Go Twitter
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal>
    </div>
  )
}

export { ClaimPage }
