import type { Token } from './token'

export type TransactionHistory = {
  from: string
  to: string
  value: number
  hash: string
  timestamp: number
  status: number | null
  nonce: number
  contractAddress: string | null
  type: string
  symbol: string
  decimal: number
}

export type TransactionInfo = {
  selectedToken: Token
  receiver: string
  amount: string | number
  txFee: string | number
}

export enum TransactionType {
  Send = 'Send',
  Receive = 'Receive',
}
