import EthereumSvg from '@/assets/svg/ethereum.svg'
import type { Token } from '@/libs/types'
import { sendTokenStore } from '@/store/SendTokenStore'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Input, TextField } from 'react-aria-components'
import { Drawer } from 'vaul'

type Props = {
  data: Token[]
  open: boolean
  setOpen: (open: boolean) => void
}

const ModalSelectToken: React.FC<Props> = ({ data, open, setOpen }) => {
  const [tokens, setTokens] = useState<Token[]>([])

  useEffect(() => {
    if (data) {
      setTokens(data)
    }
  }, [data])

  const handleSearchToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    const filteredTokens = data.filter((token) =>
      token.name.toLowerCase().includes(searchValue.toLowerCase()),
    )
    setTokens(filteredTokens)
  }

  return (
    <Drawer.Root
      shouldScaleBackground
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Drawer.Portal>
        <Drawer.Content className="bg-zinc-100 flex flex-col rounded-t-[10px] h-[96%] mt-24 fixed bottom-0 left-0 right-0 ">
          <div className="bg-gray-600 rounded-t-[10px] flex-1">
            <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
            <div className="max-w-md mx-auto">
              <Drawer.Title className="font-medium mb-4 text-center">
                My tokens
              </Drawer.Title>
              <div className="px-4">
                <TextField>
                  <Input
                    onChange={handleSearchToken}
                    //   onBlur={handleBlurPhrase}
                    className="bg-primary-500 mt-2 rounded-lg text-white p-2 w-full placeholder:text-gray-700 text-sm"
                    placeholder="Search token name"
                  />
                </TextField>
              </div>

              <div className="flex flex-col overflow-auto max-h-[calc(100vh-200px)] mt-6 gap-4 px-4">
                {tokens.map((token) => (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <div
                    key={token.contract_address}
                    className="flex flex-row items-center bg-gray-400 mt-2 rounded-lg text-white p-4 w-full placeholder:text-gray-700 text-sm"
                    onClick={() => {
                      setOpen(false)
                      sendTokenStore.send({
                        type: 'setSelectedToken',
                        token: token,
                      })
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <img
                        src={token?.image_url || EthereumSvg}
                        alt="logo"
                        className="w-6 h-6"
                      />
                      <div className="pl-2">{token.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}

export { ModalSelectToken }
