import { useQuery } from '@tanstack/react-query'
import type { Token } from '../types'
import { queryPrice } from '../utils/wallet/swap'

// refresh 3s
const refetchInterval = 5000

export function useQueryPrice(
  fromToken: Token,
  toToken: Token,
  fromAmount?: number,
) {
  return useQuery({
    queryKey: [
      'get price',
      fromToken.contract_address,
      toToken.contract_address,
      fromAmount,
    ],
    queryFn: () =>
      queryPrice({
        from: fromToken,
        to: toToken,
        fromAmount: Number(fromAmount),
      }),
    enabled: !!fromToken && !!toToken && !!fromAmount,
    refetchInterval,
  })
}
