
import { ChartComponent } from '@/components/TradingViewChart'
import { useChartData } from '@/libs/hooks'
import type { MemeToken } from '@/libs/types'
import { formatNumber } from '@/libs/utils/number'
import type { CandlestickData, Time } from 'lightweight-charts'
import { useState } from 'react'

type Props = {
  tokenSelected: MemeToken
}

const InfoAndChart: React.FC<Props> = ({ tokenSelected }) => {
  const intervalOptions = ['1m', '5m', '15m', '1h']
  const [interval, setInterval] = useState(intervalOptions[1])

  const { data, isLoading } = useChartData({
    tokenAddress: tokenSelected.address,
    interval,
  })

  return (
    <div className="flex flex-col gap-[20px] w-full">
      <div className="flex gap-[20px] w-full flex-wrap">
        <div className="border-[#3892CF] border-solid border-[1px] rounded-[12px] flex flex-col gap-[5px] items-center justify-center w-[calc(50%-10px)] p-[10px]">
          <p className="text-[#ffffffa0] text-[14px] font-[400]">Market Cap</p>
          <div className='flex gap-[10px] items-center justify-center'>
            <p className="text-[#ffffff] text-[16px] font-bold">{`$${Number.parseFloat(tokenSelected.marketCap).toFixed(2)}`}</p>
            <img
                src='https://app.bearcage.xyz/external-assets/telegram-images/%24BERA.png'
                alt="bera coin"
                className="w-[20px] h-[20px] rounded-full"
            />
          </div>
        </div>
        <div className="border-[#3892CF] border-solid border-[1px] rounded-[12px] flex flex-col gap-[5px] items-center justify-center w-[calc(50%-10px)] p-[10px]">
          <p className="text-[#ffffffa0] text-[14px] font-[400]">
            Virtual Liquidity
          </p>
          <div className='flex gap-[10px] items-center justify-center'>
            <p className="text-[#ffffff] text-[16px] font-bold">
              ${formatNumber(tokenSelected.liquidity)}
            </p>
            <img
                src='https://app.bearcage.xyz/external-assets/telegram-images/%24BERA.png'
                alt="bera coin"
                className="w-[20px] h-[20px] rounded-full"
            />
          </div>
        </div>
        <div className="border-[#3892CF] border-solid border-[1px] rounded-[12px] flex flex-col gap-[5px] items-center justify-center w-[calc(50%-10px)] p-[10px]">
          <p className="text-[#ffffffa0] text-[14px] font-[400]">
            Token Price
          </p>
          <p className="text-[#ffffff] text-[16px] font-bold">
            {formatNumber(tokenSelected.price)} BERA
          </p>
        </div>
        <div className="border-[#3892CF] border-solid border-[1px] rounded-[12px] flex flex-col gap-[5px] items-center justify-center w-[calc(50%-10px)] p-[10px]">
          <p className="text-[#ffffffa0] text-[14px] font-[400]">
            Volume 24 hours
          </p>
          <div className='flex gap-[10px] items-center justify-center'>
            <p className="text-[#ffffff] text-[16px] font-bold">
              ${formatNumber(tokenSelected.volume24h)}
            </p>
            <img
                src='https://app.bearcage.xyz/external-assets/telegram-images/%24BERA.png'
                alt="bera coin"
                className="w-[20px] h-[20px] rounded-full"
            />
          </div>
        </div>
      </div>
      {/* <div className="border-[#3892CF] border-solid border-[1px] rounded-[12px] flex flex-col gap-[5px] items-start justify-center p-[10px]">
        <p className="text-[#ffffffa0] text-[14px] font-[400]">Create By</p>
        <div className="flex items-center justify-center gap-[10px]">
          <img src={Success} alt="tick" className="w-[20px] h-[20px]" />
          <p className="text-[#ffffff] text-[16px] font-bold">
            {tokenSelected.user.username}
          </p>
        </div>
      </div> */}
      <div className="py-[12px] px-[16px] rounded-[16px] bg-[#52A7E71F] flex flex-col gap-[20px] justify-start min-h-[200px]"  id="chart">
        <div className="flex items-center gap-2">
          {intervalOptions.map((item) => (
            <button
              type="button"
              onClick={() => setInterval(item)}
              key={item}
              className={`px-3 py-1 rounded-xl ${interval === item ? 'bg-blue' : 'bg-[#0C1E2B]'}`}
            >
              <p className="text-[12px]">{item}</p>
            </button>
          ))}
        </div>
        <ChartComponent
          data={data as CandlestickData<Time>[]}
          isFetchingData={isLoading}
        />
      </div>
    </div>
  )
}

export { InfoAndChart }
