import type React from 'react'

const TokenCardSkeleton: React.FC = () => {
  // create array with 4 elements
  const arr = Array.from({ length: 4 })
  return arr.map((_, index) => (
    <div
      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
      key={index}
      className="flex justify-between p-4 items-center border-[1.5px] border-blue-600 rounded-2xl bg-blue-800 bg-opacity-80 hover:bg-opacity-100 pressed:bg-opacity-90 animate-pulse"
    >
      <div className="flex justify-center items-center gap-4">
        <div>
          <div className="w-6 h-6 bg-gray-200 rounded-full" />
        </div>

        <div className="flex flex-col justify-start items-start">
          <span className="h-4 bg-gray-200 rounded w-24" />
          <span className="h-4 bg-gray-200 rounded w-2/3 mt-1" />
        </div>
      </div>

      <span className="h-4 bg-gray-200 rounded w-1/3" />
    </div>
  ))
}

export { TokenCardSkeleton }
