interface Props {
    avatar: string,
    name: string,
    money: string|number
    token: string
}

export function CardElement(props: Props) {
    return <div className="w-full min-h-[64px] border border-solid border-[#3892CF] rounded-2xl flex items-center justify-between p-[10px]">
        <div className="flex item-center justify-center gap-[20px]">
            <img src={props.avatar} height="auto" width="45px" alt="avatar" className="rounded-[50%]" />
            <p className="text-[14px] font-semibold flex items-center">{props.name}</p>
        </div>
        <div className="flex item-center justify-center gap-[10px]">
            <img src={props.token} className="h-[16px] w-[16px]" alt="token icon" />
            <p className="text-[12px] font-semibold text-[#FFDE5E]">{props.money}</p>
        </div>
    </div>
}