import Back from '@/assets/svg/back-icon.svg'
import FireWorks from '@/assets/svg/fireworks.svg'
import { WalletButton } from '@/components/Button'
import { useMutationTurnOnNotify } from '@/libs/hooks'
import { Tabs } from '@/libs/types'
import { bottomTabStore } from '@/store/BottomTabStore'
import { useQueryClient } from '@tanstack/react-query'
import { useUtils } from '@telegram-apps/sdk-react'
import { useNavigate } from 'react-router-dom'

const ClaimSuccess = () => {
  const utils = useUtils()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutationTurnOnNotify()

  const turnOnNotify = async () => {
    await mutateAsync()
    queryClient.invalidateQueries({
      queryKey: ['whitelist-info'],
    })
    utils.openLink('https://discord.com/invite/bearcage')
    redirectToHome()
  }

  const redirectToHome = () => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Wallet,
    })
    navigate({
      pathname: '/',
    })
  }

  return (
    <div className="w-full min-h-[100vh] flex flex-col items-center justify-between py-[30px] px-[20px]">
      <div className="w-full flex flex-col gap-[20px] items-center justify-center">
        <div
          className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px]"
          onClick={redirectToHome}
          onKeyDown={() => {}}
        >
          <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
          <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
            Back
          </p>
        </div>
        <img src={FireWorks} className="w-[150px] h-[150px]" alt="fireworks" />
        <p className="text-[24px] text-[#ffffff] font-bold text-center">
          Congratulations!
        </p>
        <div className="flex items-center justify-center">
          <p className="text-[14px] text-white">You have secured</p>
          <p className="text-yellow-800 text-[14px] font-bold ml-1">
            1 BeraBonds GTD WL
          </p>
        </div>
        <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
          Remember to turn on notification on BearCage discord to get the latest
          news about the mint date and mint place.
        </p>
      </div>
      <div className="w-full">
        <WalletButton onPress={turnOnNotify} className="w-[100%] h-[48px]">
          <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
            Turn On Notification
          </p>
        </WalletButton>
      </div>
    </div>
  )
}

export { ClaimSuccess }
