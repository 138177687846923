import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { useTokenValue } from '@/libs/hooks'
import { useMyPoint } from '@/libs/hooks/usePoint'
import type { Token } from '@/libs/types'
import { usdFormatter } from '@/libs/utils/number'
import { transactionDetailStore } from '@/store/TransactionDetailStore'
import { Button } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'

export type TokenCardProps = { token: Token }

const TokenCard: React.FC<TokenCardProps> = ({ token }) => {
  const point = useMyPoint()

  const navigate = useNavigate()
  const { name, image_url, is_sendable } = token
  const { tokenValueInUSD, balance, usdPrice } = useTokenValue(token)

  const tokenLogo = image_url ?? BeraCoinImg

  const detailTransaction = () => {
    transactionDetailStore.send({
      type: 'setSelectedToken',
      token: token,
    })
    navigate({
      pathname: '/token-detail',
    })
  }

  return (
    <Button
      className="flex justify-between p-4 items-center border-[1.5px] border-[#3892CF] rounded-2xl bg-[#52A7E73D]  hover:bg-opacity-100 pressed:bg-opacity-90"
      onPress={is_sendable ? detailTransaction : undefined}
    >
      <div className="flex justify-center items-center gap-4">
        <div>
          <img src={tokenLogo} alt="logo" className="w-6 h-6" />
        </div>

        <div className="flex flex-col justify-start items-start">
          <span className="text-base font-semibold">{name}</span>
          {usdPrice && (
            <span className="text-sm font-normal text-white text-[14px]">
              ${Number(usdPrice)}
            </span>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        {!is_sendable ? (
          <span className="text-base font-semibold text-right">
            {point.toLocaleString('en-US')}
          </span>
        ) : (
          <span className="text-base font-semibold text-right">
            {balance ? balance.toLocaleString('en-US') : 0}
          </span>
        )}
        {usdPrice && (
          <span className="text-[14px]">{usdFormatter(tokenValueInUSD)}</span>
        )}
      </div>
    </Button>
  )
}

export { TokenCard }
