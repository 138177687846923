import WalletDefaultAvt from '@/assets/Images/wallet-default.png'
import ArrowRightSvg from '@/assets/svg/arrow-right.svg'
import KeySvg from '@/assets/svg/key.svg'
import LogoutSvg from '@/assets/svg/logout.svg'
import SeedPhraseSvg from '@/assets/svg/seed-phrase.svg'
import { Container, Content } from '@/components/Layout'
import type { WalletAccount } from '@/libs/types'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { transactionStore } from '@/store/TransactionStore'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { useState } from 'react'
import { Button, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { isAddressEqual } from 'viem'
import ModalDeleteAccount from './ModalDeleteAccount'
import { SettingItem } from './SettingItem'

const AccountSettingPage: React.FC = () => {
  const navigate = useNavigate()
  const { activeAccount, accounts } = useSelector(
    walletStore,
    (state) => state.context,
  )

  const [openModalDelete, setOpenModalDelete] = useState(false)

  const showPrivateKey = () => {
    navigate('/show-private-key')
  }

  const showMnemonic = () => {
    navigate('/show-mnemonic')
  }

  const handleDeleteAccount = () => {
    walletStore.send({
      type: 'deleteAccount',
      account: activeAccount as WalletAccount,
    })
    transactionStore.send({
      type: 'clearTransaction',
    })
    toast.success('Account deleted successfully')
    const restAccounts = accounts.filter(
      (acc) =>
        !isAddressEqual(acc.address, activeAccount?.address as `0x${string}`),
    )
    if (restAccounts.length > 0) {
      navigate('/')
    } else {
      navigate('/on-board', {
        replace: true,
      })
    }
  }

  return (
    <Container>
      <Content className="flex flex-col gap-3">
        <Button
          onPress={() => navigate('/list-account')}
          className="flex items-center justify-between border-[1.5px] bg-transparent border-blue-200 px-4 py-[13px] rounded-xl"
        >
          <div className="flex items-center">
            <div className="p-2 bg-[#4CA4E6] rounded-lg">
              <img
                src={WalletDefaultAvt}
                className="w-[24px] h-[24px]"
                alt="Wallet"
              />
            </div>

            <div className="flex flex-col ml-4">
              <Text className="text-[14px] font-semibold text-left">
                {activeAccount?.name}
              </Text>
              <Text className="text-[14px] font-semibold">
                {formatWalletAddress(activeAccount?.address as string)}
              </Text>
            </div>
          </div>
          <img src={ArrowRightSvg} className="w-4 h-4" alt="Arrow" />
        </Button>
        <div className="flex flex-col gap-4 border-[1.5px] bg-transparent border-blue-200 px-4 py-[13px] rounded-xl">
          {activeAccount?.mnemonic && (
            <SettingItem
              title="Seed phrase"
              icon={SeedPhraseSvg}
              onClick={() => showMnemonic()}
            />
          )}
          <SettingItem
            title="Private key"
            icon={KeySvg}
            onClick={() => showPrivateKey()}
          />
        </div>

        <Button
          onPress={() => setOpenModalDelete(true)}
          className="flex items-center border-[1.5px] bg-[#F5222D1F] border-[#F5222D33] px-4 py-[13px] rounded-xl"
        >
          <div className="p-2 bg-[#F5222D1F] rounded-lg">
            <img src={LogoutSvg} className="w-[24px] h-[24px]" alt="logout" />
          </div>

          <div className="flex flex-col ml-4">
            <Text className="text-[14px] font-semibold">Log Out</Text>
          </div>
        </Button>

        {openModalDelete && (
          <ModalDeleteAccount
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            handleDeleteAccount={handleDeleteAccount}
          />
        )}
      </Content>
    </Container>
  )
}

export { AccountSettingPage }
