import CopySvg from '@/assets/svg/copy-wallet.svg'
import IcInfoSvg from '@/assets/svg/ic-info.svg'
import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { useMemo } from 'react'
import { Text } from 'react-aria-components'
import { toast } from 'sonner'
import { bytesToHex } from 'viem'
import { mnemonicToAccount } from 'viem/accounts'
import { PrivateKeyQr } from './PrivateKeyQr'

const ShowPrivateKeyPage: React.FC = () => {
  const { activeAccount } = useSelector(walletStore, (state) => state.context)

  const privateKey = useMemo(() => {
    if (activeAccount?.mnemonic) {
      const account = mnemonicToAccount(activeAccount.mnemonic.trim())
      const hdKey = account.getHdKey()
      return bytesToHex(hdKey.privateKey as Uint8Array)
    }
    return activeAccount?.privateKey as string
  }, [activeAccount])

  const copyPrivateKey = () => {
    navigator.clipboard.writeText(privateKey)
    toast.success('Copied successfully')
  }

  return (
    <Container>
      <Content className="flex flex-1 gap-4">
        <div className="flex flex-col">
          <Text className="text-white text-xl font-semibold">Private key</Text>
          <Text className="text-white opacity-64 text-sm font-normal mt-1">
            Private key is the only way to restore your wallet. Keep it secure.
          </Text>
          <PrivateKeyQr privateKey={privateKey} />

          <div className="flex w-full p-3 border items-center rounded-xl border-[#FAAD1466] mt-5">
            <img src={IcInfoSvg} className="w-6 h-6" alt="info" />
            <Text className="ml-4 text-xs">
              Do not share your Private key to anyone,{' '}
              <Text className="text-yellow-700 font-semibold">
                and don't import to unverified wallets or bots.
              </Text>
            </Text>
          </div>
        </div>
      </Content>
      <WalletButton className="mb-12 max-h-12" onPress={copyPrivateKey}>
        <img src={CopySvg} className="w-5 h-5 mr-2" alt="copy" />
        Copy
      </WalletButton>
    </Container>
  )
}

export { ShowPrivateKeyPage }
