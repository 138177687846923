import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import {
  useGameStatus,
  useIsHoldingResult,
  usePoint,
  useResult,
} from '@/store/Game'
import CountUp from 'react-countup'
import { CountdownTimer } from './CountdownTimer'

export const AvailablePoints = () => {
  const point = usePoint()
  const gameStatus = useGameStatus()
  const result = useResult()
  const pointNumber = Number(point)
  const isHoldingResult = useIsHoldingResult()

  return (
    <div className="flex flex-col items-center justify-center h-[170px] pt-12 pb-20">
      {gameStatus === 'IDLE' ? (
        <>
          {isHoldingResult ? (
            <p className="text-[50px] font-bold leading-[60px] tracking-[-0.03em] text-[#FFDE5E]">
              00:00
            </p>
          ) : (
            <>
              <div className="flex gap-2 items-center justify-center">
                <img src={BeraCoinImg} className="w-6 h-6" alt="BeraCoin" />
                <p className="font-normal">Total</p>
              </div>

              {result?.result === 'win' ? (
                <CountUp
                  start={pointNumber > 10 ? pointNumber - 10 : 0}
                  end={pointNumber}
                  separator=","
                  className="text-[50px] font-bold"
                />
              ) : (
                <p className="text-[50px] font-bold">
                  {Number(point).toLocaleString('en-US')}
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <CountdownTimer />
      )}
    </div>
  )
}
