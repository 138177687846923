interface Props {
    tab: number,
    handleChangeTab:(value: number) =>  void
}

export function CustomSidebar (props: Props) {
    const SidebarOption = [
        "ALL", "MONTH"
    ]
    return (<div className="w-full rounded-[24px] h-[44px] bg-[#0C1E2B] flex p-[2px]">
        {SidebarOption.map((element: string, index: number) => {
            return (
                <div key={index} onClick={() => props.handleChangeTab(index)} className={"rounded-3xl w-3/6 flex justify-center items-center " + (index == props.tab ? "bg-[#52A7E7]" : "bg-transparent")} >
                    <p className="text-sm font-bold text-[#ffffff]">{element}</p>
                </div>
            )
        })}
    </div>)
}