
import LeaderBoardIcon from "@/assets/svg/new-leaderboard-icon.svg"
import { Tabs } from '@/libs/types'
import { handleReactGASend, } from '@/libs/utils/tracking'
import { useChoice, useLastChoice } from '@/store/Game'
import { useViewport } from '@telegram-apps/sdk-react'
import type React from 'react'
import { useEffect } from 'react'
import { type ClassNameValue, twMerge } from 'tailwind-merge'
import { calculateHeight } from '../IndexPage/utils'
import {
  AvailablePoints,
  Guess,
  ResultTransition,
  TokenPrice,
} from './components'
import styles from './styles.module.css'

const Styles: Record<'BEAR' | 'BULL', ClassNameValue> = {
  BEAR: styles.bear,
  BULL: styles.bull,
}

interface TabGameProps {
  handleConsumeFuel: () => boolean
  handleChangeTab:(tab: Tabs) => void
}

const TabGame: React.FC<TabGameProps> = ({ handleConsumeFuel, handleChangeTab }) => {
  const vp = useViewport()
  const height = calculateHeight(vp?.height)

  const choice = useChoice()
  const lastChoice = useLastChoice()

  useEffect(() => {
    handleReactGASend('GameScreenView')
  }, [])

  return (
    <div
      className="flex flex-1 flex-col px-4 bg-[#0C1E2B] overflow-y-auto"
      style={{ height }}
    >
      <div 
        className='absolute top-[15px] right-[15px] z-50' 
        onClick={() => {
          handleChangeTab(Tabs.Leaderboard);
        }} 
        onKeyDown={() => {}}
      >
        <img src={LeaderBoardIcon} alt="leaderboard" className='h-[24px] w-[24px]' />
      </div>
      <div
        className={twMerge(
          styles.background,
          Styles[choice || lastChoice || 'BULL'],
        )}
      />
      <div className="z-10">
        <AvailablePoints />
        <TokenPrice />
        <Guess handleConsumeFuel={handleConsumeFuel} />
      </div>

      <ResultTransition />
    </div>
  )
}

export { TabGame }
