import Template from '@/assets/Images/wallet-default.webp'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import type { ListAccount } from '@/libs/types'
import { maskLastThreeChars } from '@/libs/utils/formatStr'

interface Props {
  index: string
  image: string | null
  name: string | null
  value: string | null
  showAccountRank: boolean
  element: ListAccount
}

export function RankCard(props: Props) {
  return (
    <div
      className="rounded-2xl flex items-center justify-between absolute h-[64px] bottom-[15%] right-[10px] pt-[10px] pb-[10px] pl-[15px] pr-[15px] border border-solid border-[#FFF7D9] bg-[#FFDE5E] w-[calc(100%-20px)] duration-1000"
      id="contain-account"
    >
      <div className="flex items-center justify-center gap-2.5 relative">
        <p
          className="text-sm font-bold text-[#8C6F00] w-[30px]"
          id="rank-account"
        >
          {props.index}
        </p>
        <img
          src={props.image ? props.image : Template}
          className="h-[50px] w-[50px] rounded-[50%]"
          alt="avatar"
          id="avatar-account"
        />
        <p
          className="text-sm font-semibold text-[#003F60] truncate w-[140px]"
          id="name-account"
        >
          {maskLastThreeChars(
            props.name
              ? props.name
              : `${props.element.last_name} ${props.element.first_name}`,
          )}
        </p>
      </div>
      <div
        className="flex items-center gap-2.5 justify-center"
        id="point-account"
      >
        <img src={BeraCoinImg} width="16px" height="16px" alt="token icon" />
        <p className="text-xs font-semibold text-[#003F60] truncate w-[70px]">
          {props.value}
        </p>
      </div>
    </div>
  )
}
