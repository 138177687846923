export const formatTime = (time: number): string => {
  const timeLeft = {
    hours: Math.floor(time / 3600),
    minutes: Math.floor((time % 3600) / 60),
    seconds: Math.floor(time % 60),
  }

  const minutesSeconds = `${timeLeft.minutes.toString().padStart(2, '0')}:${timeLeft.seconds.toString().padStart(2, '0')}`

  if (timeLeft.hours === 0) {
    return minutesSeconds
  }

  return `${timeLeft.hours.toString().padStart(2, '0')}:${minutesSeconds}`
}
