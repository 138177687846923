import { useQuery } from '@tanstack/react-query'
import type { PrivateKeyAccount } from 'viem'
import type { Token } from '../types'
import { estimateGasMultiSwap } from '../utils/wallet/swap'

export function useEstimateGasSwap(
  from: Token,
  to: Token,
  amount: number,
  receiveAmount: number,
  slippage: number,
  signer: PrivateKeyAccount,
) {
  const minOut =
    Number(receiveAmount) - (slippage / 100) * Number(receiveAmount)
  const { data, ...res } = useQuery({
    queryKey: [
      'get-gas-fee',
      from?.contract_address,
      to?.contract_address,
      amount,
    ],
    queryFn: () =>
      estimateGasMultiSwap({
        mode: 'multiSwap',
        from,
        to,
        amount,
        signer,
        minOut,
      }),
    enabled: !!from && !!to && !!amount,
  })

  return { fee: data ?? null, ...res }
}
