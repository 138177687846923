import MintNFTBG from '@/assets/Images/mint-nft-task.png'
import TransactionSuccess from '@/assets/svg/transaction-success.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import type { TaskInfo } from '@/libs/types/task'
import { transactionStore } from '@/store/TransactionStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

export function TaskMintNFT(
  open: boolean,
  setOpen: (value: boolean) => void,
  title: string,
  task: TaskInfo,
  goTask: (id: number) => void,
  loading: boolean,
  claimTask: (id: number) => void,
) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const txsMintNFT = useSelector(
    transactionStore,
    (state) => state.context.txsMintNFT,
  )
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false)

  const closeDialog = () => {
    setOpen(false)
  }
  const checkTask = async () => {
    if (txsMintNFT?.length > 0) {
      await goTask(task.task.id)
      setOpen(false)
      setSuccessDialogOpen(true)
    } else {
      setOpen(false)
      navigate({
        pathname: '/mint-nft',
        search: createSearchParams({
          taskId: task.task.id.toString(),
        }).toString(),
      })
    }
  }

  const ClaimTaskSuccess = async () => {
    await claimTask(task.task.id)
    // toast.success(`You have successfully claimed ${task.task.reward_point} $BECA`)
    transactionStore.send({
      type: 'clearMintNFTTransaction',
    })
    queryClient.invalidateQueries({ queryKey: ['task'] })
    setSuccessDialogOpen(false)
  }

  const buttonLoading = () => {
    return (
      <WalletButton className="w-full h-[48px] border-0" color="blue-800">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </WalletButton>
    )
  }

  const InfoModal = () => {
    return (
      <BottomSheetModal
        open={open}
        title={title}
        onClose={() => closeDialog()}
        heightPercent={0.82}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-center pt-[30px]">
          <img
            src={MintNFTBG}
            alt="telegram icon"
            className="w-full h-[auto]"
          />
          <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
            {task.task.title}
          </p>
          <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
            {task.task.description}
          </p>
          <div className="flex items-center justify-center gap-[5px] pb-[10px]">
            <img
              src={BeraCoinImg}
              width="24px"
              height="24px"
              alt="token icon"
            />
            <p className="text-[20px] text-[#FFDE5E] leading-[24px] font-[700]">
              {`${task.task.reward_point}`}
            </p>
            <p className="text-[20px] text-[#FFFFFF] leading-[24px] font-[700]">
              $BECA
            </p>
          </div>
          {!loading && (
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => checkTask()}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Join & Finish task
              </p>
            </WalletButton>
          )}
          {loading && <div className="w-full">{buttonLoading()}</div>}
        </div>
      </BottomSheetModal>
    )
  }

  const SuccessDialog = () => {
    return (
      <BottomSheetModal
        open={successDialogOpen}
        title={title}
        onClose={() => setSuccessDialogOpen(false)}
        heightPercent={0.5}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
          <div className="w-full h-full flex flex-col items-center justify-center">
            <img
              src={TransactionSuccess}
              alt="transaction success"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              Mission successful
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
              Tip: Claim BERA every day to get BERA + BECA. The more BERA you
              hold, the better your chances of getting an airdrop.
            </p>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            {!loading && (
              <WalletButton
                className="w-full h-[48px]"
                onPress={() => ClaimTaskSuccess()}
              >
                <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                  Claim reward
                </p>
              </WalletButton>
            )}
            {loading && <div className="w-full">{buttonLoading()}</div>}
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  return (
    <div>
      {InfoModal()}
      {SuccessDialog()}
    </div>
  )
}
