import { useEffect, useMemo, useState } from 'react'
import { Tab, TabList, Tabs as TabsView } from 'react-aria-components'
import { NFTAssets } from '../NFTAssets'
import { TokenAssets } from '../TokenAssets'
import { WalletFeature } from '../WalletFeature'
import '@/styles/aria-tabs.css'
import { EmblaCarousel } from '@/components/Carousel'
import { MintCountdown } from '@/components/Countdown/MintCountdown'
import { useWalletAddress } from '@/libs/hooks'
import { useBeraBalance } from '@/libs/hooks/useBeraBalance'
import { useMyPoint } from '@/libs/hooks/usePoint'
import { HomeTab } from '@/libs/types'
import { MINT_ROUND_DATA, TIME_TO_MINT } from '@/libs/utils/constans'
import {
  handleReactGASend,
  handleReactGASendEvent,
} from '@/libs/utils/tracking'
import { bottomTabStore } from '@/store/BottomTabStore'
import { useSelector } from '@xstate/store/react'
import { useWalletBanners } from '../components/Banners/WalletBanners'

enum Assets {
  Token = 'token',
  NFT = 'nft',
}

enum AssetsIndex {
  Token = 0,
  NFT = 1,
}

const TabWallet: React.FC = () => {
  const { homeTab } = useSelector(bottomTabStore, (state) => state.context)
  const walletAddress = useWalletAddress()
  const [swipeIndex, setSwipeIndex] = useState(AssetsIndex.Token)
  const point = useMyPoint()
  const { balance: beraBalance } = useBeraBalance(walletAddress as string)
  const { getSlides } = useWalletBanners({
    point,
    beraBalance,
  })

  useEffect(() => {
    if (homeTab === HomeTab.NFT) {
      setSwipeIndex(AssetsIndex.NFT)
    } else {
      setSwipeIndex(AssetsIndex.Token)
    }
  }, [homeTab])

  const newSlide = useMemo(() => {
    const dataSlide = getSlides()
    const currentTime = new Date()
    if (currentTime.getTime() / 1000 - TIME_TO_MINT <= 0) {
      const indexDelete = dataSlide.findIndex((element) => element.id === 4)
      if (indexDelete > -1) {
        dataSlide.splice(indexDelete, 1)
      }
    }
    if (currentTime.getTime() / 1000 - TIME_TO_MINT > 0) {
      const indexDelete = dataSlide.findIndex((element) => element.id === 5)
      if (indexDelete > -1) {
        dataSlide.splice(indexDelete, 1)
      }
    }
    return dataSlide
  }, [getSlides])

  const onTabChange = (key: Assets) => {
    if (key === Assets.Token) {
      handleReactGASendEvent('TokenListClick')
      setSwipeIndex(AssetsIndex.Token)
    } else {
      handleReactGASendEvent('NftListClick')
      setSwipeIndex(AssetsIndex.NFT)
    }
  }

  const tabSelectedKey =
    swipeIndex === AssetsIndex.Token ? Assets.Token : Assets.NFT

  useEffect(() => {
    handleReactGASend('MainScreenHomeView')
  }, [])

  return (
    <div className="flex flex-col overflow-auto max-h-[calc(100vh-170px)]">
      <MintCountdown mintData={MINT_ROUND_DATA} />
      <WalletFeature />
      <EmblaCarousel slides={newSlide} />

      <div className="px-4 mt-6">
        <TabsView
          onSelectionChange={(key) => onTabChange(key as Assets)}
          selectedKey={tabSelectedKey}
        >
          <TabList aria-label="Wallet">
            <Tab id={Assets.Token}>Tokens</Tab>
            <Tab id={Assets.NFT}>NFTs</Tab>
          </TabList>

          {tabSelectedKey === Assets.Token && <TokenAssets />}
          {tabSelectedKey === Assets.NFT && <NFTAssets />}
        </TabsView>
      </div>
      {/* <BottomSheetModal
        open={open}
        title="Notification"
        onClose={() => setOpen(false)}
        heightPercent={0.63}
      >
        <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
          <div className="flex flex-col px-4 py-5 gap-4 w-full">
            <div className="w-full flex items-center justify-center">
              <img
                src={NotificationModalImage}
                alt="notification modal"
                className="h-[64px] w-[64px]"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center">
              <p className="text-[20px] text-[#ffffff] font-bold">BeraBonds</p>
              <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
                The Only 3,333 Proof of BearCage Contributors
              </p>
            </div>
            <div className="w-full flex items-center justify-center">
              <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
                We have run out of GTD WL. Follow our twitter to claim your FCFS
                slot Beras.
              </p>
            </div>
          </div>
          <div className="pb-[10px]">
            <WalletButton
              onPress={turnOnNotifyHandler}
              className="w-[100%] h-[48px]"
            >
              <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                Go Twitter
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal> */}
    </div>
  )
}

export { TabWallet }
