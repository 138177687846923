import {
  type Choice,
  type GameStatus,
  type PlayGameFn,
  Prediction,
  type WSPlayGame,
  type WSRefresh,
} from '@/libs/types'
import { createStore } from '@xstate/store'
import { differenceInSeconds } from 'date-fns'

interface IFuel {
  remainingFuel: number
  timeToRefill: number | null
  lastConsumeFuel: number | null
}

type IResult = Omit<WSPlayGame, 'lastUpdatedFuelAt' | 'remainingFuel'>

interface GameContext {
  point: number | null
  price: number | null
  timer: number | null
  result: IResult | null
  marketDirection: 'bear' | 'bull' | null
  choice: Choice | null
  status: GameStatus
  fuel: IFuel
  lastChoice: Choice | null
  intervalId: NodeJS.Timeout | null

  handlePlayGame: PlayGameFn | null
  isHolding: boolean | null
  isOnline: boolean | null
}

export const TIME_SHOW_RESULT = 3
export const TIME_COUNTDOWN = 5
export const MAX_FUEL = 12
export const REFILL_TIME = 60

/**
 * @description The timer will run from 5 to -3.
 * 5 -> 0 will be the countdown time (COUNTINGDOWN)
 * 0 -> -3 will be the result display time (RESULT)
 * else will be IDLE
 */
const initialContext: GameContext = {
  point: null,
  price: null,
  marketDirection: null,
  choice: null,
  lastChoice: null,
  result: null,

  status: 'IDLE',
  timer: null,
  fuel: {
    remainingFuel: 12,
    timeToRefill: null,
    lastConsumeFuel: null,
  },

  intervalId: null, // This is an interval id to update the timer
  handlePlayGame: null, // This is a function to play the game
  isHolding: null, // This is a state to check if the user is holding the result animation

  isOnline: null,
}

const gameStore = createStore(initialContext, {
  setChoice: (context, { choice }: { choice: Choice }) => {
    if (context.status !== 'IDLE' || context.fuel.remainingFuel === 0) {
      return context
    }

    return {
      lastChoice: choice,
      choice,
      status: 'COUNTINGDOWN' as GameStatus,
      timer: TIME_COUNTDOWN,
      result: null,
    }
  },
  setOnline: (_, { isOnline }: { isOnline: boolean }) => ({
    isOnline,
  }),
  setStatus: (_, { status }: { status: GameStatus }) => ({
    status,
  }),
  setIntervalId: (_, { intervalId }: { intervalId: NodeJS.Timeout }) => ({
    intervalId,
  }),
  setHolding: (_, { isHolding }: { isHolding: boolean }) => ({
    isHolding,
  }),
  setFuel: (context, { fuel }: { fuel: Omit<IFuel, 'timeToRefill'> }) => {
    return {
      fuel: {
        ...fuel,
        timeToRefill: context.fuel.timeToRefill,
      },
    }
  },
  setPrice: (_, { price }: { price: string }) => {
    return {
      price: Number.parseFloat(price),
    }
  },
  setPoint: (_, { point }: { point: number }) => ({
    point,
  }),
  setResult: (_, { result }: { result: IResult }) => ({
    result,
  }),
  setPlayGameFn: (_, { handlePlayGame }: { handlePlayGame: PlayGameFn }) => ({
    handlePlayGame,
  }),
  handleRefresh: (context, args: WSRefresh) => {
    const timeToRefill =
      REFILL_TIME -
      1 -
      differenceInSeconds(new Date(), new Date(args.lastUpdatedFuelAt))

    return {
      point: args.point,
      price: Number.parseFloat(args.price),
      fuel: {
        remainingFuel: args.remainingFuel,
        timeToRefill: timeToRefill > 0 ? timeToRefill : 0,
        lastConsumeFuel: context.fuel.lastConsumeFuel,
      },
      marketDirection: args.marketDirection,
    }
  },
  updateTimer: (context) => {
    const newTimer = context.timer !== null ? context.timer - 1 : TIME_COUNTDOWN

    if (newTimer === 0) {
      // Show result ~~ play game

      if (
        context.handlePlayGame &&
        context.choice &&
        context.fuel.lastConsumeFuel
      ) {
        context.handlePlayGame({
          prediction: Prediction[context.choice],
          timestamp: context.fuel.lastConsumeFuel,
        })
      }

      return {
        status: 'RESULT' as GameStatus,
        timer: newTimer,
      }
    }

    if (newTimer === -TIME_SHOW_RESULT) {
      if (context.intervalId) {
        clearInterval(context.intervalId)
      }

      return {
        status: 'IDLE' as GameStatus,
        timer: null,
        choice: null,
        intervalId: null,
      }
    }

    return {
      timer: newTimer,
    }
  },
})

export { gameStore }
