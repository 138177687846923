import ClaimGif from '@/assets/gifs/claim.gif'
import Back from '@/assets/svg/back-icon.svg'
import { WalletButton } from '@/components/Button'
import { Container } from '@/components/Layout'
import { useCheckMintedNftBeraBond } from '@/libs/hooks'
import { HomeTab, Tabs } from '@/libs/types'
import { MINT_ROUND_DATA } from '@/libs/utils/constans'
import { bottomTabStore } from '@/store/BottomTabStore'
import { walletStore } from '@/store/WalletStore'
import { useUtils } from '@telegram-apps/sdk-react'
import { useSelector } from '@xstate/store/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { berachainTestnetbArtio } from 'viem/chains'
import { useAccount, useSwitchChain } from 'wagmi'
import { ChooseWallets } from './ChooseWallets'
import { ElementMint } from './ElementMint'
import { SelectWallet } from './SelectWallet'

const MintPage = () => {
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const { address, isConnected: isConnectedWithWC, chainId } = useAccount()
  const { isMinted } = useCheckMintedNftBeraBond(
    address || (activeAccount?.address as string),
  )
  const { switchChain } = useSwitchChain()

  useEffect(() => {
    if (chainId && chainId !== berachainTestnetbArtio.id) {
      switchChain({
        chainId: berachainTestnetbArtio.id,
        addEthereumChainParameter: {
          chainName: berachainTestnetbArtio.name,
          nativeCurrency: berachainTestnetbArtio.nativeCurrency,
          rpcUrls: ['https://bartio.rpc.berachain.com'],
          blockExplorerUrls: ['https://bartio.beratrail.io'],
        },
      })
    }
  }, [chainId, switchChain])
  const utils = useUtils()
  const navigate = useNavigate()
  const [openChooseWallets, setOpenChooseWallets] = useState(false)

  const redirectToHome = () => {
    bottomTabStore.send({
      type: 'setHomeTab',
      tab: HomeTab.NFT,
    })

    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Wallet,
    })
    navigate({
      pathname: '/',
    })
  }

  return (
    <Container className="px-0 w-full min-h-[100vh]" center={<SelectWallet />}>
      <div className="w-full  flex flex-col items-start justify-between py-[30px] px-[20px] overflow-auto h-[calc(100vh-6vh)]">
        <div className="w-full flex flex-col gap-[20px] items-center justify-start ">
          <div className="w-full rounded-[14px] min-h-[30px] flex items-center justify-between gap-[10px]">
            <div
              className="flex items-center justify-center gap-[5px]"
              onClick={() =>
                navigate({
                  pathname: '/',
                })
              }
              onKeyDown={() => {}}
            >
              <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
              <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
                Back
              </p>
            </div>
            <div
              className="flex items-center justify-center gap-[5px]"
              onClick={() => setOpenChooseWallets(true)}
              onKeyDown={() => {}}
            >
              <p className="text-[#71C2FF] text-[14px] font-[400] flex items-center justify-center gap-[5px] h-full underline">
                Check with external wallet?
              </p>
            </div>
          </div>

          <p className="text-[24px] text-[#ffffff] font-bold text-center">
            BeraBonds
          </p>
          <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
            The Only 3,333 Proof of BearCage Contributors
          </p>

          <img
            src={ClaimGif}
            className="w-[160px] h-[160px] rounded-[16px]"
            alt="icon"
          />
          {isMinted && (
            <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
              Congratulations! You have minted 1 BeraBonds for this wallet. Each
              wallet can only mint 1 BeraBonds.
            </p>
          )}
          {isMinted && !isConnectedWithWC && (
            <div className="w-[100%] h-[48px]">
              <WalletButton
                onPress={redirectToHome}
                className="w-[100%] h-[48px]"
              >
                <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                  Check in your asset
                </p>
              </WalletButton>
            </div>
          )}
          <div className="flex flex-col gap-[10px] w-full">
            {MINT_ROUND_DATA.map((element, index: number) => (
              <ElementMint
                key={element.name}
                data={element}
                isMinted={isMinted ?? false}
                roundIdx={index}
              />
            ))}
          </div>
          <div
            className="flex flex-col gap-[5px] w-full cursor-pointer"
            onClick={() =>
              utils.openLink(
                'https://x.com/bearcage_xyz/status/1864669571569078473',
              )
            }
            onKeyDown={() => {}}
          >
            <p className="text-[12px] text-[#69B6EB] font-[400] underline text-center">
              More infor about claiming $BERA
            </p>
            <p className="text-[12px] text-[#69B6EB] font-[400] underline text-center">
              and BeraBonds minting here
            </p>
          </div>
        </div>
      </div>
      <ChooseWallets open={openChooseWallets} setOpen={setOpenChooseWallets} />
    </Container>
  )
}

export { MintPage }
