import FadeLoader from 'react-spinners/FadeLoader'

const LoadingFullScreen = () => {
  return (
    <div className="h-screen flex flex-1 justify-center items-center">
      <FadeLoader
        color={'#FFDE5E'}
        loading={true}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}

export { LoadingFullScreen }
