import ArrowDownLeftYellowSvg from '@/assets/svg/arrow-down-left-yellow.svg'
import ArrowUpRightGreenSvg from '@/assets/svg/arrow-up-right-green.svg'

const className = 'h-[24px] w-[24px]'

const IconSwitcher = () => {
  return (
    <div className="flex flex-col overflow-hidden h-[40px] w-[20px] bg-[#12375366] rounded-lg items-center justify-center">
      <img
        className={className}
        src={ArrowDownLeftYellowSvg}
        alt="ArrowDownLeftYellow"
      />
      <img
        className={className}
        src={ArrowUpRightGreenSvg}
        alt="ArrowUpRightGreen"
      />
    </div>
  )
}

export default IconSwitcher
