import copyIcon from '@/assets/Images/copy-icon.png'
import giftIcon from '@/assets/Images/gift-icon.png'
import inviteIcon from '@/assets/Images/invite-icon.png'
import pointIcon from '@/assets/Images/point-icon.png'
import Template from '@/assets/Images/wallet-default.webp'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button/WalletButton'
import type { Referral } from '@/libs/types'
import { formatNumber } from '@/libs/utils/number'
import {
  handleReactGASend,
  handleReactGASendEvent,
} from '@/libs/utils/tracking'
import { getReferral, getReferralBonus } from '@/libs/utils/wallet/action'
import { authStore } from '@/store/AuthStore'
import { useQuery } from '@tanstack/react-query'
import { useUtils } from '@telegram-apps/sdk-react'
import { useSelector } from '@xstate/store/react'
import { useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import { CardElement } from '../components/TabInvite/CardElement'

const TabInvite = () => {
  const utils = useUtils()
  const userInfo = useSelector(authStore, (state) => state.context.user)

  const { data: referral } = useQuery({
    queryKey: ['referral-list'],
    queryFn: () => getReferral(),
  })

  const { data: referralBonus } = useQuery({
    queryKey: ['referral-bonus'],
    queryFn: () => getReferralBonus(),
  })

  const refererBonusValue = useMemo(() => {
    let result = '0'
    if (!referralBonus) return result
    if (referralBonus.count.totalRewardPoints) {
      result = referralBonus.count.totalRewardPoints.toLocaleString('en-US')
    }
    return result
  }, [referralBonus])

  useEffect(() => {
    handleReactGASend('InviteScreenView')
  }, [])

  const inviteFriend = () => {
    handleReactGASendEvent('InviteShareClick')

    utils.shareURL(
      `${import.meta.env.VITE_APP_URL}?startapp=${userInfo?.referer_code as string}`,
    )
  }
  const copyRefererCode = () => {
    handleReactGASendEvent('InviteCopyClick')

    navigator.clipboard.writeText(
      `${import.meta.env.VITE_APP_URL}?startapp=${userInfo?.referer_code as string}`,
    )
    toast.success('Copied successfully')
  }
  return (
    <div className="pt-[20px] pb-[60px] pl-[10px] pr-[10px] h-[calc(100vh-150px)] overflow-auto relative">
      <p className="text-[#ffffff] text-[32px] leading-10 font-semibold text-center">
        More friends,
      </p>
      <p className="text-[#FFFFFF] text-[32px] leading-10 font-semibold text-center">
        more <span className="text-[#FFDE5E]">$BECA.</span>
      </p>
      <div className="min-h-[180px] rounded-[24px] w-full bg-[#52A7E73D] mt-[20px] flex flex-col p-[20px] gap-[10px] border border-solid border-[#3892CF]">
        <div className="flex align-start justify-start gap-[12px]">
          <img
            src={giftIcon}
            alt="gift icon"
            height="24px"
            width="24px"
            className="w-[30px] h-[30px]"
          />
          <div className="flex flex-col">
            <p className="font-semibold text-[#ffffff] text-[16px]">
              Welcome bonus
            </p>
            <p className="font-[400] text-[#ffffffa3] text-[14px]">
              Instant earn 5,000 $BECA for each friend joining BearCage.
            </p>
          </div>
        </div>
        <div className="flex align-start justify-start gap-[12px]">
          <img
            src={pointIcon}
            alt="point icon"
            height="24px"
            width="24px"
            className="w-[30px] h-[30px]"
          />
          <div className="flex flex-col">
            <p className="font-semibold text-[#ffffff] text-[16px]">
              Life-time bonus
            </p>
            <p className="font-[400] text-[#ffffffa3] text-[14px]">
              You’ll get 10% of each friend’s total winning $BECA.
            </p>
          </div>
        </div>
      </div>
      <p className="font-semibold text-[16px] text-[#ffffffa3] mt-[30px] mb-[10px]">
        {`${formatNumber(referralBonus?.count.totalReferral || 0, 0)} invited friend bonus: `}
        <span className="text-[#ffffff]">{`${formatNumber(refererBonusValue, 0)} $BECA`}</span>
      </p>
      <div className="flex flex-col gap-[10px] pb-[20px]">
        {!!referral?.length &&
          referral.map((element: Referral) => {
            return (
              <CardElement
                avatar={element.photo_url ? element.photo_url : Template}
                name={
                  element.username
                    ? element.username
                    : `${element.first_name} ${element.last_name}`
                }
                money={`+${formatNumber(element.reward_point, 0)}`}
                token={BeraCoinImg}
                key={element.id}
              />
            )
          })}
      </div>
      <div className="fixed bottom-[110px] left-[10px] flex align-center justify-center gap-[20px]">
        <WalletButton
          onPress={inviteFriend}
          className="w-[calc(100vw-85px)] h-[50px]"
        >
          Invite friends{' '}
          <img
            src={inviteIcon}
            className="h-[15px] w-[25px] pl-[5px]"
            alt="invite icon"
          />
        </WalletButton>
        <WalletButton
          onPress={copyRefererCode}
          className="max-w-[55px] h-[50px]"
        >
          <img src={copyIcon} className="h-[20px] w-[20px]" alt="copy icon" />
        </WalletButton>
      </div>
    </div>
  )
}

export { TabInvite }
