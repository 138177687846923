import BGCenter from '@/assets/Images/BG-center.png'
import BGLeft from '@/assets/Images/BG-left.png'
import BGRight from '@/assets/Images/BG-right.png'
import Top1Crown from '@/assets/Images/top1-crown.webp'
import Top2Crown from '@/assets/Images/top2-crown.webp'
import Top3Crown from '@/assets/Images/top3-crown.webp'
import Template from '@/assets/Images/wallet-default.webp'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import type { ListAccount } from '@/libs/types'
import { maskLastThreeChars } from '@/libs/utils/formatStr'
import { useEffect, useState } from 'react'

type Props = {
  listAccount: ListAccount[]
}

export function TopAccount(props: Props) {
  const emptyData: ListAccount = {
    id: '',
    username: '',
    point: 0,
    photo_url: '',
    last_name: '',
    first_name: '',
  }
  const [numberOne, setNumberOne] = useState<ListAccount>()
  const [numberTwo, setNumberTwo] = useState<ListAccount>()
  const [numberThree, setNumberThree] = useState<ListAccount>()

  useEffect(() => {
    if (props.listAccount[0]) {
      setNumberOne(props.listAccount[0])
    } else {
      setNumberOne(emptyData)
    }
    if (props.listAccount[1]) {
      setNumberTwo(props.listAccount[1])
    } else {
      setNumberTwo(emptyData)
    }
    if (props.listAccount[2]) {
      setNumberThree(props.listAccount[2])
    } else {
      setNumberThree(emptyData)
    }
  }, [props.listAccount])

  return (
    <div className="w-full flex gap-0 items-end min-h-[260px]">
      <div className="w-[31%] flex items-center justify-end flex-col bg-cover bg-center h-[213px] relative">
        <img
          src={BGLeft}
          className="absolute w-[122%] h-[100%] left-[-10px] bottom-[0px] max-w-[122%]"
          alt="bg-left"
        />
        <div className="w-full relative mb-2.5">
          <img
            src={Top2Crown}
            className="relative z-[2]"
            width="100%"
            height="auto"
            alt="top-crown"
          />
          <img
            src={numberTwo?.photo_url ? numberTwo.photo_url : Template}
            width="70%"
            height="auto"
            className="rounded-[50%] absolute top-[20%] left-[15%]"
            alt="image2"
          />
        </div>
        <div className="w-full flex items-center justify-center flex-col gap-2.5">
          <p className="truncate w-full text-center text-[#ffffff]">
            {maskLastThreeChars(
              numberTwo?.username
                ? numberTwo?.username
                : `${numberTwo?.last_name} ${numberTwo?.first_name}`,
            )}
          </p>
          <div className="flex gap-1.5">
            <img src={BeraCoinImg} className="w-[16px] h-[16px]" alt="icon" />
            <p className="text-xs font-semibold text-[#FFDE5E]">
              {Number(numberTwo?.point).toLocaleString('en-US')}
            </p>
          </div>
        </div>
      </div>
      <div
        className="w-[38%] flex items-center justify-end flex-col bg-cover bg-center h-[260px] z-[2]"
        style={{ backgroundImage: `url(${BGCenter})` }}
      >
        <div className="w-full relative mb-2.5">
          <img
            src={Top1Crown}
            className="relative z-[2]"
            width="100%"
            height="auto"
            alt="top1-crown"
          />
          <img
            src={numberOne?.photo_url ? numberOne.photo_url : Template}
            width="70%"
            height="auto"
            className="rounded-[50%] absolute top-[20%] left-[15%]"
            alt="image1"
          />
        </div>
        <div className="w-full flex items-center justify-center flex-col gap-2.5">
          <p className="truncate w-full text-center text-[#ffffff]">
            {maskLastThreeChars(
              numberOne?.username
                ? numberOne?.username
                : `${numberOne?.last_name} ${numberOne?.first_name}`,
            )}
          </p>
          <div className="flex gap-1.5">
            <img src={BeraCoinImg} className="w-[16px] h-[16px]" alt="icon" />
            <p className="text-xs font-semibold text-[#FFDE5E]">
              {Number(numberOne?.point).toLocaleString('en-US')}
            </p>
          </div>
        </div>
      </div>
      <div className="w-[31%] flex items-center justify-end flex-col bg-cover bg-center h-[213px] relative">
        <img
          src={BGRight}
          className="absolute w-[122%] h-[100%] right-[-10px] bottom-[0px] max-w-[122%]"
          alt="bg-right"
        />
        <div className="w-full relative mb-2.5">
          <img
            src={Top3Crown}
            className="relative z-[2]"
            width="100%"
            height="auto"
            alt="top3-crown"
          />
          <img
            src={numberThree?.photo_url ? numberThree.photo_url : Template}
            width="70%"
            height="auto"
            className="rounded-[50%] absolute top-[20%] left-[15%]"
            alt="image3"
          />
        </div>
        <div className="w-full flex items-center justify-center flex-col gap-2.5">
          <p className="truncate w-full text-center text-[#ffffff]">
            {maskLastThreeChars(
              numberThree?.username
                ? numberThree?.username
                : `${numberThree?.last_name} ${numberThree?.first_name}`,
            )}
          </p>
          <div className="flex gap-1.5">
            <img src={BeraCoinImg} className="w-[16px] h-[16px]" alt="icon" />
            <p className="text-xs font-semibold text-[#FFDE5E]">
              {Number(numberThree?.point).toLocaleString('en-US')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
