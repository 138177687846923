import ArrowDownLeft from '@/assets/svg/arrow-down-left.svg'
import ArrowUpRight from '@/assets/svg/arrow-up-right.svg'
import { WalletButton } from '@/components/Button/WalletButton'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import {
  gameStore,
  useCanChoice,
  useChoice,
  useEnableChoice,
} from '@/store/Game'

const styleButton = 'h-12 gap-2'

interface GuessProps {
  handleConsumeFuel: () => boolean
}

const eventNames: Record<'BEAR' | 'BULL', string> = {
  BEAR: 'GameBearClick',
  BULL: 'GameBullClick',
}

export const Guess = ({ handleConsumeFuel }: GuessProps) => {
  const choice = useChoice()
  const enableChoice = useEnableChoice()
  const canChoice = useCanChoice()

  const handleChoice = (choice: 'BEAR' | 'BULL') => {
    if (!canChoice) return

    const bool = handleConsumeFuel()

    if (bool) {
      eventNames[choice] && handleReactGASendEvent(eventNames[choice])

      gameStore.send({
        type: 'setChoice',
        choice,
      })
    }
  }

  return (
    <div className="flex flex-col gap-4 items-center py-5">
      <p className="text-sm font-normal leading-6 tracking-tight text-[#CBE9FF]">
        Guess BTC Price In The Next 5s
      </p>
      <div className="flex gap-4 w-full">
        <WalletButton
          color="green"
          className={styleButton}
          rightIcon={
            <img src={ArrowUpRight} alt="ArrowUpRight" className="w-5 h-5" />
          }
          isDisabled={choice === 'BULL' || !enableChoice}
          onPress={() => handleChoice('BULL')}
        >
          BULL
        </WalletButton>
        <WalletButton
          className={styleButton}
          rightIcon={
            <img src={ArrowDownLeft} alt="ArrowDownLeft" className="w-5 h-5" />
          }
          isDisabled={choice === 'BEAR' || !enableChoice}
          onPress={() => handleChoice('BEAR')}
        >
          BEAR
        </WalletButton>
      </div>
    </div>
  )
}
