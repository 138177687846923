import XIcon from '@/assets/Images/x-icon.png'
import OnchainFaucetIcon from '@/assets/svg/task-faucet.svg'
import OnchainMintNftIcon from '@/assets/svg/task-mint.svg'
import OnchainSendTokenIcon from '@/assets/svg/task-send.svg'
import TelegramIcon from '@/assets/Images/telegram-icon.png'
import { TaskInfo } from '@/libs/types/task'

export const generateImageByTask = (task: TaskInfo) => {
  switch(task.task.action_type) {
    case "twitter_follow":
      return XIcon
    case "telegram_boost":
      return TelegramIcon
    case "telegram_display_name":
      return TelegramIcon
    case "telegram_subscribe":
      return TelegramIcon
      case "onchain_faucet":
      return OnchainFaucetIcon
      case "onchain_mint_nft":
        return OnchainMintNftIcon
        case "onchain_send_token":
          return OnchainSendTokenIcon
    default:
      return TelegramIcon
  }
}