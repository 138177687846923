import Mint1 from '@/assets/svg/mint-contain-1.svg'
import Mint2 from '@/assets/svg/mint-contain-2.svg'
import Mint3 from '@/assets/svg/mint-contain-3.svg'
import { NATIVE_TOKEN_ADDRESS } from '../constant/addresses'
import { type Token, TokenType } from '../types'

export const HEADER_HEIGHT = 56
export const BOTTOM_BAR_HEIGHT = 77
export const TAB_HEIGHT = 48
export const WALLET_FEATURE_HEIGHT = 160
export const EMBLA_CAROUSEL_HEIGHT = 130
export const NFT_IDS = [1, 2, 3, 4, 5]
export const isDev = import.meta.env.DEV
export const isDevInsideTelegram =
  import.meta.env.VITE_DEV_INSIDE_TELEGRAM === 'true'
export const isUseTelegramApp = isDevInsideTelegram || !isDev
export const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export const BERA_LOGO =
  'https://app.bearcage.xyz/external-assets/telegram-images/%24BERA.png'

export const BERA_BOND_IMAGE_URL =
  'https://app.bearcage.xyz/external-assets/telegram-images/nft.gif'

export const METHOD_ID = {
  MULTI_SWAP: '0x0b2f6f3f',
  USER_CMD: '0xa15112f9',
}
export const ERROR_MESSAGE = {
  WHITE_LIST_FULL: 'Whitelist slot is full',
}
export const listTokenSwap: Token[] = [
  {
    id: 1,
    contract_address: NATIVE_TOKEN_ADDRESS,
    name: 'BERA',
    symbol: 'BERA',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/bera.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: true,
    is_sendable: true,
  },
  {
    id: 2,
    contract_address: '0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03',
    name: 'HONEY',
    symbol: 'HONEY',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/honey.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 3,
    contract_address: '0x7507c1dc16935b82698e4c63f2746a2fcf994df8',
    name: 'WBERA',
    symbol: 'WBERA',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/wbera.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 4,
    contract_address: '0xd6D83aF58a19Cd14eF3CF6fe848C9A4d21e5727c',
    name: 'STGUSDC',
    symbol: 'STGUSDC',
    decimals: 6,
    image_url:
      'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 5,
    contract_address: '0x2577D24a26f8FA19c1058a8b0106E2c7303454a4',
    name: 'WBTC',
    symbol: 'WBTC',
    decimals: 8,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/btc.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
  {
    id: 6,
    contract_address: '0xE28AfD8c634946833e89ee3F122C06d7C537E8A8',
    name: 'WETH',
    symbol: 'WETH',
    decimals: 18,
    image_url:
      'https://artio-static-asset-public.s3.ap-southeast-1.amazonaws.com/assets/eth.png',
    token_type: TokenType.ERC20,
    is_display: true,
    is_native_token: false,
    is_sendable: true,
  },
]

export const MINT_ROUND_DATA = [
  {
    image: Mint1,
    name: 'Whitelist (GTD)',
    startTime: 1733497200, // 1733497200
    endTime: 1733540400, // 1733540400
    price: 1,
  },
  {
    image: Mint2,
    name: 'Whitelist (FCFS)',
    startTime: 1733540400, // 1733540400
    endTime: 1733626800, // 1733626800
    price: 2,
  },
  {
    image: Mint3,
    name: 'Public (FCFS)',
    startTime: 1733626800, // 1733626800
    endTime: 1733713200, // 1733713200
    price: 2,
  },
]

export const TIME_TO_MINT = MINT_ROUND_DATA[0].startTime
