import { useMutation } from '@tanstack/react-query'
import request from '../config/request'

interface IClamWL {
  address: string
}

async function clamWL(data: IClamWL) {
  const res = await request.post('/bera-bonds', data)
  return res.data
}

export const useMutationClamWL = () => {
  return useMutation({
    mutationFn: (data: IClamWL) => clamWL(data),
  })
}
