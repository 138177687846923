import Add from '@/assets/svg/add-circe.svg'
import Down from '@/assets/svg/anchor-down.svg'
import Share from '@/assets/svg/share.svg'
import { WalletButton } from '@/components/Button'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import { useMemeTokens } from '@/libs/hooks'
import { type MemeToken, Tabs } from '@/libs/types'
import { formatNumber, } from '@/libs/utils/number'
import { bottomTabStore } from '@/store/BottomTabStore'
import { debounce } from 'es-toolkit'
import { useEffect, useRef, useState } from 'react'
import { Input } from 'react-aria-components'

interface Props {
  setTokenSelect: (value: MemeToken) => void
  setScrollElementMeme: (value: 'trading' | 'chart' | null) => void
}
const sortOptions = [
  {
    label: 'Creation Time',
    value: 'created_at',
  },
  {
    label: 'Market Cap',
    value: 'market_cap',
  },
  {
    label: 'My Token',
    value: 'my_token',
  },
]

const TabMeme = (props: Props) => {
  const { setTokenSelect } = props
  const scrollableDivRef = useRef<HTMLDivElement>(null)
  const searchBarRef = useRef<HTMLDivElement>(null)
  // const [tab, setTab] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const pageSize = 10
  const [page, setPage] = useState<number>(1)
  const [listData, setListData] = useState<MemeToken[]>([])
  const [showLoadingTop, setShowLoadingTop] = useState<boolean>(false)
  const [sort, setSort] = useState<'created_at' | 'market_cap' | 'my_token'>('created_at')
  // const handleChangeTabValue = (value: number) => {
  //   setTab(value)
  // }
  const [search, setSearch] = useState<string>('')
  const [openSort, setOpenSort] = useState<boolean>(false)
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListData([])
    const debouncedFunction = debounce(() => {
      setSearch(e.target.value)
      setPage(1)
    }, 500)
    debouncedFunction()
  }
  const handleChangeTab = (tab: Tabs) => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab,
    })
  }

  const { tokens, isLoading, pagination, refetch } = useMemeTokens({
    page: page,
    pageSize: pageSize,
    searchTerm: search,
    sort,
  })

  useEffect(() => {
    if (tokens?.length > 0) {
      const idTokenMeme = new Set(listData.map((obj) => obj.id))
      const newTokens = tokens.filter(
        (obj: MemeToken) => !idTokenMeme.has(obj.id),
      )

      const newListData: MemeToken[] =
        pagination.page === 1 ? [...tokens] : [...listData, ...newTokens]

      setListData(newListData)
    }
    if(tokens?.length === 0) {
      if(pagination?.page === 1) {
        setListData([])
      }
    }
  }, [tokens, pagination])

  useEffect(() => {
    if(!loading) {
      setShowLoadingTop(false)
    }
  }, [loading])

  const scrollList = async () => {
    if (scrollableDivRef.current) {
      const scrollTop = scrollableDivRef.current.scrollTop
      const scrollHeight = scrollableDivRef.current.scrollHeight
      const clientHeight = scrollableDivRef.current.clientHeight
      if (!loading && !isLoading) {
        if(!showLoadingTop) {
          setShowLoadingTop(true)
        }
        if (scrollTop + clientHeight >= scrollHeight && pagination.hasMore) {
          setLoading(true)
          await new Promise((resolve) => setTimeout(resolve, 1000))
          setPage(page + 1)
          setLoading(false)
        }
        if(scrollTop === 0) {
          setLoading(true)
          setPage(1)
          await refetch()
          setLoading(false)
        }
      }
      scrollableDivRef.current.setAttribute(
        'data-previous-scrollTop',
        scrollTop.toString(),
      )
    }
  }

  useEffect(() => {
    props.setScrollElementMeme(null)
  }, [props.setScrollElementMeme])

  return (
    <div className="w-full h-full">
      <WalletButton
        className="bottom-[120px] right-[14px] h-[48px] rounded-xl text-[16px] font-[700] fixed flex items-center justify-center gap-[10px] z-10"
        color="green"
        onPress={() => handleChangeTab(Tabs.CreateMeme)}
      >
        <img src={Add} className="w-[24px] h-[24px]" alt="add" />
        Create
      </WalletButton>
      {/* <ListTab tab={tab} handleChangeTab={handleChangeTabValue} /> */}
      <div
        className="p-[10px] flex items-center justify-start duration-100 h-[70px]"
        ref={searchBarRef}
      >
        <Input
          className="w-[55%] bg-[#003F60] border-[1px] border-[#003F60] focus:border-[#003F60] px-4 py-3 rounded-[24px] text-white placeholder-white placeholder-opacity-64 text-sm mt-[10px] h-[40px]"
          placeholder="Search..."
          onChange={handleChangeSearch}
          // value={search}
        />
        <div
          onClick={() => setOpenSort(true)}
          onKeyDown={() => {}}
          className="border-[#3892CF] border-solid border-[1px] rounded-[24px] bg-[transparent] w-[40%] ml-[5%] h-[40px] mt-[10px] flex items-center justify-center gap-[10px]"
        >
          <p className="text-[14px] text-[#ffffff] font-bold">
            {sortOptions.find((item) => item.value === sort)?.label}
          </p>
          <img src={Down} alt="down arrow" className="w-[20px] h-[20px]" />
        </div>
      </div>
      <div
        ref={scrollableDivRef}
        onScroll={() => scrollList()}
        className="flex flex-col gap-[20px] p-[10px] overflow-auto h-[calc(100vh-220px)] pb-[30px] duration-100"
      >
        {showLoadingTop && <div className='h-[30px] w-full'>
          <div className="w-full flex items-center justify-center">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>}
        {listData.map((element) => {
          return (
            <div
              key={element.id}
              onClick={() => {
                setTokenSelect(element)
                handleChangeTab(Tabs.DetailMeme)
              }}
              onKeyDown={() => {}}
              className="w-full border-[1.5px] border-solid border-[#3892CF] rounded-[14px] bg-[#52A7E73D] p-[10px] flex gap-[20px]"
            >
              <img
                src={element.image_url as string}
                alt="coin"
                className="w-[32px] h-[32px] rounded-[12px]"
              />
              <div className="flex flex-col gap-[2px] relative w-full h-full">
                <div className="flex items-start justify-between w-full">
                  <p className="text-[#FFDE5E] text-[18px] font-bold">
                    {element.name}
                  </p>
                </div>
                <div className="flex items-center justify-start gap-[20px] pt-[8px]">
                  <p className="text-[#ffffffa0] text-[12px] font-[400]">
                    PRICE{" "} 
                    <span
                      className='text-[#ffffff] font-bold'
                    >
                      ${Number.parseFloat(element?.price.toFixed(2))}
                    </span>
                  </p>
                  <p className="text-[#ffffffa0] text-[12px] font-[400]">
                    H24{' '}
                    <span
                      className={`${
                        element?.change24h > 0
                          ? 'text-[#52C41A]'
                          : 'text-[#FF4D4F]'
                      } font-bold`}
                    >
                      {element?.change1h && Number.parseFloat(element?.change24h.toFixed(2))}%
                    </span>
                  </p>
                  <p className="text-[#ffffffa0] text-[12px] font-[400]">
                    H1{' '}
                    <span
                      className={`${
                        element?.change1h > 0
                          ? 'text-[#52C41A]'
                          : 'text-[#FF4D4F]'
                      } font-bold`}
                    >
                      {element?.change1h && Number.parseFloat(element?.change1h.toFixed(2))}%
                    </span>
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-start gap-[5px]">
                  <div className="bg-[#003F60] rounded-[8px] p-[4px]">
                    <p className="text-[#ffffffa0] text-[12px] font-[400]">
                      VOL{' '}
                      <span className="text-[#ffffff] font-bold">
                        ${formatNumber(element.volume1h)} BERA
                      </span>
                    </p>
                  </div>
                  <div className="bg-[#003F60] rounded-[8px] p-[4px]">
                    <p className="text-[#ffffffa0] text-[12px] font-[400]">
                      MCAP{' '}
                      <span className="text-[#ffffff] font-bold">{`$${formatNumber(Number.parseFloat(element.marketCap).toFixed(4))} BERA`}</span>
                    </p>
                  </div>
                  <div className="bg-[#003F60] rounded-[8px] p-[4px] flex gap-[5px]">
                    <p className="text-[#ffffffa0] text-[12px] font-[400]">
                      LIQ{' '}
                      <span className="text-[#ffffff] font-bold">
                        ${formatNumber(element.liquidity)}
                      </span>
                    </p>
                    <img
                      src='https://app.bearcage.xyz/external-assets/telegram-images/%24BERA.png'
                      alt="bera coin"
                      className="w-[16px] h-[16px] rounded-full"
                    />
                  </div>
                </div>
                <div className='absolute top-[0px] right-[0px] bg-[#0C1E2B] rounded-[16px] flex items-center justify-center w-[32px] h-[24px] cursor-pointer'>
                  <img src={Share} alt="down arrow" className="w-[16px] h-[16px]" />
                </div>
              </div>
            </div>
          )
        })}
        {listData?.length === 0 && !loading && !isLoading && (
          <div className='text-center'>
            <p className="text-[14px] text-[#ffffff] font-bold">
              No record
            </p>
          </div>
        )}
        {(loading || isLoading) && (
          <div className="w-full flex items-center justify-center">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        )}
        <BottomSheetModal
          open={openSort}
          title="Sort"
          onClose={() => setOpenSort(false)}
          heightPercent={0.52}
        >
          <div className="flex flex-col px-4 py-5 gap-3">
            {sortOptions.map((item) => (
              <button
                key={item.value}
                type="button"
                onClick={() => {
                  setPage(1)
                  setSort(item.value as 'created_at' | 'market_cap' | 'my_token')
                  setOpenSort(false)
                }}
                className="w-full border-b-[1.5px] border-solid border-b-[#3892CF] rounded-[6px] bg-[#52A7E73D] p-[10px] flex gap-[20px]"
              >
                <p className="text-[14px] text-[#ffffff] font-bold">
                  {item.label}
                </p>
              </button>
            ))}
          </div>
        </BottomSheetModal>
      </div>
    </div>
  )
}

export { TabMeme }
