import './sentry'
import ReactDOM from 'react-dom/client'

import { Root } from 'components/Root'

// Uncomment this import in case, you would like to develop the application even outside
// the Telegram application, just in your browser.
import './mockEnv.ts'

import '@telegram-apps/telegram-ui/dist/styles.css'
import './styles/index.css'
import './styles/embla-carousel.css'
import { postEvent } from '@telegram-apps/sdk-react'

postEvent('web_app_expand')

ReactDOM.createRoot(document.getElementById('root')!).render(<Root />)
