import MintCountdownBg from '@/assets/svg/countdown-mint-top.svg'
import MintBG from '@/assets/svg/mint-bg.svg'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Countdown } from './Countdown'

interface MintRound {
  image: string
  name: string
  startTime: number
  endTime: number
  price: number
}

interface MintCountdownProps {
  mintData: MintRound[]
}

export const MintCountdown = ({ mintData }: MintCountdownProps) => {
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(
    Math.floor(new Date().getTime() / 1000),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(new Date().getTime() / 1000))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const { activeRound, nextRound } = useMemo(() => {
    const active = mintData.find(
      (round) => currentTime >= round.startTime && currentTime <= round.endTime,
    )
    const next = mintData.find((round) => round.startTime > currentTime)

    return { activeRound: active, nextRound: next }
  }, [mintData, currentTime])

  if (!activeRound && !nextRound) return null

  if (activeRound) {
    return (
      <div className="min-h-[106px] w-full bg-[#003F60] relative pb-[1rem] py-[10px] pl-[15px]" 
        onClick={() => navigate({
          pathname: '/mint',
        })}
        onKeyDown={() => {}}
      >
        <img
          src={MintCountdownBg}
          alt="mint countdown"
          className="w-[auto] min-h-[106px] absolute right-0 top-0"
        />
        <div className="flex flex-col items-start justify-start gap-[10px]">
          <p className="text-[16px] text-[#ffffff] font-bold">
            {activeRound.name} Round is Live!
          </p>
          {nextRound && (
            <>
              <p className="text-[10px] text-[#ffffff] font-[400]">
                {nextRound.name} Round starts in
              </p>
              <Countdown targetDate={nextRound.startTime} />
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className="min-h-[106px] w-full relative pb-[1rem] py-[10px] pl-[15px] bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${MintBG})` }}
      onClick={() => navigate({
        pathname: '/mint',
      })}
      onKeyDown={() => {}}
    >
      <div className="flex flex-col items-start justify-start gap-[10px]">
        <p className="text-[16px] text-[#ffffff] font-bold">
          {nextRound?.name} Round starts in
        </p>
        <Countdown targetDate={Number(nextRound?.startTime)} />
      </div>
    </div>
  )
}
