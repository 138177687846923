const botName = import.meta.env.VITE_BOT_NAME as string

export const TELEGRAM_CLOUD_STORAGE_KEY = {
  ACTIVE_ACCOUNT: `${botName}_activeAccount`,
  ACCOUNTS: `${botName}_accounts`,
  TXS_SWAP: `${botName}_txsSwap`,
  TXS_ADD_LIQUIDITY: `${botName}_txsAddLiquidity`,
  TXS_BERA_PUMP: `${botName}_txsBeraPump`,
  TXS_CREATE_MEME: `${botName}_txsCreateMeme`,
  TXS_SEND_BERA: `${botName}_txsSendBera`,
  TXS_MINT_NFT: `${botName}_txsMintNFT`,
  TXS_MINT_NFT_BERA_BOND: `${botName}_txsMintNFTBeraBond`,
  TXS_STAKE: `${botName}_txsStake`,
} as const
