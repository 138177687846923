import { useDisabledBackButtonOnThisPage } from '@/libs/hooks'
import { BottomTab } from './bottom-tabs'

const IndexPage = () => {
  useDisabledBackButtonOnThisPage()

  return <BottomTab />
}

export { IndexPage }
