import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import { useKeyboardVisibility } from '@/libs/hooks/useKeyboardVisibility'
import type { Token, TransactionInfo } from '@/libs/types'
import { sendTokenStore } from '@/store/SendTokenStore'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { useState } from 'react'
import { Input, Text } from 'react-aria-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isAddress } from 'viem'

const InputReceiver: React.FC = () => {
  useKeyboardVisibility()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const amount = searchParams.get('amount')
  const txFee = searchParams.get('txFee')

  const { selectedToken } = useSelector(
    sendTokenStore,
    (state) => state.context,
  )
  const [receiver, setReceiver] = useState('')

  const [errAddress, setErrAddress] = useState<string | undefined>()

  const handleChangeReceiver = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrAddress(undefined)

    const receiverAddr = e.target.value
    if (!isAddress(receiverAddr)) {
      setErrAddress('Invalid address')
    }

    setReceiver(receiverAddr)
  }

  const handleSubmit = () => {
    const txData: TransactionInfo = {
      selectedToken: selectedToken as Token,
      receiver,
      amount: Number(amount),
      txFee: Number(txFee),
    }
    console.log({ txData })
    sendTokenStore.send({
      type: 'setTransactionData',
      txData,
    })
    navigate('/confirm-transaction')
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between py-5">
        <div className="grid gap-4">
          <div className="flex flex-col">
            <Text className="text-xl text-white font-semibold">
              Receiving address
            </Text>
            <Text className="text-sm opacity-64">
              Enter the wallet address you want to send to
            </Text>
          </div>
          <div>
            <Input
              autoFocus
              className="w-full bg-transparent border-[1px] focus:border-yellow px-4 py-3 rounded-xl text-white placeholder-white placeholder-opacity-64"
              placeholder="Enter wallet address"
              onChange={handleChangeReceiver}
              value={receiver}
            />
            {errAddress && (
              <p className="text-red-500 text-sm mt-2">{errAddress}</p>
            )}
          </div>
        </div>
        <WalletButton
          isDisabled={!!errAddress || !receiver}
          className="max-h-12 mb-12"
          onPress={handleSubmit}
        >
          Continue
        </WalletButton>
      </Content>
    </Container>
  )
}

export { InputReceiver }
