import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { Pagination } from '../types';

async function getSaleHIstory({
  tokenAddress,
  page,
  pageSize,
  filter
}: Pagination & { tokenAddress: string; page: number, pageSize: number, filter: string }) {
  const res = await request.get(
    `/meme-history/${tokenAddress}`,
    {
      params: {
        page,
        pageSize,
        filter
      },
    },
  )
  return res.data
}

export const useHistorySale = ({
  tokenAddress,
  page,
  pageSize,
  filter
}: Pagination & { tokenAddress: string; page: number, pageSize: number, filter: string }) => {
  const { data, ...res } = useQuery({
    queryKey: ['chart-data-token', tokenAddress, page, pageSize, filter],
    queryFn: () => getSaleHIstory({ tokenAddress, page, pageSize, filter }),
  })
  return { data: data?.data ?? [], pagination: data?.pagination, ...res }
}
