import TransactionError from "@/assets/svg/transaction-error.svg"
import TransactionSuccess from "@/assets/svg/transaction-success.svg"
import WarningIcon from "@/assets/svg/warning-icon.svg"
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from "@/components/Button";
import { BottomSheetModal } from "@/components/Modal/BottomSheetModal";
import { useCheckFaucet } from "@/libs/hooks";
import { useGetETHMainnetBalance } from "@/libs/hooks/useGetETHMainnetBalance";
import type { TaskInfo } from "@/libs/types/task";
import { generateImageByTask } from "@/libs/utils/task/task";
import { walletStore } from "@/store/WalletStore";
import { useQueryClient } from "@tanstack/react-query";
import { useUtils } from "@telegram-apps/sdk-react";
import { useSelector } from "@xstate/store/react";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export function TaskFaucet (
  open: boolean, 
  setOpen:(value: boolean) => void, 
  title: string, 
  task: TaskInfo, 
  goTask:(id: number) => void, 
  loading: boolean,
  claimTask:(id: number) => void
  ) {
  
  const utils = useUtils()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const activeAccount = useSelector(walletStore, (state) => state.context.activeAccount)
  const { isFaucet } = useCheckFaucet(activeAccount?.address as string)
  const { balanceETHMainnet } = useGetETHMainnetBalance(activeAccount?.address as string)
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false)
  const [errorETHOpen, setErrorETHOpen] = useState<boolean>(false)
  const [errorFaucetOpen, setErrorFaucetOpen] = useState<boolean>(false)
  const [afterFaucetOpen, setAfterFaucetOpen] = useState<boolean>(false)
  const [loadingFaucet, setLoadingFaucet] = useState<boolean>(false)
  
  const closeDialog = () => {
    setOpen(false)
  }

  const checkTask = async () => {
    if(Number(balanceETHMainnet) > 0.001 && isFaucet) {
      await goTask(task.task.id)
      setOpen(false)
      setSuccessDialogOpen(true)
    }
    if(Number(balanceETHMainnet) < 0.001) {
      setOpen(false)
      setErrorETHOpen(true)
    } else if(!isFaucet) {
      setOpen(false)
      setErrorFaucetOpen(true)
    }
  }

  const checkTaskAfterFaucet = async () => {
    setLoadingFaucet(true)
    await new Promise((resolve) => setTimeout(resolve, 3000))
    if(!isFaucet) {
      setAfterFaucetOpen(false)
      toast.error("Claim faucet false")
    } else {
      setAfterFaucetOpen(false)
      checkTask()
    }
    setLoadingFaucet(false)
  }

  const ClaimTaskSuccess = async () => {
    await claimTask(task.task.id)
    // toast.success(`You have successfully claimed ${task.task.reward_point} $BECA`)
    queryClient.invalidateQueries({ queryKey: ['task'] })
    setSuccessDialogOpen(false)
  }

  const actionErrorFaucet = async () => {
    if(activeAccount?.address) {
      navigator.clipboard.writeText(activeAccount?.address)
      toast.success('Copy wallet address successfully')
      await new Promise((resolve) => setTimeout(resolve, 1000))
      utils.openLink("https://bartio.faucet.berachain.com/")
      setAfterFaucetOpen(true)
      setErrorFaucetOpen(false)
    } else {
      toast.error('Copy wallet address false')
    }
  }

  const buttonLoading = () => {
    return (
      <WalletButton className="w-full h-[48px] border-0" color="blue-800">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </WalletButton>
    )
  }

  const InfoModal = () => {
    return (
      <BottomSheetModal
        open={open}
        title={title}
        onClose={() => closeDialog()}
        heightPercent={0.5}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-center pt-[30px]">
          <img
            src={task?.task?.image_url ? task.task.image_url : generateImageByTask(task)}
            alt="telegram icon"
            className="w-[64px] h-[64px]"
          />
          <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
            {task.task.title}
          </p>
          <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
            {task.task.description}
          </p>
          <div className="flex items-center justify-center gap-[5px] pb-[10px]">
            <img
              src={BeraCoinImg}
              width="24px"
              height="24px"
              alt="token icon"
            />
            <p className="text-[20px] text-[#FFDE5E] leading-[24px] font-[700]">
              {`${task.task.reward_point}`}
            </p>
            <p className="text-[20px] text-[#FFFFFF] leading-[24px] font-[700]">
              $BECA
            </p>
          </div>
          {!loading && (
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => checkTask()}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
              Join & Finish task
              </p>
            </WalletButton>
          )}
          {loading && <div className="w-full">{buttonLoading()}</div>}
        </div>
      </BottomSheetModal>
    )
  }

  const SuccessDialog = () => {
    return (
      <BottomSheetModal
        open={successDialogOpen}
        title={title}
        onClose={() => setSuccessDialogOpen(false)}
        heightPercent={0.5}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
        <div className="w-full h-full flex flex-col items-center justify-center">
            <img
              src={TransactionSuccess}
              alt="transaction success"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              Mission successful
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
            Tip: Claim BERA every day to get BERA + BECA. The more BERA you hold, the better your chances of getting an airdrop.
            </p>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            {!loading && (
              <WalletButton
                className="w-full h-[48px]"
                onPress={() => ClaimTaskSuccess()}
              >
                <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                  Claim reward
                </p>
              </WalletButton>
            )}
            {loading && <div className="w-full">{buttonLoading()}</div>}
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  const ErrorETHValue = () => {
    return (
      <BottomSheetModal
        open={errorETHOpen}
        title={title}
        onClose={() => setErrorETHOpen(false)}
        heightPercent={0.7}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
          <div className="w-full flex flex-col items-center justify-center">
            <img
              src={TransactionError}
              alt="transaction error"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              Please make sure you can finish this task
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
              You cannot claim BERA now. You must have at least 0.001 ETH on the Ethereum Mainnet in your wallet to use the faucet. You can add ETH to your current wallet or import a wallet that has at least 0.001 ETH before continuing to finish this task
            </p>
          </div>
          <div className='flex flex-col w-full gap-[10px]'>
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => navigate({
                pathname: '/receive',
              })}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Send ETH to BearCage Wallet
              </p>
            </WalletButton>
            <WalletButton
              className="w-full h-[48px]"
              color="blue-800"
              onPress={() => navigate({
                pathname: '/import-wallet',
              })}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Import Wallet with ETH
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  const ErrorFaucet = () => {
    return (
      <BottomSheetModal
        open={errorFaucetOpen}
        title={title}
        onClose={() => setErrorFaucetOpen(false)}
        heightPercent={0.6}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
          <div className="flex flex-col items-center justify-center">
            <img
              src={WarningIcon}
              alt="transaction error"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              One more step to get 1 BERA + 1000 BECA
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
              Please follow the instructions on the next page to finish the faucet claim process. Your $BERA and $BECA rewards will arrive immediately after you finish claiming from the faucet.
            </p>
          </div>
          <div className='flex flex-col w-full gap-[10px]'>
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => actionErrorFaucet()}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
              Claim Faucet from Bera
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  const AfterFaucet = () => {
    return (
      <BottomSheetModal
        open={afterFaucetOpen}
        title={title}
        onClose={() => setAfterFaucetOpen(false)}
        heightPercent={0.5}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-center pt-[30px]">
          <img
            src={task?.task?.image_url ? task.task.image_url : generateImageByTask(task)}
            alt="telegram icon"
            className="w-[64px] h-[64px]"
          />
          <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
            {task.task.title}
          </p>
          <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
            {task.task.description}
          </p>
          <div className="flex items-center justify-center gap-[5px] pb-[10px]">
            <img
              src={BeraCoinImg}
              width="24px"
              height="24px"
              alt="token icon"
            />
            <p className="text-[20px] text-[#FFDE5E] leading-[24px] font-[700]">
              {`${task.task.reward_point}`}
            </p>
            <p className="text-[20px] text-[#FFFFFF] leading-[24px] font-[700]">
              $BECA
            </p>
          </div>
          {!loadingFaucet && (
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => checkTaskAfterFaucet()}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
              Check
              </p>
            </WalletButton>
          )}
          {loadingFaucet && <div className="w-full">{buttonLoading()}</div>}
        </div>
      </BottomSheetModal>
    )
  }
  return (
    <div>
      {InfoModal()}
      {SuccessDialog()}
      {ErrorETHValue()}
      {ErrorFaucet()}
      {AfterFaucet()}
    </div>
  )
}