import { TokenCardSkeleton } from '@/components/Skeleton'
import { TokenCard } from '@/components/Token'
import { TokenAssetsContainer } from './components/TokenAssetsContainer'
import { useTokens } from './hooks/useTokens'

const TokenAssets: React.FC = () => {
  const { tokens, isLoading } = useTokens({
    sendable: false,
  })

  return (
    <TokenAssetsContainer>
      {isLoading ? (
        <TokenCardSkeleton />
      ) : (
        tokens.map((token) => <TokenCard key={token.id} token={token} />)
      )}
    </TokenAssetsContainer>
  )
}

export { TokenAssets }
