import Back from '@/assets/svg/back-icon.svg'
import { WalletButton } from '@/components/Button'
import { stakeStore } from '@/store/StakeStore'
import { useSelector } from '@xstate/store/react'

const CongratulationsStake = () => {
  const { selectedNft } = useSelector(stakeStore, (state) => state.context)

  return (
    <div className="w-full min-h-[100vh] flex flex-col items-center justify-between py-[30px] px-[20px]">
      <div className="w-full flex flex-col gap-[20px] items-center justify-center max-h-[calc(100vh-120px)] overflow-auto">
        <div
          className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px]"
          onClick={() =>
            stakeStore.send({
              type: 'setStakeStep',
              step: 'list',
            })
          }
          onKeyDown={() => {}}
        >
          <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
          <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
            Back
          </p>
        </div>
        <div className="w-full flex items-center justify-center">
          <div className="rounded-[18px] p-[1.5px] w-[50%] bg-[#52A7E73D] relative">
            <img
              src={selectedNft?.image_url}
              alt={`NFT ${selectedNft?.name}`}
              className="w-full h-[auto] rounded-[18px] border-[1.5px] border-solid border-[#3892CF]"
            />
            <div className="p-[10px] text-left">
              <p className="text-[#FFFFFF] text-[14px] font-[700]">
                {selectedNft?.name}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-[10px]">
          <p className="text-[24px] text-[#ffffff] font-bold text-center">
            Congratulations!
          </p>
          <p className="text-[14px] text-[#ffffff] font-bold text-center">
            You have staked{' '}
            <span className="text-[#FAC808]">{selectedNft?.name}</span>{' '}
            successfully!
          </p>
          <p className="text-[14px] text-[#FFFFFF80] font-[400] text-center">
            After staking, the system will start record your interaction metrics
            on Bearcage platform. This will be the reference for your future
            token allocation and rewards on Bearcage
          </p>
        </div>
      </div>
      <div className="w-[100%] h-[48px]">
        <WalletButton
          onPress={() =>
            stakeStore.send({
              type: 'setStakeStep',
              step: 'list',
            })
          }
          className="w-[100%] h-[48px]"
        >
          <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
            Check now
          </p>
        </WalletButton>
      </div>
    </div>
  )
}

export { CongratulationsStake }
