import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { Pagination } from '../types'

async function getMemeTokens({
  page = 1,
  pageSize = 10,
  searchTerm,
  sort,
}: Pagination & {
  searchTerm?: string
  sort?: string
}) {
  const res = await request.get('meme-coins', {
    params: {
      page,
      pageSize,
      searchTerm,
      sort,
    },
  })
  return res.data
}

export const useMemeTokens = ({
  page = 1,
  pageSize = 10,
  searchTerm,
  sort,
}: Pagination & {
  searchTerm?: string
  sort?: string
}) => {
  const { data, ...res } = useQuery({
    queryKey: ['list-meme-tokens', page, pageSize, searchTerm, sort],
    queryFn: () => getMemeTokens({ page, pageSize, searchTerm, sort }),
    refetchOnWindowFocus: true,
  })

  return { tokens: data?.data ?? [], pagination: data?.pagination, ...res }
}
