import { LPCONDUIT, NATIVE_TOKEN_ADDRESS } from '@/libs/constant/addresses'
import CrocQueryAbi from '@/libs/evm/abi/CrocQuery.json'
import CrocSwapDex from '@/libs/evm/abi/CrocSwapDex.json'
import MultiswapAbi from '@/libs/evm/abi/MultiSwap.json'
import type {
  GetRouterSteps,
  QueryPrice,
  RouterStep,
  Step,
  Token,
} from '@/libs/types'
import { transactionStore } from '@/store/TransactionStore'
import {
  http,
  type PrivateKeyAccount,
  createPublicClient,
  decodeAbiParameters,
  decodeFunctionData,
  encodeAbiParameters,
  erc20Abi,
  formatGwei,
  formatUnits,
  isAddressEqual,
  parseUnits,
} from 'viem'
import { berachainTestnetbArtio, mainnet } from 'viem/chains'
import { MAX_UINT256, METHOD_ID, listTokenSwap } from '../constans'
import { contractAddressList } from '../contractAddress'
import { isNativeToken } from './action'

const client = createPublicClient({
  chain:
    import.meta.env.VITE_NODE_ENV === 'production'
      ? mainnet
      : berachainTestnetbArtio,
  transport: http(),
})

export const getAllowance = async ({
  token,
  walletAddress,
  spender,
}: {
  token: Token
  walletAddress: string
  spender: string
}) => {
  let formatAllowance = ''
  const result = await client.readContract({
    address: token.contract_address as `0x${string}`,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [walletAddress as `0x${string}`, spender as `0x${string}`],
  })
  formatAllowance = formatUnits(result, token.decimals)

  return formatAllowance
}

export async function simulateApproveToken({
  token,
  spender,
}: {
  token: Token
  spender: string
}) {
  const { request } = await client.simulateContract({
    abi: erc20Abi,
    functionName: 'approve',
    address: token.contract_address as `0x${string}`,
    args: [spender as `0x${string}`, BigInt(MAX_UINT256)],
  })
  return request
}

export const getRouterSteps = async ({
  mode,
  from,
  to,
  amount,
}: GetRouterSteps) => {
  const formatAmount = parseUnits(amount.toString(), from.decimals)
  const prefixApiUrl = import.meta.env.VITE_BERA_BEX_PREFIX_API_URL
  const wbera = listTokenSwap.find((token) => token.symbol === 'WBERA')
  const adjustedFrom = isAddressEqual(
    from.contract_address as `0x${string}`,
    NATIVE_TOKEN_ADDRESS,
  )
    ? wbera?.contract_address
    : from.contract_address
  const adjustedTo = isAddressEqual(
    to.contract_address as `0x${string}`,
    NATIVE_TOKEN_ADDRESS,
  )
    ? wbera?.contract_address
    : to.contract_address

  const res = await fetch(
    `${prefixApiUrl}/route?fromAsset=${adjustedFrom}&toAsset=${adjustedTo}&amount=${formatAmount}`,
  )

  if (!res.ok) {
    throw new Error(`Error fetching router steps: ${res.statusText}`)
  }

  const data: RouterStep = await res.json()

  if (
    mode === 'multiSwap' &&
    (isAddressEqual(
      from.contract_address as `0x${string}`,
      NATIVE_TOKEN_ADDRESS,
    ) ||
      isAddressEqual(
        to.contract_address as `0x${string}`,
        NATIVE_TOKEN_ADDRESS,
      ))
  ) {
    for (const step of data.steps) {
      if (
        isAddressEqual(
          step.base as `0x${string}`,
          wbera?.contract_address as `0x${string}`,
        )
      ) {
        step.base = NATIVE_TOKEN_ADDRESS
      }
      if (
        isAddressEqual(
          step.quote as `0x${string}`,
          wbera?.contract_address as `0x${string}`,
        )
      ) {
        step.quote = NATIVE_TOKEN_ADDRESS
      }
    }
  }

  return data.steps
}

export async function previewMultiSwap({
  from,
  to,
  amount,
}: GetRouterSteps): Promise<{
  quantity: string
  predictedQty: string
  rate: number
} | null> {
  const steps = await getRouterSteps({
    mode: 'preview',
    from,
    to,
    amount,
  })
  console.log({ steps })

  let convertQuantity = ''
  let convertPredictedQty = ''
  try {
    const formatAmount = parseUnits(amount.toString(), from.decimals)

    const result = await client.readContract({
      address: contractAddressList.MultiSwap,
      abi: MultiswapAbi,
      functionName: 'previewMultiSwap',
      args: [steps, formatAmount],
    })
    const [quantity, predictedQty] = result as [bigint, bigint]

    convertQuantity = formatUnits(quantity, to.decimals)
    convertPredictedQty = formatUnits(predictedQty, to.decimals)
  } catch (error) {
    console.log('previewMultiSwap error', error)
  }

  const rate = Number(convertQuantity) / Number(amount)

  return {
    quantity: convertQuantity,
    predictedQty: convertPredictedQty,
    rate,
  }
}

export async function estimateGasMultiSwap({
  from,
  to,
  amount,
  minOut,
  signer,
}: GetRouterSteps & {
  minOut: number
  signer: PrivateKeyAccount
}) {
  const steps = await getRouterSteps({
    mode: 'multiSwap',
    from,
    to,
    amount,
  })

  const formatAmount = parseUnits(amount.toString(), from.decimals)
  const formatMinOut = parseUnits(minOut.toString(), to.decimals)

  const { maxFeePerGas } = await client.estimateFeesPerGas()

  const maxFee = formatGwei(maxFeePerGas)

  const gas = await client.estimateContractGas({
    address: contractAddressList.MultiSwap,
    abi: MultiswapAbi,
    functionName: 'multiSwap',
    args: [steps, formatAmount, formatMinOut],
    value: isNativeToken(from) ? formatAmount : undefined,
    account: signer.address,
  })
  const gasLimit = gas.toString()
  const estimateFee = (Number(gasLimit) * Number(maxFee)) / 1e9
  const formatFee = Math.floor(estimateFee * 1e8) / 1e8
  return formatFee
  // return request
}

export async function simulateMultiSwap({
  from,
  to,
  amount,
  minOut,
  signer,
}: GetRouterSteps & {
  minOut: number
  signer: PrivateKeyAccount
}) {
  const steps = await getRouterSteps({
    mode: 'multiSwap',
    from,
    to,
    amount,
  })

  const formatAmount = parseUnits(amount.toString(), from.decimals)
  const formatMinOut = parseUnits(minOut.toString(), to.decimals)
  const { request } = await client.simulateContract({
    address: contractAddressList.MultiSwap,
    abi: MultiswapAbi,
    functionName: 'multiSwap',
    args: [steps, formatAmount, formatMinOut],
    value: isAddressEqual(
      from.contract_address as `0x${string}`,
      NATIVE_TOKEN_ADDRESS,
    )
      ? formatAmount
      : undefined,
    account: signer,
  })
  return request
}

export async function queryPrice({
  from,
  to,
  fromAmount,
}: {
  from: Token
  to: Token
  fromAmount: number
}) {
  console.log({ fromAmount })
  const poolIdx = await getRouterSteps({
    mode: 'preview',
    from,
    to,
    amount: fromAmount,
  }).then((res) => res[0].poolIdx)

  const q6464price = (await client.readContract({
    address: contractAddressList.CrocQuery,
    abi: CrocQueryAbi,
    functionName: 'queryPrice',
    args: [from.contract_address, to.contract_address, poolIdx],
  })) as bigint

  const sq = Number.parseInt(q6464price.toString()) / 2 ** 64
  const price = sq * sq
  const actuallyPrice = (price / 10 ** from.decimals) * 10 ** to.decimals
  return {
    q6464price,
    actuallyPrice,
    poolIdx,
  }
}

export async function simulateAddLiquidity({
  from,
  to,
  fromAmount,
  result,
  signer,
  slippage,
}: {
  from: Token
  to: Token
  fromAmount: number
  result: QueryPrice
  signer: PrivateKeyAccount
  slippage: number
}) {
  const formatFromAmount = parseUnits(fromAmount.toString(), from.decimals)
  const code = 31
  const base = from.contract_address as `0x${string}`
  const quote = to.contract_address as `0x${string}`
  const _poolIdx = BigInt(result.poolIdx)
  const bidTick = 0
  const askTick = 0
  const settleFlags = 0
  const _slippage = BigInt(slippage)
  const limitLower = (result.q6464price * (100n - _slippage)) / 100n
  const limitUpper = (result.q6464price * (100n + _slippage)) / 100n

  const params = encodeAbiParameters(
    [
      { type: 'uint8', name: 'code' },
      { type: 'address', name: 'base' },
      { type: 'address', name: 'quote' },
      { type: 'uint256', name: 'poolIdx' },
      { type: 'int24', name: 'bidTick' },
      { type: 'int24', name: 'askTick' },
      { type: 'uint128', name: 'quantity' },
      { type: 'uint128', name: 'limitLower' },
      { type: 'uint128', name: 'limitUpper' },
      { type: 'uint8', name: 'settleFlags' },
      { type: 'address', name: 'lpConduit' },
    ],
    [
      code,
      base,
      quote,
      _poolIdx,
      bidTick,
      askTick,
      formatFromAmount,
      limitLower,
      limitUpper,
      settleFlags,
      LPCONDUIT,
    ],
  )

  const { request } = await client.simulateContract({
    address: contractAddressList.CrocSwapDex,
    abi: CrocSwapDex,
    functionName: 'userCmd',
    args: [128, params],
    account: signer,
  })
  return request
}

export function getSwapInfo(data: `0x${string}`) {
  const decodeAbi = decodeFunctionData({
    abi: MultiswapAbi,
    data,
  })
  const args = decodeAbi.args
  const step = args?.[0] as Step[]

  const _amount = args?.[1]?.toString() as string
  const _minOut = args?.[2]?.toString() as string

  const fromTokenAddress = step[0].isBuy ? step[0].base : step[0].quote
  const toTokenAddress = step[0].isBuy ? step[0].quote : step[0].base

  return {
    amount: _amount,
    minOut: _minOut,
    fromTokenAddress,
    toTokenAddress,
  }
}

export function getAddLiquidityInfo(data: `0x${string}`) {
  try {
    const decodeAbi = decodeFunctionData({
      abi: CrocSwapDex,
      data,
    })

    const params = decodeAbiParameters(
      [
        { type: 'uint8', name: 'code' },
        { type: 'address', name: 'base' },
        { type: 'address', name: 'quote' },
        { type: 'uint256', name: 'poolIdx' },
        { type: 'int24', name: 'bidTick' },
        { type: 'int24', name: 'askTick' },
        { type: 'uint128', name: 'quantity' },
        { type: 'uint128', name: 'limitLower' },
        { type: 'uint128', name: 'limitUpper' },
        { type: 'uint8', name: 'settleFlags' },
        { type: 'address', name: 'lpConduit' },
      ],
      decodeAbi?.args?.[1] as `0x${string}`,
    )
    const fromTokenAddress = params[1]
    const toTokenAddress = params[2]

    const amount = params[6].toString()

    return {
      amount,
      fromTokenAddress,
      toTokenAddress,
    }
  } catch (error) {
    console.log({ error })
    return null
  }
}

export async function getTransactionData(txHash: `0x${string}`) {
  const tx = await client.getTransaction({
    hash: txHash,
  })
  const block = await client.getBlock({
    blockNumber: tx.blockNumber,
  })
  const ts = Number(block.timestamp)

  if (tx.input.startsWith(METHOD_ID.MULTI_SWAP)) {
    const swapInfo = getSwapInfo(tx.input)
    transactionStore.send({
      type: 'addSwapTransaction',
      txData: {
        ...swapInfo,
        hash: txHash,
        ts,
      },
    })
  } else if (tx.input.startsWith(METHOD_ID.USER_CMD)) {
    const addLiquidityInfo = getAddLiquidityInfo(tx.input)
    if (addLiquidityInfo) {
      transactionStore.send({
        type: 'addAddLiquidityTransaction',
        txData: {
          ...addLiquidityInfo,
          hash: txHash,
          ts,
        },
      })
    }
  }

  return tx
}
