import type { Token, TransactionHistory } from '@/libs/types'
import { createStore } from '@xstate/store'

const transactionDetailStore = createStore(
  {
    selectedToken: {} as Token,
    transactionData: {} as TransactionHistory,
  },
  {
    setTransactionData: (_, { txData }: { txData: TransactionHistory }) => ({
      transactionData: txData,
    }),
    setSelectedToken: (_, { token }: { token: Token }) => ({
      selectedToken: token,
    }),
  },
)

export { transactionDetailStore }
