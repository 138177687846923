import EyeSvg from '@/assets/svg/eye.svg'
import ReceiveSvg from '@/assets/svg/ic-receive.svg'
import SendSvg from '@/assets/svg/ic-send.svg'
import SwapSvg from '@/assets/svg/swap.svg'
import { WalletButton } from '@/components/Button/WalletButton'
import { usdFormatter } from '@/libs/utils/number'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import {} from '@tabler/icons-react'
import { Title } from '@telegram-apps/telegram-ui'
import { useState } from 'react'
import { Button, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { useWalletBalance } from 'store/balanceStore'

const WalletFeature: React.FC = () => {
  const navigate = useNavigate()
  const balance = useWalletBalance()
  const swapIcon = <img src={SwapSvg} alt="swap" className="w-6 h-6" />
  const [showBalance, setShowBalance] = useState(true)

  return (
    <div
      id="wallet-feature"
      className="flex flex-col justify-center pt-2 bg-blue p-4 rounded-es-3xl rounded-ee-3xl"
    >
      <Text className="text-white">Total balance</Text>
      <div className="flex justify-between items-center">
        <Title level="2" plain weight="2" className="text-4xl mb-3">
          {showBalance ? usdFormatter(balance) : '*****'}
        </Title>
        <Button onPress={() => setShowBalance((pre) => !pre)}>
          <img src={EyeSvg} alt="eye" className="w-5 h-5" />
        </Button>
      </div>

      <div className="w-full flex flex-1 justify-evenly gap-2">
        <WalletButton
          color="blue"
          className="w-2/5 flex-none text-rgba"
          leftIcon={<img src={SendSvg} alt="send" className="w-6 h-6" />}
          onPress={() => {
            handleReactGASendEvent('SendButtonClick')
            navigate('/send-token-list')
          }}
        >
          Send
        </WalletButton>
        <WalletButton
          color="blue"
          className="w-2/5 flex-none text-rgba"
          leftIcon={<img src={ReceiveSvg} alt="receive" className="w-6 h-6" />}
          onPress={() => {
            navigate('/receive')
            handleReactGASendEvent('ReceiveButtonClick')
          }}
        >
          Receive
        </WalletButton>

        <WalletButton
          color="blue"
          className="opacity-32 flex-none text-rgba"
          leftIcon={swapIcon}
        />
      </div>
    </div>
  )
}

export { WalletFeature }
