import BeraBondsAbi from '@/libs/evm/abi/BeraBonds.json'
import { type Nft, type RoundInfo, TokenType } from '@/libs/types'
import axios from 'axios'
import {
  http,
  type PrivateKeyAccount,
  createPublicClient,
  formatEther,
  parseEther,
} from 'viem'
import { berachainTestnetbArtio, mainnet } from 'viem/chains'
import { BERA_BOND_IMAGE_URL } from '../constans'
import { contractAddressList } from '../contractAddress'

const publicClient = createPublicClient({
  chain:
    import.meta.env.VITE_NODE_ENV === 'production'
      ? mainnet
      : berachainTestnetbArtio,
  transport: http(),
})

export async function getBeraBondsNftByWalletAddress(
  walletAddress: `0x${string}`,
) {
  const res = await axios.get(
    `https://api.routescan.io/v2/network/testnet/evm/80084/etherscan/api?module=account&action=addresstokennftinventory&address=${walletAddress}&contractaddress=${contractAddressList.BeraBonds}&page=1&offset=100`,
  )
  // format data
  const data: Nft[] = res.data.result.map(
    (item: {
      TokenId: number
    }) => ({
      token_id: Number(item.TokenId),
      balance: 1,
      name: `BeraBonds #${item.TokenId}`,
      contract_address: contractAddressList.BeraBonds,
      image_url: BERA_BOND_IMAGE_URL,
      token_type: TokenType.ERC721,
      symbol: 'BERABONDS',
      is_staked: false,
    }),
  )
  return data
}

export async function checkIsWhitelist(
  walletAddress: `0x${string}`,
  roundIdx: number,
) {
  const result = await publicClient.readContract({
    address: contractAddressList.BeraBonds,
    abi: BeraBondsAbi,
    functionName: 'isWhitelist',
    args: [roundIdx, walletAddress],
  })
  // if result undefined, return false
  return result ?? false
}

export async function checkIsPublicMint() {
  const result = await publicClient.readContract({
    address: contractAddressList.BeraBonds,
    abi: BeraBondsAbi,
    functionName: 'isPublicMint',
  })
  return result
}

export async function mintNft({
  account,
  beraAmount,
  roundIdx,
}: {
  account: `0x${string}` | PrivateKeyAccount
  beraAmount: number
  roundIdx: number
}) {
  console.log({ beraAmount }, { roundIdx })
  const { request } = await publicClient.simulateContract({
    address: contractAddressList.BeraBonds,
    abi: BeraBondsAbi,
    functionName: 'mint',
    value: parseEther(beraAmount.toString()),
    args: [roundIdx],
    account,
  })
  return request
}

export async function getRoundData({
  roundIdx,
}: {
  roundIdx: number
}) {
  const result = (await publicClient.readContract({
    address: contractAddressList.BeraBonds,
    abi: BeraBondsAbi,
    functionName: 'roundDataList',
    args: [roundIdx],
  })) as [bigint, bigint, bigint, bigint, bigint, boolean]

  const formatData: RoundInfo = {
    supply: Number(result[0]),
    totalMinted: Number(result[1]),
    mintFee: Number(formatEther(result[2])),
    startAt: Number(result[3]),
    endAt: Number(result[4]),
    isPublic: result[5],
  }

  return formatData
}

export async function checkMintedNftBeraBond({
  walletAddress,
}: {
  walletAddress: string
}) {
  const result = (await publicClient.readContract({
    address: contractAddressList.BeraBonds,
    abi: BeraBondsAbi,
    functionName: 'mintedAccounts',
    args: [walletAddress],
  })) as boolean

  return result
}
