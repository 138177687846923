import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { Nft } from '../types'

export const getNfts = async () => {
  const res = await request.get<Nft[]>('/wallet/nft')
  const formatData = res.data.map((item) => ({
    ...item,
    token_id: item.erc1155_id,
  }))
  return formatData
}

export const useNfts = () => {
  const { data } = useQuery({
    queryKey: ['get-nfts'],
    queryFn: () => getNfts(),
  })

  return data ?? []
}
