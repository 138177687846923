import type { Token, TransactionInfo } from '@/libs/types'
import { createStore } from '@xstate/store'

const sendTokenStore = createStore(
  {
    selectedToken: {} as Token,
    transactionData: {} as TransactionInfo,
  },
  {
    setTransactionData: (_, { txData }: { txData: TransactionInfo }) => ({
      transactionData: txData,
    }),
    setSelectedToken: (_, { token }: { token: Token }) => ({
      selectedToken: token,
    }),
  },
)

export { sendTokenStore }
