import { useQuery } from '@tanstack/react-query'
import { sortBy } from 'es-toolkit'
import type { CandlestickData } from 'lightweight-charts'
import request from '../config/request'

const REFETCH_INTERVAL = 15 * 1000

async function getChartData({
  tokenAddress,
  interval,
}: { tokenAddress: string; interval: string }) {
  const res = await request.get<CandlestickData[]>(
    `/price/candle/${tokenAddress}`,
    {
      params: {
        interval,
      },
    },
  )
  const sortedData = sortBy(res.data, ['time'])
  console.log({ sortedData })
  return sortedData
}

export const useChartData = ({
  tokenAddress,
  interval,
}: { tokenAddress: string; interval: string }) => {
  const { data, ...res } = useQuery({
    queryKey: ['chart-data-token', tokenAddress, interval],
    queryFn: () => getChartData({ tokenAddress, interval }),
    refetchInterval: REFETCH_INTERVAL,
  })
  return { data: data ?? [], ...res }
}
