import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { BeraBondSnapshot } from '../types'

export const getBeraBondSnapshot = async (nftId: number) => {
  const res = await request.get<BeraBondSnapshot>(
    `/bera-bonds/snapshot/${nftId}`,
  )

  return res.data
}

export const useBeraBondSnapshot = (nftId: number) => {
  const { data } = useQuery({
    queryKey: ['get-bera-bond-snapshot', nftId],
    queryFn: () => getBeraBondSnapshot(nftId),
  })

  return data ?? null
}
