import Template from '@/assets/Images/wallet-default.webp'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import type { ListAccount } from '@/libs/types'
import { maskLastThreeChars } from '@/libs/utils/formatStr'

interface Props {
  index: number
  image: string
  name: string
  value: string
  element: ListAccount
}

export function CardElement(props: Props) {
  return (
    <div className="rounded-2xl w-full flex items-center justify-between h-[64px] pt-[10px] pb-[10px] pl-[15px] pr-[15px] border border-solid border-[#3892CF] bg-[#52A7E73D]">
      <div className="flex items-center justify-center gap-2.5">
        <p className="text-sm font-bold text-[#52A7E7] w-[30px]">
          {props.index}
        </p>
        <img
          src={props.image ? props.image : Template}
          height="auto"
          width="50px"
          alt="avatar"
        />
        <p className="text-sm font-semibold  text-[#FFFFFF] truncate w-[140px]">
          {maskLastThreeChars(
            props.name
              ? props.name
              : `${props.element.last_name} ${props.element.first_name}`,
          )}
        </p>
      </div>
      <div className="flex items-center gap-2.5 justify-center">
        <img src={BeraCoinImg} width="16px" height="16px" alt="ball -icon" />
        <p className="text-xs font-semibold  text-[#FFDE5E] truncate w-[70px]">
          {props.value}
        </p>
      </div>
    </div>
  )
}
