import Back from '@/assets/svg/back-icon.svg'
import GraduatedToken from '@/assets/svg/stack-total-graduated.svg'
import TotalToken from '@/assets/svg/stack-total-token.svg'
import SwitchIcon from '@/assets/svg/switch.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button'
import { AccountHeader, Container } from '@/components/Layout'
import {
  useBeraBondSnapshot,
  useCheckApprovedNft,
  useMutationStakeNft,
} from '@/libs/hooks'
import { BERA_LOGO } from '@/libs/utils/constans'
import { publicClient } from '@/libs/utils/wallet/meme'
import { approveSendNft, stakeNft } from '@/libs/utils/wallet/stake'
import { stakeStore } from '@/store/StakeStore'
import { transactionStore } from '@/store/TransactionStore'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useMemo, useState } from 'react'
import { toast } from 'sonner'
import {} from 'viem'
import { privateKeyToAccount } from 'viem/accounts'
import { useWriteContract } from 'wagmi'

const CreateStake = () => {
  const queryClient = useQueryClient()
  const { selectedNft } = useSelector(stakeStore, (state) => state.context)
  const { mutateAsync: getNftInfo } = useMutationStakeNft()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)
  const { writeContractAsync } = useWriteContract()
  const { isApproved } = useCheckApprovedNft(signer.address)
  const [isLoading, setIsLoading] = useState(false)
  const beraBondSnapShot = useBeraBondSnapshot(selectedNft?.token_id as number)
  const [clickedApprove, setClickedApprove] = useState(false)

  const infoNft = useMemo(() => {
    return [
      {
        icon: BeraCoinImg,
        name: 'Total $BECA',
        value: Number(beraBondSnapShot?.previewChangedSnapshot.totalBeca),
      },
      {
        icon: BERA_LOGO,
        name: 'Total $BERA Volume',
        value: Number(beraBondSnapShot?.previewChangedSnapshot.totalBeraVolume),
      },
      {
        icon: SwitchIcon,
        name: 'Total Transactions',
        value: Number(
          beraBondSnapShot?.previewChangedSnapshot.totalTransactions,
        ),
      },
      {
        icon: TotalToken,
        name: 'Total Tokens Created',
        value: Number(
          beraBondSnapShot?.previewChangedSnapshot.totalTokenCreated,
        ),
      },
      {
        icon: GraduatedToken,
        name: 'Total Graduated Tokens',
        value: Number(
          beraBondSnapShot?.previewChangedSnapshot.totalCompletedToken,
        ),
      },
    ]
  }, [beraBondSnapShot])

  const approve = async () => {
    setIsLoading(true)
    setClickedApprove(true)
    try {
      const request = await approveSendNft({
        signer,
      })

      const txHash = await writeContractAsync({
        ...request,
        account: signer,
      })
      const receipt = await publicClient.waitForTransactionReceipt({
        hash: txHash as `0x${string}`,
        confirmations: 1,
      })
      if (receipt.status === 'success') {
        toast.success('Approve Successfully!')
        queryClient.invalidateQueries({
          queryKey: ['check-approved-nft', signer.address],
        })
      }
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      if (
        error?.metaMessages[0]?.includes('account does not have enough funds')
      ) {
        toast.error('Insufficient BERA balance')
      } else {
        toast.error('Approve Failed!')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const stake = async () => {
    setIsLoading(true)
    try {
      const result = await getNftInfo({
        tokenId: Number(selectedNft?.token_id),
      })
      if (result) {
        const request = await stakeNft({
          userSnapshot: result.userSnapshot,
          signature: result.signature,
          signer,
        })
        const txHash = await writeContractAsync({
          ...request,
          account: signer,
        })
        const receipt = await publicClient.waitForTransactionReceipt({
          hash: txHash as `0x${string}`,
          confirmations: 1,
        })
        if (receipt.status === 'success') {
          toast.success('Stake Successfully!')
          transactionStore.send({
            type: 'addStakeTransaction',
            txData: {
              hash: txHash as `0x${string}`,
              walletAddress: signer.address,
              tokenId: Number(selectedNft?.token_id),
            },
          })
          queryClient.refetchQueries({
            queryKey: ['stake-nfts'],
          })

          stakeStore.send({
            type: 'setStakeStep',
            step: 'congratulation',
          })
        }
      }
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      if (
        error?.metaMessages[0]?.includes('account does not have enough funds')
      ) {
        toast.error('Insufficient BERA balance')
      } else {
        toast.error('Stake Failed!')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleStake = async () => {
    if (!isApproved) {
      await approve()
    } else {
      await stake()
    }
  }

  const generateButtonTitle = () => {
    if (isLoading && !isApproved) {
      return 'Approving...'
    }
    if (isLoading && isApproved) {
      return 'Staking...'
    }
    if (!isLoading && !isApproved) {
      return 'Approve'
    }
    if (clickedApprove && isApproved) {
      return 'Confirm Staking'
    }
    return 'Stake Now'
  }

  return (
    <Container
      className="px-0  w-full min-h-[100vh] flex flex-col"
      center={<AccountHeader />}
    >
      <div className="w-full flex  flex-col items-center justify-between  px-[20px] pb-0">
        <div className="w-full flex flex-col gap-[20px] items-center justify-center max-h-[calc(100vh-100px)] overflow-auto">
          <div
            className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px] "
            onClick={() =>
              stakeStore.send({
                type: 'setStakeStep',
                step: 'list',
              })
            }
            onKeyDown={() => {}}
          >
            <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
            <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
              Back
            </p>
          </div>
          <div className="flex flex-col w-full gap-[10px]">
            <p className="text-[24px] text-[#ffffff] font-bold text-center">
              BeraBonds
            </p>
            <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
              The Only 3,333 Proof of BearCage Contributors
            </p>
          </div>
          <div className="w-full flex items-center justify-center">
            <div className="rounded-[18px] p-[1.5px] w-[50%] bg-[#52A7E73D] relative">
              <img
                src={selectedNft?.image_url}
                alt={`NFT ${selectedNft?.name}`}
                className="w-full h-[auto] rounded-[18px] border-[1.5px] border-solid border-[#3892CF]"
              />
              <div className="p-[10px] text-left">
                <p className="text-[#FFFFFF] text-[14px] font-[700]">
                  {selectedNft?.name}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#52A7E73D] border-[1.5px] border-solid border-[#3892CF] rounded-[16px] w-full py-[12px] px-[16px] flex flex-col">
            {infoNft.map((element, index: number) => {
              return (
                <div
                  key={element.name}
                  className={`${index === infoNft.length - 1 ? 'border-b-[0px]' : 'border-b-[1px]'} border-solid border-[#52A7E733] min-h-[36px] flex items-center justify-between w-full mb-[12px]`}
                >
                  <div className="flex gap-[10px]">
                    <img
                      src={element.icon}
                      alt={element.name}
                      className="w-[24px] h-[24px]"
                    />
                    <p className="text-[14px] text-[#ffffff] font-[400]">
                      {element.name}
                    </p>
                  </div>
                  <p className="text-[14px] text-[#ffffff] font-[600]">
                    {element?.value?.toLocaleString()}
                  </p>
                </div>
              )
            })}
          </div>
          <div className="w-[100%] h-[48px] mb-3">
            <WalletButton
              onPress={handleStake}
              isDisabled={isLoading}
              className="w-[98%] h-[48px] disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                {generateButtonTitle()}
              </p>
            </WalletButton>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { CreateStake }
