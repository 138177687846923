import { Container, Content } from '@/components/Layout'
import { TokenCardSkeleton } from '@/components/Skeleton'
import type { Token } from '@/libs/types'
import { getTokens } from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Input, Text, TextField } from 'react-aria-components'
import { TokenSend } from './components/TokenSend'

const ListToken: React.FC = () => {
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const { data, isLoading } = useQuery({
    queryKey: ['tokens-send', activeAccount?.address],
    queryFn: () => getTokens(),
    refetchOnWindowFocus: true,
  })

  const [tokens, setTokens] = useState<Token[]>([])

  useEffect(() => {
    if (data) {
      setTokens(data)
    }
  }, [data])

  const handleSearchToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    const filteredTokens = data?.filter((token) =>
      token.symbol.toLowerCase().includes(searchValue.toLowerCase()),
    )
    setTokens(filteredTokens ?? [])
  }

  return (
    <Container className="w-full p-0">
      <Content className="flex flex-1 flex-col justify-between ">
        <div className="flex flex-col px-4 gap-4">
          <Text className="text-xl font-semibold text-white">Select token</Text>
          <TextField>
            <Input
              onChange={handleSearchToken}
              className="w-full bg-transparent border-[1px] border-[#3892CF] focus:border-yellow px-4 py-3 rounded-xl text-white placeholder-white placeholder-opacity-64"
              placeholder="Search token"
            />
          </TextField>
          <div className="overflow-auto flex flex-col gap-3 max-h-[calc(100vh-200px)]">
            {isLoading ? (
              <TokenCardSkeleton />
            ) : tokens.length > 0 ? (
              tokens?.map((token) => (
                <TokenSend key={token.contract_address} token={token} />
              ))
            ) : (
              <Text className="text-[#A9A9A9] font-semibold text-center">
                No record
              </Text>
            )}
          </div>
        </div>
      </Content>
    </Container>
  )
}

export { ListToken }
