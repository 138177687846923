import ClaimGif from '@/assets/gifs/claim.gif'
import BlueTick from '@/assets/svg/blue-tick.svg'
import CancelIcon from '@/assets/svg/cancel-icon.svg'
import { WalletButton } from '@/components/Button'
import { Countdown } from '@/components/Countdown/Countdown'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import { useGetRoundInfo } from '@/libs/hooks'
import { useCheckWhiteList } from '@/libs/hooks/useCheckWhiteList'
import { HomeTab, Tabs } from '@/libs/types'
import { publicClient } from '@/libs/utils/wallet/meme'
import { mintNft } from '@/libs/utils/wallet/nft'
import { bottomTabStore } from '@/store/BottomTabStore'
import { transactionStore } from '@/store/TransactionStore'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { privateKeyToAccount } from 'viem/accounts'
import { useAccount, useWriteContract } from 'wagmi'

type Data = {
  image: string
  name: string
  startTime: number
  endTime: number
  price: number
}

const ElementMint = ({
  data,
  isMinted,
  roundIdx,
}: {
  data: Data
  isMinted: boolean
  roundIdx: number
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { address, isConnected: isConnectedWithWC } = useAccount()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)
  const { isWhitelist } = useCheckWhiteList({
    roundIdx,
    walletAddress: address ?? (activeAccount?.address as `0x${string}`),
  })
  const { roundInfo } = useGetRoundInfo(roundIdx)
  const [loading, setLoading] = useState<boolean>(false)

  const [currentTime, setCurrentTime] = useState(
    Math.floor(new Date().getTime() / 1000),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(new Date().getTime() / 1000))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const [isMintedNft, setIsMintedNft] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const { writeContractAsync } = useWriteContract()

  useEffect(() => {
    setIsMintedNft(isMinted)
  }, [isMinted])

  const roundStatus = useMemo(() => {
    if (currentTime < data.startTime) {
      return {
        status: 'upcoming',
        roundIdx,
      }
    }
    if (currentTime >= data.startTime && currentTime <= data.endTime) {
      return {
        status: 'active',
        roundIdx,
      }
    }
    return {
      status: 'ended',
      roundIdx,
    }
  }, [currentTime, data.startTime, data.endTime, roundIdx])

  const percent = useMemo(() => {
    return (Number(roundInfo?.totalMinted) / Number(roundInfo?.supply)) * 100 >
      100
      ? 100
      : Math.ceil(
          (Number(roundInfo?.totalMinted) / Number(roundInfo?.supply)) * 100,
        )
  }, [roundInfo])

  const canMint = useMemo(() => {
    // Public round (round 3) - anyone can mint
    if (roundIdx === 2) return true

    // Private rounds (round 1 & 2) - need whitelist
    return isWhitelist
  }, [roundIdx, isWhitelist])

  const getMintStatus = useCallback(() => {
    if (isMintedNft) return 'Minted'
    if (loading) return 'Minting...'
    return 'Mint'
  }, [isMintedNft, loading])

  const getEligibilityMessage = () => {
    // Public round - no message
    if (roundIdx === 2) return null

    // Round 1 message
    if (roundIdx === 0) {
      if (isWhitelist) {
        return {
          icon: BlueTick,
          message: "You're eligible for WL (GTD) round",
        }
      }
      return {
        icon: CancelIcon,
        message: "You're not eligible for WL (GTD) round",
      }
    }

    // Round 2 message
    if (isWhitelist) {
      return {
        icon: BlueTick,
        message: isMintedNft
          ? 'You have minted'
          : "You're eligible for WL (FCFS) round",
      }
    }

    return {
      icon: CancelIcon,
      message: "You're not eligible for WL (FCFS) round",
    }
  }

  const mint = async () => {
    setLoading(true)
    try {
      const request = await mintNft({
        account: address ?? signer,
        beraAmount: data.price,
        roundIdx,
      })
      const txHash = await writeContractAsync({
        ...request,
        account: address ?? signer,
      })
      console.log({ txHash })
      const receipt = await publicClient.waitForTransactionReceipt({
        hash: txHash as `0x${string}`,
        confirmations: 1,
      })
      console.log({ receipt })

      if (receipt) {
        setIsMintedNft(true)
        setOpen(true)
        toast.success('Transaction successfully!')
        transactionStore.send({
          type: 'addMintNFTBeraBondTransaction',
          txData: {
            hash: txHash,
            walletAddress: activeAccount?.address as `0x${string}`,
          },
        })
        queryClient.invalidateQueries({
          queryKey: ['bera-bonds-nfts'],
        })
        queryClient.invalidateQueries({
          queryKey: ['get-round-info', roundIdx.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ['check-minted-nft-bera-bonds'],
        })
      }
    } catch (error) {
      console.log({ error })
      toast.error('Transaction failed, please try again', {
        duration: 3000,
      })
    } finally {
      setLoading(false)
    }
  }

  const redirectToHome = () => {
    setOpen(false)
    bottomTabStore.send({
      type: 'setHomeTab',
      tab: HomeTab.NFT,
    })
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Wallet,
    })
    navigate({
      pathname: '/',
    })
  }

  const getStatusColor = () => {
    switch (roundStatus.status) {
      case 'upcoming':
        return '#FFAB00'
      case 'active':
        return '#5DD3B9'
      case 'ended':
        return '#FF7152'
      default:
        return '#FFAB00'
    }
  }

  const getStatusText = () => {
    switch (roundStatus.status) {
      case 'upcoming':
        return 'Upcoming'
      case 'active':
        return 'Live'
      case 'ended':
        return 'End'
      default:
        return 'Upcoming'
    }
  }

  const confirmMint = async () => {
    setOpenConfirm(false)
    await mint()
  }

  return (
    <div className="bg-[#52A7E73D] border-[1.5px] border-solid border-[#3892CF] rounded-[16px] p-[12px] w-full flex flex-col items-start justify-between gap-[10px]">
      <div className="flex items-start justify-between gap-[10px] w-full">
        <img
          src={data.image}
          className="w-[40px] h-[40px] rounded-[16px]"
          alt="icon"
        />
        <div className="flex flex-col gap-[10px] items-start w-[calc(100%-100px)]">
          <div className="flex gap-[5px] justify-between w-full">
            <p className="text-[16px] text-[#ffffff] font-bold flex items-center justify-center">
              {data.name}
            </p>
            <div className="bg-[#FFE9D533] px-[8px] rounded-[8px] flex items-center justify-center h-[24px]">
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: getStatusColor(),
                }}
              >
                {getStatusText()}
              </p>
            </div>
          </div>

          {roundStatus.status !== 'upcoming' && (
            <>
              <div className="relative w-full bg-[#FFFFFF1A] h-[6px] rounded-[17px] overflow-hidden">
                <div
                  className="h-full rounded-[17px] transition-all duration-300"
                  style={{
                    width: `${percent}%`,
                    backgroundColor: '#5DD3B9',
                  }}
                />
              </div>
              <p className="text-[12px] text-[#ffffffa0] font-[400]">
                {`${roundInfo?.totalMinted}/${roundInfo?.supply}(${percent}%)`}
              </p>
            </>
          )}

          {roundStatus.status === 'upcoming' && (
            <div className="flex items-center justify-center gap-[5px]">
              <p className="text-[12px] text-[#ffffff] font-[600]">
                Starts In{' '}
              </p>
              <Countdown
                targetDate={data.startTime}
                key={`countdown-${roundIdx}`}
                onComplete={() => {
                  setCurrentTime(Math.floor(new Date().getTime() / 1000))
                }}
              />
            </div>
          )}

          {roundStatus.status === 'active' && canMint && !isMinted && (
            <WalletButton
              onPress={() => setOpenConfirm(true)}
              className="w-[68px] h-[32px]"
              isDisabled={isMintedNft || loading}
            >
              {getMintStatus()}
            </WalletButton>
          )}
        </div>
        <div className="flex flex-col gap-[2px] items-end w-[50px] justify-end">
          <p className="text-[12px] text-[#ffffff] font-[600] text-right break-keep">
            {data.price} BERA
          </p>
          <p className="text-[12px] text-[#ffffffa0] font-[400]">Price</p>
        </div>
      </div>
      {roundStatus.status === 'active' && isConnectedWithWC && loading && (
        <p className="text-[12px] text-[#ffffffa0] font-[400]">
          Go to your selected wallet app to confirm!
        </p>
      )}
      {!isMinted && getEligibilityMessage() && (
        <div className="pl-[50px]">
          <div className="flex items-start justify-start gap-[10px]">
            <img
              className="w-[18px] h-[18px]"
              src={getEligibilityMessage()?.icon}
              alt="icon"
            />
            <p className="text-[12px] text-[#FFFFFFA0] font-[400]">
              {getEligibilityMessage()?.message}
            </p>
          </div>
        </div>
      )}
      <BottomSheetModal
        open={openConfirm}
        title="Confirmation"
        onClose={() => setOpenConfirm(false)}
        heightPercent={0.62}
      >
        <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
          <div className="flex flex-col px-4 py-5 gap-4 w-full">
            <div className="w-full flex flex-col items-center justify-center gap-[20px]">
              <p className="text-[20px] text-[#ffffff] font-bold">
                {data.name}
              </p>
              <div className="flex flex-col items-center justify-center bg-[#52A7E73D] border-[1.5px] border-solid border-[#3892CF] py-[12px] px-[16px] rounded-[16px] w-full">
                <div className="flex items-center justify-between border-b-[1px] border-solid border-b-[#52A7E733] min-h-[36px] w-full">
                  <p className="text-[14px] text-[#ffffff] font-bold">
                    Mint cost
                  </p>
                  <p className="text-[14px] text-[#ffffff] font-[400]">
                    {data.price} $BERA
                  </p>
                </div>

                <div className="flex items-center justify-between min-h-[36px] w-full">
                  <p className="text-[14px] text-[#ffffff] font-bold">
                    Receive
                  </p>
                  <p className="text-[14px] text-[#ffffff] font-[400]">
                    1 BeraBonds
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-[10px] flex gap-[20px]">
            <WalletButton
              onPress={() => setOpenConfirm(false)}
              color="not-shadow"
              className="w-[calc(50%-20px)] h-[48px]"
            >
              <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                Cancel
              </p>
            </WalletButton>
            <WalletButton
              onPress={() => confirmMint()}
              className="w-[calc(50%-20px)] h-[48px]"
            >
              <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                Mint
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal>
      <BottomSheetModal
        open={open}
        title="Notification"
        onClose={() => setOpen(false)}
        heightPercent={0.65}
      >
        <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
          <div className="flex flex-col px-4 py-5 gap-4 w-full">
            <div className="w-full flex flex-col items-center justify-center">
              <p className="text-[20px] text-[#ffffff] font-bold">
                Congratulations!
              </p>
              <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
                You have minted 1 BeraBonds for this wallet. Each wallet can
                only mint 1 BeraBonds.
              </p>
            </div>
            <div className="w-full flex items-center justify-center">
              <img
                src={ClaimGif}
                alt="claim gif"
                className="h-[160px] w-[160px]"
              />
            </div>
          </div>
          <div className="pb-[10px]">
            <WalletButton
              onPress={redirectToHome}
              className="w-[100%] h-[48px]"
            >
              <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                Check now
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal>
    </div>
  )
}

export { ElementMint }
