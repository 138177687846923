import { useQuery } from '@tanstack/react-query'
import { useInitData } from '@telegram-apps/sdk-react'
import request from '../config/request'

type MyMemeToken = {
  id: number
  address: string
  symbol: string
}

async function getMyMemeReachThreshold(): Promise<MyMemeToken[]> {
  const res = await request.get<MyMemeToken[]>('meme-reach-threshold')
  return res.data
}

export const useMyMemeReachThreshold = () => {
  const initData = useInitData()

  const startParam = initData?.startParam

  const { data, ...res } = useQuery({
    queryKey: ['my-meme-reach-threshold', startParam],
    queryFn: () => getMyMemeReachThreshold(),
    refetchOnWindowFocus: true,
  })

  return { tokens: data ?? [], ...res }
}
