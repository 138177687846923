import { useQuery } from '@tanstack/react-query'
import type { Token } from '../types'
import { isNativeToken } from '../utils/wallet/action'
import { getAllowance } from '../utils/wallet/swap'

export function useAllowance(
  walletAddress: string,
  token: Token,
  spender: string,
) {
  const { data, ...res } = useQuery({
    queryKey: ['allowance', token?.contract_address, walletAddress],
    queryFn: () =>
      getAllowance({
        token,
        walletAddress,
        spender,
      }),
    enabled: !!walletAddress && !!token && !isNativeToken(token),
    refetchInterval: !isNativeToken(token) ? 1000 * 3 : false,
  })

  return { allowance: data ?? null, ...res }
}
