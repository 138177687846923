import TransactionSuccess from '@/assets/svg/transaction-success.svg'
import WarningIcon from '@/assets/svg/warning-icon.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import type { TaskInfo } from '@/libs/types/task'
import { generateImageByTask } from '@/libs/utils/task/task'
import { transactionStore } from '@/store/TransactionStore'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useEffect, useState } from 'react'
import { parseEther } from 'viem'
import { privateKeyToAccount } from 'viem/accounts'
import { useSendTransaction, useWaitForTransactionReceipt } from 'wagmi'

export function TaskSend(
  open: boolean,
  setOpen: (value: boolean) => void,
  title: string,
  task: TaskInfo,
  goTask: (id: number) => void,
  loading: boolean,
  claimTask: (id: number) => void,
) {
  // const utils = useUtils()
  const queryClient = useQueryClient()
  const activeAccount = useSelector(
    walletStore,
    (state) => state.context.activeAccount,
  )
  const txsSendBera = useSelector(
    transactionStore,
    (state) => state.context.txsSendBera,
  )
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false)
  const [errorFaucetOpen, setErrorFaucetOpen] = useState<boolean>(false)
  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)

  const handleSendNativeToken = () => {
    sendNativeToken({
      account: signer,
      to: import.meta.env.VITE_BERA_WALLET as `0x${string}`,
      value: parseEther(task.task.minimum_requirement?.toString() as string),
    })
  }

  const {
    data: hashSendNativeToken,
    isPending: isSendingNativeToken,
    sendTransaction: sendNativeToken,
    error: errorSendNativeToken,
  } = useSendTransaction()

  const { data: transactionReceipt, isFetching: isFetchingTxReceipt } =
    useWaitForTransactionReceipt({
      hash: hashSendNativeToken,
      confirmations: 1,
    })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (transactionReceipt) {
      transactionStore.send({
        type: 'addSendBeraTransaction',
        txData: transactionReceipt.transactionHash,
      })
      goTask(task.task.id)
      setOpen(false)
      setSuccessDialogOpen(true)
    }
  }, [transactionReceipt])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (errorSendNativeToken) {
      setOpen(false)
      setErrorFaucetOpen(true)
    }
  }, [errorSendNativeToken])

  const closeDialog = () => {
    setOpen(false)
  }

  const checkTask = async () => {
    if (txsSendBera?.length > 0) {
      await goTask(task.task.id)
      setOpen(false)
      setSuccessDialogOpen(true)
    } else {
      handleSendNativeToken()
    }
  }

  const ClaimTaskSuccess = async () => {
    await claimTask(task.task.id)
    // toast.success(`You have successfully claimed ${task.task.reward_point} $BECA`)
    transactionStore.send({
      type: 'clearSendBeraTransaction',
    })
    queryClient.invalidateQueries({ queryKey: ['task'] })
    setSuccessDialogOpen(false)
  }

  // const actionErrorFaucet = async () => {
  //   if(activeAccount?.address) {
  //     navigator.clipboard.writeText(activeAccount?.address)
  //     toast.success('Copy wallet address successfully')
  //     await new Promise((resolve) => setTimeout(resolve, 1000))
  //     utils.openLink("https://bartio.faucet.berachain.com/")
  //   } else {
  //     toast.error('Copy wallet address false')
  //   }
  // }

  const buttonLoading = () => {
    return (
      <WalletButton className="w-full h-[48px] border-0" color="blue-800">
        <p className="text-[#ffffff80] text-[14px]">Sending $BECA.....</p>
      </WalletButton>
    )
  }

  const InfoModal = () => {
    return (
      <BottomSheetModal
        open={open}
        title={title}
        onClose={() => closeDialog()}
        heightPercent={0.6}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-center pt-[30px]">
          <img
            src={
              task?.task?.image_url
                ? task.task.image_url
                : generateImageByTask(task)
            }
            alt="telegram icon"
            className="w-[64px] h-[64px] rounded-[50%]"
          />
          <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
            {task.task.title}
          </p>
          <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
            {task.task.description}
          </p>
          <div className="flex items-center justify-center gap-[5px] pb-[10px]">
            <img
              src={BeraCoinImg}
              width="24px"
              height="24px"
              alt="token icon"
            />
            <p className="text-[20px] text-[#FFDE5E] leading-[24px] font-[700]">
              {`${task.task.reward_point}`}
            </p>
            <p className="text-[20px] text-[#FFFFFF] leading-[24px] font-[700]">
              $BECA
            </p>
          </div>
          {!loading && !isSendingNativeToken && !isFetchingTxReceipt && (
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => checkTask()}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Join & Finish task
              </p>
            </WalletButton>
          )}
          {(loading || isSendingNativeToken || isFetchingTxReceipt) && (
            <div className="w-full">{buttonLoading()}</div>
          )}
        </div>
      </BottomSheetModal>
    )
  }

  const SuccessDialog = () => {
    return (
      <BottomSheetModal
        open={successDialogOpen}
        title={title}
        onClose={() => setSuccessDialogOpen(false)}
        heightPercent={0.5}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
          <div className="w-full h-full flex flex-col items-center justify-center">
            <img
              src={TransactionSuccess}
              alt="transaction success"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              Mission successful
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
              Tip: Claim BERA every day to get BERA + BECA. The more BERA you
              hold, the better your chances of getting an airdrop.
            </p>
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            {!loading && (
              <WalletButton
                className="w-full h-[48px]"
                onPress={() => ClaimTaskSuccess()}
              >
                <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                  Claim reward
                </p>
              </WalletButton>
            )}
            {loading && <div className="w-full">{buttonLoading()}</div>}
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  const ErrorFaucet = () => {
    return (
      <BottomSheetModal
        open={errorFaucetOpen}
        title={title}
        onClose={() => setErrorFaucetOpen(false)}
        heightPercent={0.6}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-between pt-[30px] h-full">
          <div className="flex flex-col  items-center justify-center">
            <img
              src={WarningIcon}
              alt="transaction error"
              className="w-[64px] h-[64px]"
            />
            <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
              Mission Fail
            </p>
            <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
              You do not have enough BERA to finish this task. Please complete
              the faucet task first.
            </p>
          </div>
          <div className="flex flex-col w-full gap-[10px]">
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => setErrorFaucetOpen(false)}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Close
              </p>
            </WalletButton>
          </div>
        </div>
      </BottomSheetModal>
    )
  }

  return (
    <div>
      {InfoModal()}
      {SuccessDialog()}
      {ErrorFaucet()}
    </div>
  )
}
