import { memo, useCallback, useEffect, useRef } from 'react'

interface CountdownProps {
  targetDate: number
  onComplete?: () => void
}

const CountdownComponent = ({ targetDate, onComplete }: CountdownProps) => {
  const hoursRef = useRef<HTMLDivElement>(null)
  const minutesRef = useRef<HTMLDivElement>(null)
  const secondsRef = useRef<HTMLDivElement>(null)

  const formatNumber = useCallback((num: number): string => {
    return num < 10 ? `0${num}` : num.toString()
  }, [])

  const updateCountdown = useCallback(() => {
    const now = Math.floor(new Date().getTime() / 1000)
    const timeLeft = targetDate - now

    if (timeLeft <= 0) {
      onComplete?.()
      if (hoursRef.current) hoursRef.current.textContent = '00'
      if (minutesRef.current) minutesRef.current.textContent = '00'
      if (secondsRef.current) secondsRef.current.textContent = '00'
      return
    }

    const secondsLeft = Math.floor(timeLeft)
    const minutesLeft = Math.floor(secondsLeft / 60)
    const totalHours = Math.floor(minutesLeft / 60)

    if (hoursRef.current)
      hoursRef.current.textContent = formatNumber(totalHours)
    if (minutesRef.current)
      minutesRef.current.textContent = formatNumber(minutesLeft % 60)
    if (secondsRef.current)
      secondsRef.current.textContent = formatNumber(secondsLeft % 60)
  }, [targetDate, formatNumber, onComplete])

  useEffect(() => {
    // Update immediately when component mounts
    updateCountdown()

    // Then update every second
    const interval = setInterval(updateCountdown, 1000)

    return () => clearInterval(interval)
  }, [updateCountdown])

  return (
    <div className="flex items-center justify-center gap-[5px]">
      <div className="w-[24px] h-[24px] rounded-[3px] bg-[#FAC808] flex items-center justify-center text-[12px] text-[#003F60] font-[600]">
        <div ref={hoursRef}>00</div>
      </div>
      <p className="text-[12px] text-[#ffffff] font-[600]">:</p>
      <div className="w-[24px] h-[24px] rounded-[3px] bg-[#FAC808] flex items-center justify-center text-[12px] text-[#003F60] font-[600]">
        <div ref={minutesRef}>00</div>
      </div>
      <p className="text-[12px] text-[#ffffff] font-[600]">:</p>
      <div className="w-[24px] h-[24px] rounded-[3px] bg-[#FAC808] flex items-center justify-center text-[12px] text-[#003F60] font-[600]">
        <div ref={secondsRef}>00</div>
      </div>
    </div>
  )
}

export const Countdown = memo(CountdownComponent)
