export enum Tabs {
  Wallet = 'wallet',
  Game = 'game',
  Leaderboard = 'leaderboard',
  Tasks = 'tasks',
  Invite = 'invite',
  DApps = 'dapps',
  Meme = 'meme',
  CreateMeme = 'createMeme',
  DetailMeme = 'detailMeme',
}

export enum HomeTab {
  Token = 'TOKEN',
  NFT = 'NFT',
}
