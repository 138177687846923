
import ArrowRight from "@/assets/svg/arrow-right-sale-history.svg"
import Filter from "@/assets/svg/filter.svg"
import RefreshIcon from "@/assets/svg/refresh-icon.svg"
import { useHistorySale } from "@/libs/hooks/useHistorySale"
import type { MemeToken } from '@/libs/types'
import type { SaleHistory as SaleHistoryType } from "@/libs/types/saleHistory"
import { formatWalletAddress } from "@/libs/utils/wallet/action"
import { useUtils } from "@telegram-apps/sdk-react"
import { Typography } from '@telegram-apps/telegram-ui'
import { format } from "date-fns"
import type { } from 'lightweight-charts'
import { useEffect, useState, } from 'react'

type Props = {
  tokenSelected: MemeToken
}

const SaleHistory: React.FC<Props> = ({ tokenSelected }) => {
  const elementShow = 20
  const [activePage, setActivePage] = useState<number>(1)
  const [arrayPage, setArrayPage] = useState<number[]>([])
  const [showElement, setShowElement] = useState<SaleHistoryType[]>([])
  const [filter, setFilter] = useState<string>('')
  const utils = useUtils()

  const { data, pagination, isLoading } = useHistorySale({
    tokenAddress: tokenSelected.address,
    page: activePage,
    pageSize: elementShow,
    filter: filter
  })

  useEffect(() => {
    if(data) {
      setShowElement(data)
    }
    if(pagination) {
      const tempArray = []
      const numberElement = Math.ceil(pagination?.totalCount / elementShow)
      for (let i = 0; i < numberElement ; i++) {
        tempArray.push(i + 1)
      }
      setArrayPage(tempArray)
    }
  }, [data, pagination])

  const refreshData = () => {
    setFilter('')
    setActivePage(1)
  }

  return (
    <div className="py-[12px] px-[16px] rounded-[16px] bg-[#52A7E71F] flex flex-col gap-[20px] justify-start">
      <div className="flex items-center justify-between w-full">
      <Typography className='text-[#ffffff] font-bold text-[24px]'>
        Activities
      </Typography>
      <img 
        src={RefreshIcon} 
        onClick={() => refreshData()}
        onKeyDown={() => {}}
        className='h-[20px] w-[20px] cursor-pointer' 
        alt="arrow right"
      />
      </div>
      <div className='w-full border-[1px] border-[#52A7E71F] border-solid' />
      <div className='w-full'>
        <table className='w-full'>
          <tr className='h-[30px]'>
            <th className='text-[#ffffffa0] font-[700] text-[12px] text-left'>Type</th>
            <th className='text-[#ffffffa0] font-[700] text-[12px]'>BERA</th>
            <th className='text-[#ffffffa0] font-[700] text-[12px]'>Halving</th>
            <th className='text-[#ffffffa0] font-[700] text-[12px]'>Maker</th>
          </tr>
          {isLoading && (
            <tr className='h-[50px] border-b-[1px] border-solid border-b-[#ffffff3b] animate-pulse'>
              <td>
                <div className="h-[30px] bg-gray-200 rounded-[12px] dark:bg-gray-700 w-[auto]" />
              </td>
              <td className='text-[#ffffffa0] font-[400] text-[12px] text-center'>
                <div className="h-[30px] bg-gray-200 rounded-[12px] dark:bg-gray-700 w-[auto]" />
              </td>
              <td className='text-[#ffffffa0] font-[400] text-[12px] text-center'>
                <div className="h-[30px] bg-gray-200 rounded-[12px] dark:bg-gray-700 w-[auto]" />
              </td>
              <td>
                <div className="h-[30px] bg-gray-200 rounded-[12px] dark:bg-gray-700 w-[auto]" />
              </td>
            </tr>
          )}
          {!isLoading && showElement.map((element) => {
            const amountIn = element.amountIn / 1e18
            const amountOut = element.amountOut / 1e18
            return (
              <tr key={element.transactionHash} className='h-[50px] border-b-[1px] border-solid border-b-[#ffffff3b] '>
                <td>
                  <div className='flex flex-col'>
                    <div className='flex gap-[5px] items-center justify-start'>
                      <p className={`${element.type === 'Buy' ? 'text-[#52C41A]' : 'text-[#F5222D]'} text-[12px] font-bold text-center`}>{element.type}</p>
                      <img 
                        src={ArrowRight} 
                        onClick={() => utils.openLink(`https://bartio.beratrail.io/tx/${element.transactionHash}`)}
                        onKeyDown={() => {}}
                        className='h-[16px] w-[16px] cursor-pointer' 
                        alt="arrow right" 
                      />
                    </div>
                    <p className='text-[#ffffffa0] text-[12px] font-bold'>{format(new Date(element.timestamp*1000), 'dd/MM HH:mm',)}</p>
                  </div>
                </td>
                <td className='text-[#ffffffa0] font-[400] text-[12px] text-center'>{element.type === 'Buy' ? amountIn.toLocaleString('en-US') : amountOut.toLocaleString('en-US')}</td>
                <td className='text-[#ffffffa0] font-[400] text-[12px] text-center'>{element.type === 'Buy' ? amountOut.toLocaleString('en-US') : amountIn.toLocaleString('en-US')}</td>
                <td>
                  <div className="flex gap-[5px] items-center justify-center">
                    <p className='text-[#ffffffa0] font-[600] text-[12px]'>{formatWalletAddress(element.account, 4)}</p>
                    <img 
                      src={Filter} 
                      onClick={() => setFilter(element.account)}
                      onKeyDown={() => {}}
                      className='h-[16px] w-[16px] cursor-pointer' 
                      alt="arrow right"
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
        {arrayPage.length > 0 && <Pagination activePage={activePage} arrayPage={arrayPage} setActivePage={setActivePage} />}
      </div>
    </div>
  )
}

const Pagination: React.FC<{activePage: number, arrayPage: number[], setActivePage:(value: number) => void}> = (props) => {
  return (
    <div className="w-full h-[32px] flex gap-[4px] items-center justify-center mt-[15px]">
      <div 
        className={`${props.activePage === 1 ? 'opacity-20' : 'opacity-1'} border-[1px] border-solid border-[#3892CF] rounded-[8px] flex items-center justify-center h-[32px] w-[32px] cursor-pointer`}
        onClick={() => props.activePage !== 1 && props.setActivePage(props.activePage - 1)}
        onKeyDown={() => {}}
      >
        <p className="text-[#ffffff] font-bold text-[14px]">{"<"}</p>
      </div>
      {props.activePage > 2 && <div 
        className="border-[1px] border-solid border-[#3892CF] rounded-[8px] flex items-center justify-center h-[32px] w-[32px] cursor-pointer"
      >
        <p className="text-[#ffffff] font-bold text-[14px]">{"..."}</p>
      </div>}
      {props.arrayPage.map((elementPage: number) => {
        return elementPage > props.activePage - 2 && elementPage < props.activePage + 2 && (
          <div 
            className={`${elementPage === props.activePage ? "border-[#FAC808]" : "border-[#3892CF]"} border-[1px] border-solid rounded-[8px] flex items-center justify-center h-[32px] w-[32px] cursor-pointer`} 
            key={elementPage}
            onClick={() => elementPage !== props.activePage && props.setActivePage(elementPage)}
            onKeyDown={() => {}}
          >
            <p className={`${elementPage === props.activePage ? "text-[#FAC808]" : "text-[#ffffff]"}  font-bold text-[14px]`}>{elementPage}</p>
          </div>
        )
      })}
      {props.activePage < props.arrayPage.length - 1 && <div 
        className="border-[1px] border-solid border-[#3892CF] rounded-[8px] flex items-center justify-center h-[32px] w-[32px] cursor-pointer"
      >
        <p className="text-[#ffffff] font-bold text-[14px]">{"..."}</p>
      </div>}
      <div 
        className={`${props.activePage === props.arrayPage.length ? 'opacity-20' : 'opacity-1'} border-[1px] border-solid border-[#3892CF] rounded-[8px] flex items-center justify-center h-[32px] w-[32px] cursor-pointer`}
        onClick={() => props.activePage !== props.arrayPage.length && props.setActivePage(props.activePage + 1)}
        onKeyDown={() => {}}
      >
        <p className="text-[#ffffff] font-bold text-[14px]">{">"}</p>
      </div>
    </div>
  )
}

export { SaleHistory }
