import { useQuery } from '@tanstack/react-query'
import { checkMintedNftBeraBond } from '../utils/wallet/nft'

export function useCheckMintedNftBeraBond(walletAddress: string) {
  const { data, ...res } = useQuery({
    queryKey: ['check-minted-nft-bera-bonds', walletAddress],
    queryFn: () => checkMintedNftBeraBond({ walletAddress }),
  })

  return { isMinted: data, ...res }
}
