import { useQuery } from '@tanstack/react-query'
import { getBalanceNativeToken } from '../utils/wallet/action'

export function useNativeTokenBalance(walletAddress: string) {
  const { data, ...res } = useQuery({
    queryKey: ['nativeTokenBalance', walletAddress],
    queryFn: () => getBalanceNativeToken(walletAddress),
  })

  return { balance: data ?? 0, ...res }
}
