import { useQuery } from '@tanstack/react-query'
import type { ContractAddress } from '../types'
import { getUsdPrice, getUsdPriceBinance } from '../utils/wallet/action'

export const useTokenPrice = (contractAddress: ContractAddress) => {
  const res = useQuery({
    queryKey: ['tokenPrice', contractAddress],
    queryFn: () => getUsdPrice(contractAddress),
    staleTime: 1000 * 60 * 5,
  })

  return { usdPrice: res.data?.price, price: res.data, ...res }
}

export const useTokenPriceBinance = (symbol: string) => {
  const res = useQuery({
    queryKey: ['tokenPriceBinance', symbol],
    queryFn: () => getUsdPriceBinance(symbol),
    staleTime: 1000 * 60 * 5,
  })

  return { usdPrice: res.data?.lastPrice, price: res.data, ...res }
}
