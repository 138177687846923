import EnergySvg from '@/assets/svg/energy.svg'
import OutlineArrowRightSvg from '@/assets/svg/outline-arrow-right.svg'
import { MAX_FUEL, useRemainingFuel } from '@/store/Game'

export const Fuel = () => {
  const currentFuel = useRemainingFuel()

  const percent = (currentFuel / MAX_FUEL) * 100

  return (
    <div className="w-full flex items-center justify-center gap-2">
      <img src={EnergySvg} className="w-4 h-4" alt="Energy" />
      <div className="w-[100px] h-2 px-[2px bg-[#52A7E733] rounded-md relative">
        <div
          className="h-1 bg-[#FFDE5E] rounded-md absolute top-1/2 left-[2px] -translate-y-1/2"
          style={{ width: `calc(${percent}% - ${percent * 0.04}px)` }}
        />
      </div>
      <p className="text-sm font-bold text-[#FFDE5E]">
        {currentFuel}{' '}
        <span className="text-sm text-[#71BDF1] font-normal">/ {MAX_FUEL}</span>
      </p>
      <img
        src={OutlineArrowRightSvg}
        className="w-4 h-4"
        alt="OutlineArrowRight"
      />
    </div>
  )
}
