import { formatNumber } from '@/libs/utils/number'
import { formatTime } from '@/libs/utils/time'
import { useSelector } from '@xstate/store/react'
import { MAX_FUEL, gameStore } from './GameStore'

export const useChoice = () =>
  useSelector(gameStore, (state) => state.context.choice)

export const useLastChoice = () =>
  useSelector(gameStore, (state) => state.context.lastChoice)

export const useGameStatus = () =>
  useSelector(gameStore, (state) => state.context.status)

export const useTimer = () =>
  useSelector(gameStore, (state) => state.context.timer)

export const useRemainingFuel = () =>
  useSelector(gameStore, (state) => state.context.fuel.remainingFuel)

export const useGetPrice = () =>
  useSelector(gameStore, (state) => formatNumber(state.context.price || 0, 2))

export const usePoint = () =>
  useSelector(gameStore, (state) => state.context.point)

export const useTimeToRefill = () =>
  useSelector(gameStore, (state) =>
    formatTime(state.context.fuel.timeToRefill || 0),
  )

export const useShowRefill = () =>
  useSelector(gameStore, (state) => state.context.fuel.remainingFuel < MAX_FUEL)

export const usePlayGame = () =>
  useSelector(gameStore, (state) => state.context.timer === 0)

export const useResult = () =>
  useSelector(gameStore, (state) => state.context.result)

export const useEnableChoice = () =>
  useSelector(gameStore, (state) => {
    if (!state.context.isOnline) return false

    return (
      state.context.fuel.remainingFuel > 0 ||
      (state.context.fuel.remainingFuel === 0 &&
        state.context.status !== 'IDLE')
    )
  })

export const useIntervalId = () =>
  useSelector(gameStore, (state) => state.context.intervalId)

export const useIsHoldingResult = () =>
  useSelector(gameStore, (state) => state.context.isHolding)

export const useCanChoice = () =>
  useSelector(
    gameStore,
    (state) =>
      !(
        state.context.status !== 'IDLE' ||
        state.context.fuel.remainingFuel === 0
      ),
  )

export const useMarketDirection = () =>
  useSelector(gameStore, (state) => state.context.marketDirection)
