import MotionNumber from 'motion-number'
import React from 'react'
import IconSwitcher from '../IconSwitcher'

interface AnimatedNumberProps {
  value: number
}

const textStyle = {
  fontSize: '32px',
  fontWeight: '700',
  lineHeight: '40px',
  letterSpacing: '-0.03em',
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ value }) => {
  return (
    <div className="flex gap-2">
      <IconSwitcher />

      {value
        .toFixed(4)
        .toString()
        .split('')
        .map((v, i) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <React.Fragment key={i}>
            {v === '.' ? (
              <p style={{ ...textStyle }}>.</p>
            ) : (
              <MotionNumber
                value={Number.parseInt(v)}
                format={{ style: 'decimal' }}
                animate={{
                  position: 'relative',
                  height: '40px',
                  width: '20px',
                  overflow: 'hidden',
                  background: '#12375366',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  ...textStyle,
                }}
                inputMode="text"
              />
            )}
          </React.Fragment>
        ))}

      <p style={{ ...textStyle }}>%</p>
    </div>
  )
}

export { AnimatedNumber }
