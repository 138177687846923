import { useQuery } from '@tanstack/react-query'
import {getBalanceETHMainnet} from '../utils/wallet/action'

export function useGetETHMainnetBalance(
  walletAddress: string,
) {
  const { data, ...res } = useQuery({
    queryKey: ['get-eth-mainnet-balance', walletAddress],
    queryFn: () => getBalanceETHMainnet(walletAddress),
    enabled: !!walletAddress,
  })

  return { balanceETHMainnet: data ?? 0, ...res }
}
