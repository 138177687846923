import { type DefaultOptions, QueryClient } from '@tanstack/react-query'

const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 1,
  },
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig })
