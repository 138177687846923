import { WalletButton } from '@/components/Button/WalletButton'
import { Container, Content } from '@/components/Layout'
import type { WalletAccount } from '@/libs/types'
import { loadWalletFromMnemonics } from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useSelector } from '@xstate/store/react'
import { useEffect, useState } from 'react'
import { Button, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

const shuffleArray = (array: string[] | number[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}
const getRandomIndices = (total: number, count: number) => {
  const indices = Array.from({ length: total }, (_, i) => i)
  shuffleArray(indices)
  return indices.slice(0, count)
}

const ConfirmMnemonic: React.FC = () => {
  const navigate = useNavigate()

  const mnemonic = useSelector(
    walletStore,
    (state) => state.context.mnemonicGenerated,
  )
  const [groupedWords, setGroupedWords] = useState<string[][]>([])
  const [arrCorrectIdx, setArrCorrectIdx] = useState<number[]>([])
  const [selectedWords, setSelectedWords] = useState<string[]>([])

  const generateIndices = () => {
    const indices = getRandomIndices(12, 4)
    //sort indices
    indices.sort((a, b) => a - b)
    setArrCorrectIdx(indices)
    shuffleAndGroupWords(indices)
    setSelectedWords([])
    return indices
  }

  const shuffleAndGroupWords = (indices: number[]) => {
    const words = mnemonic.split(' ')
    const correctWords = indices.map((idx) => words[idx])
    const remainingWords = words.filter((_, idx) => !indices.includes(idx))
    shuffleArray(remainingWords)

    const group1 = [correctWords[0], ...remainingWords.slice(0, 2)]
    const group2 = [correctWords[1], ...remainingWords.slice(2, 4)]
    const group3 = [correctWords[2], ...remainingWords.slice(4, 6)]
    const group4 = [correctWords[3], ...remainingWords.slice(6, 8)]

    shuffleArray(group1)
    shuffleArray(group2)
    shuffleArray(group3)
    shuffleArray(group4)

    setGroupedWords([group1, group2, group3, group4])
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    generateIndices()
  }, [])

  const handleSelectWord = (word: string, groupIdx: number) => {
    // set word to selectedWords by group idx
    const newSelectedWords = [...selectedWords]
    newSelectedWords[groupIdx] = word
    setSelectedWords(newSelectedWords)
  }

  const handleConfirmMnemonic = () => {
    const isCorrect = arrCorrectIdx.every(
      (idx, groupIdx) => selectedWords[groupIdx] === mnemonic.split(' ')[idx],
    )
    if (!isCorrect) {
      toast.error('Invalid recovery phrase')
      generateIndices()
      return
    }
    const { account, privateKey } = loadWalletFromMnemonics(mnemonic)
    const walletAccount: WalletAccount = {
      ...account,
      mnemonic,
      privateKey,
    }
    walletStore.send({
      type: 'addWallet',
      account: walletAccount,
    })
    navigate('/home')
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between">
        <div>
          <Text className="text-white text-xl font-semibold">
            Confirm recovery phrase
          </Text>
          <div className="grid gap-3 mt-4">
            {groupedWords.map((group, groupIdx) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <div key={groupIdx} className="flex flex-1 flex-col">
                <Text className="font-semibold mb-2">
                  Word {`#${arrCorrectIdx[groupIdx] + 1}`}
                </Text>
                <div className="flex justify-between text-left gap-2">
                  {group.map((word, wordIdx) => {
                    const isActive = selectedWords[groupIdx] === word
                    return (
                      <Button
                        className={`border-[1.5px] p-1 rounded-xl flex flex-1 justify-center items-center ${isActive ? 'bg-yellow-300 border-yellow' : 'bg-transparent border-blue-200'}`}
                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                        key={wordIdx}
                        onPress={() => handleSelectWord(word, groupIdx)}
                      >
                        {word}
                      </Button>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        <WalletButton
          className="mb-12 max-h-12"
          onPress={handleConfirmMnemonic}
        >
          Continue
        </WalletButton>
      </Content>
    </Container>
  )
}

export { ConfirmMnemonic }
