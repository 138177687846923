import { HomeTab, Tabs } from '@/libs/types'
import { createStore } from '@xstate/store'

const bottomTabStore = createStore(
  {
    currentTab: Tabs.Wallet,
    homeTab: HomeTab.Token,
  },
  {
    setCurrentTab: (_, { tab }: { tab: Tabs }) => ({
      currentTab: tab,
    }),
    setHomeTab: (_, { tab }: { tab: HomeTab }) => ({
      homeTab: tab,
    }),
  },
)

export { bottomTabStore }
