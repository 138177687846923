import { gameStore } from '@/store/Game'
import { retrieveLaunchParams } from '@telegram-apps/sdk-react'
import { omit } from 'es-toolkit'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { WSMessageResponse, WSPlayGameArgs } from '../types'
import { useProfile } from './useProfile'

const MAX_RECONNECT_ATTEMPTS = 5
const RECONNECT_DELAY = 500 // ms

const useConnectSocket = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const { initDataRaw } = retrieveLaunchParams()
  const { userInfo } = useProfile()

  const intervalGetPriceIds = useRef<NodeJS.Timeout[]>([])
  const reconnectTimeoutId = useRef<NodeJS.Timeout>()
  const reconnectAttempts = useRef(0)
  const isOnline = useRef(navigator.onLine)

  const connectSocket = useCallback(() => {
    if (!initDataRaw && !userInfo) {
      console.log('No initDataRaw available, skipping connection')
      return
    }

    if (!isOnline.current) {
      console.log('No internet connection, skipping connection')
      return
    }

    const apiUrl = import.meta.env.VITE_API_URL as string
    const url = `${apiUrl.replace('https', 'wss')}/ws?authorization=${
      // biome-ignore lint/style/useTemplate: <explanation>
      encodeURIComponent('tma ' + initDataRaw)
    }`

    let ws: WebSocket | null = null
    try {
      ws = new WebSocket(url)
    } catch (error) {
      console.error('Failed to create WebSocket:', error)
      handleReconnectWS()
      return
    }

    ws.onopen = () => {
      console.log('Socket connected')
      reconnectAttempts.current = 0 // Reset reconnect attempts on successful connection
      gameStore.send({ type: 'setOnline', isOnline: true })

      handleRefresh(ws)
      const intervalId = setInterval(() => handleRefresh(ws), 1000)
      intervalGetPriceIds.current.push(intervalId)
    }

    ws.onmessage = (event: MessageEvent<string>) => {
      const data: WSMessageResponse = JSON.parse(event.data)

      switch (data.event) {
        case 'REFRESH':
          gameStore.send({ type: 'handleRefresh', ...data.data })
          break
        case 'PLAY_GAME':
          console.log('Prediction result:', data)
          gameStore.send({
            type: 'setResult',
            result: omit(data.data, ['lastUpdatedFuelAt', 'remainingFuel']),
          })
          break
        case 'CONSUME_FUEL':
          gameStore.send({
            type: 'setFuel',
            fuel: {
              remainingFuel: data.data.remainingFuel,
              lastConsumeFuel: data.data.timestamp,
            },
          })
          break
        default:
          console.error('Unknown message', data)
      }
    }

    ws.onclose = (event) => {
      console.log('Socket closed', event.reason)
      handleReconnectWS()
    }

    ws.onerror = (error) => {
      console.error('Socket error:', error)
      // handleReconnectWS()
    }

    setSocket(ws)

    return () => {
      cleanupSocket(ws)
    }
  }, [initDataRaw, userInfo])

  const cleanupSocket = (ws: WebSocket | null) => {
    if (ws) ws.close()
    setSocket(null)
    // biome-ignore lint/complexity/noForEach: <explanation>
    intervalGetPriceIds.current.forEach((id) => clearInterval(id))
    intervalGetPriceIds.current = []
    clearTimeout(reconnectTimeoutId.current)
  }

  const handleRefresh = (currentSocket: WebSocket) => {
    if (currentSocket.readyState === WebSocket.OPEN) {
      currentSocket.send(JSON.stringify({ event: 'REFRESH' }))
    } else {
      console.error('REFRESH Socket not connected')
    }
  }

  const handlePlayGame = ({ prediction, timestamp }: WSPlayGameArgs) => {
    if (socket?.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({ event: 'PLAY_GAME', data: { prediction, timestamp } }),
      )
    } else {
      console.error('PLAY_GAME Socket not connected')
    }
  }

  const handleConsumeFuel = () => {
    if (socket?.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ event: 'CONSUME_FUEL' }))
      return true
    }
    console.error('CONSUME_FUEL Socket not connected')
    return false
  }

  const handleReconnectWS = () => {
    cleanupSocket(socket)
    if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
      reconnectAttempts.current++
      reconnectTimeoutId.current = setTimeout(() => {
        console.log(
          `Attempting to reconnect... (Attempt ${reconnectAttempts.current} of ${MAX_RECONNECT_ATTEMPTS})`,
        )
        connectSocket()
      }, RECONNECT_DELAY)
    } else {
      console.error(
        `Max reconnection attempts (${MAX_RECONNECT_ATTEMPTS}) reached. Please try again later.`,
      )
      // You might want to trigger some UI feedback here to inform the user
    }
  }

  useEffect(() => {
    if (userInfo) {
      const cleanup = connectSocket()
      return cleanup
    }
  }, [connectSocket, userInfo])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        reconnectAttempts.current = 0
        console.log('App became visible, reconnecting WebSocket')
        handleReconnectWS()
      }
    }

    const handleOnline = () => {
      console.log('Network connection restored')
      isOnline.current = true
      reconnectAttempts.current = 0
      gameStore.send({ type: 'setOnline', isOnline: true })
      handleReconnectWS()
    }

    const handleOffline = () => {
      console.log('Network connection lost')
      isOnline.current = false
      gameStore.send({ type: 'setOnline', isOnline: false })
      cleanupSocket(socket)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  gameStore.send({
    type: 'setPlayGameFn',
    handlePlayGame,
  })

  return { handlePlayGame, handleConsumeFuel }
}

export { useConnectSocket }
