import { useQuery } from '@tanstack/react-query'
import { getBeraBalance } from '../utils/wallet/action'

export function useBeraBalance(walletAddress: string) {
  const { data, ...res } = useQuery({
    queryKey: ['beraBalance', walletAddress],
    queryFn: () => getBeraBalance(walletAddress),
  })

  return { balance: data ?? 0, ...res }
}
