import ArrowDownLeftYellowSvg from '@/assets/svg/arrow-down-left-yellow.svg'
import ArrowUpRightGreenSvg from '@/assets/svg/arrow-up-right-green.svg'
import { useGameStatus, useGetPrice, useMarketDirection } from '@/store/Game'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import styles from '../styles.module.css'
import { AnimatedNumber } from './AnimatedNumber'
import { Fuel } from './Fuel'
import { NextRefill } from './NextRefill'

const ArrowIcon = {
  bull: ArrowUpRightGreenSvg,
  bear: ArrowDownLeftYellowSvg,
}

const arrowVariants = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
}

export const TokenPrice = () => {
  const [number, setNumber] = useState(0.0112)
  const gameStatus = useGameStatus()
  const btcPrice = useGetPrice()
  const marketDirection = useMarketDirection()

  /**
   * @description Randomize the price of the token
   */
  useEffect(() => {
    const x = setInterval(() => {
      setNumber((pre) => {
        return pre + 1.2121 > 10 ? 0.0101 : pre + 1.2121
      })
    }, 300)

    return () => clearInterval(x)
  }, [])

  return (
    <div className="flex flex-col">
      <div className={twMerge('py-5 relative', styles.background_center)}>
        <div
          className={
            'w-full px-4 py-3 absolute top-0 left-1/2 -translate-x-1/2 flex flex-col items-center'
          }
        >
          <div className="flex gap-2 items-center justify-center">
            <p className="text-sm font-medium leading-6 tracking-tight text-center text-[#52a7e7]">
              BTC Price{' '}
              {gameStatus === 'COUNTINGDOWN' ? <span>${btcPrice}</span> : null}
            </p>

            <AnimatePresence mode="wait">
              {marketDirection && (
                <motion.div
                  key={marketDirection}
                  className="flex"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={arrowVariants}
                  transition={{ duration: 0.3 }}
                >
                  {Array.from({ length: 3 }).map((_, index) => (
                    <motion.img
                      key={index + marketDirection}
                      className="h-[12px] w-[12px]"
                      src={ArrowIcon[marketDirection]}
                      alt={
                        marketDirection === 'bull'
                          ? 'ArrowDownLeftYellow'
                          : 'ArrowUpRightGreen'
                      }
                    />
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="mb-[8px]">
            {gameStatus === 'COUNTINGDOWN' ? (
              <AnimatedNumber value={number} />
            ) : (
              <p className="h-10 text-4xl font-bold leading-10 tracking-tight text-left w-[200px]">
                ${btcPrice}
              </p>
            )}
          </div>

          <Fuel />
        </div>
      </div>

      <NextRefill />
    </div>
  )
}
