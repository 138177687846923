import { useEffect } from 'react'

export const useKeyboardVisibility = () => {
  useEffect(() => {
    const handleFocus = () => {}

    const handleBlur = () => {
      window.scrollTo(0, 0)
    }

    window.addEventListener('focusin', handleFocus)
    window.addEventListener('focusout', handleBlur)

    return () => {
      window.removeEventListener('focusin', handleFocus)
      window.removeEventListener('focusout', handleBlur)
    }
  }, [])
}
