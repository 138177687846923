import { useQuery } from '@tanstack/react-query'
import type { Token } from '../types'
import { getBalance } from '../utils/wallet/action'

export function useTokenBalance(walletAddress: string, token: Token) {
  const { data, ...res } = useQuery({
    queryKey: ['tokenBalance', token?.contract_address, walletAddress],
    queryFn: () => getBalance(walletAddress, token),
    enabled: !!token,
  })

  return { balance: data, ...res }
}
