import type { WalletAccount } from '@/libs/types'
import { createStore } from '@xstate/store'
import { isAddressEqual } from 'viem'

const walletStore = createStore(
  {
    activeAccount: null as WalletAccount | null,
    accounts: [] as WalletAccount[],
    mnemonicGenerated: '',
  },
  {
    initData: (
      _,
      {
        activeAccount,
        accounts,
      }: { activeAccount: WalletAccount; accounts: WalletAccount[] },
    ) => ({
      accounts,
      activeAccount: activeAccount,
    }),

    addWallet: (context, { account }: { account: WalletAccount }) => ({
      accounts: [...context.accounts, account],
      activeAccount: account,
      mnemonicGenerated: '',
    }),
    removeWallet: (context, { account }: { account: WalletAccount }) => ({
      accounts: context.accounts.filter(
        (acc) => !isAddressEqual(acc.address, account.address),
      ),
    }),
    activeWallet: (_, { account }: { account: WalletAccount }) => ({
      activeAccount: account,
    }),
    setMnemonic: (_, { mnemonic }: { mnemonic: string }) => ({
      mnemonicGenerated: mnemonic,
    }),
    deleteAccount: (context, { account }: { account: WalletAccount }) => {
      const accounts = context.accounts.filter(
        (acc) => !isAddressEqual(acc.address, account.address),
      )
      return {
        accounts,
        activeAccount: accounts[0] || null,
      }
    },
  },
)

export { walletStore }
