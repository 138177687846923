import axios from 'axios'
import { getInitDataRaw } from '../utils/initData'

const request = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 60 * 1000,
})

const initDataRaw = getInitDataRaw()

request.interceptors.request.use(
  (config) => {
    config.headers.Accept = 'application/json'
    config.headers.Authorization = `tma ${initDataRaw}`

    return config
  },
  (error) => Promise.reject(error),
)

// Thêm interceptor cho phản hồi để xử lý lỗi
request.interceptors.response.use(
  (response) => response,
  (error) => {
    // Xử lý lỗi
    if (error.response) {
      console.error('Server Error:', error.response.status, error.response.data)
      switch (error.response.status) {
        case 401:
          // alert("Unauthorized access. Please log in.");
          break
        case 403:
          // alert("You do not have permission to perform this action.");
          break
        case 404:
          // alert("The requested resource was not found.");
          break
        case 500:
          // alert("An internal server error occurred. Please try again later.");
          break
        default:
        // alert("An error occurred. Please try again.");
      }
    } else if (error.request) {
      console.log('Network Error:', error.message)
      // alert("Network error. Please check your internet connection.");
    } else {
      // Lỗi khác
      console.log('Error:', error.message)
      // alert("An error occurred. Please try again.");
    }
    return Promise.reject(error)
  },
)
export default request
