import Chart from '@/assets/svg/chart.svg'
import Switch from '@/assets/svg/switch.svg'
import Wallet from '@/assets/svg/wallet.svg'
import {
  useMemeTokenBalance,
  useNativeTokenBalance,
  useWalletAddress,
} from '@/libs/hooks'
import type { MemeToken } from '@/libs/types'
import {
  buyMemeToken,
  calculateCurvedBurnReturn,
  calculateCurvedMintReturn,
  sellMemeToken,
} from '@/libs/utils/wallet/meme'
import { transactionStore } from '@/store/TransactionStore'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { debounce } from 'es-toolkit'
import { useEffect, useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { privateKeyToAccount } from 'viem/accounts'
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import { SwapTab } from '../TabTask/TaskSwap'
import { TradeSidebar } from './TradeSidebar'

interface Props {
  tokenSelect: MemeToken
}

const TradePlace = (props: Props) => {
  const { tokenSelect } = props
  const navigate = useNavigate()
  const walletAddress = useWalletAddress()
  const queryClient = useQueryClient()

  const { balance: balanceNativeToken } = useNativeTokenBalance(
    walletAddress as string,
  )

  const { balance } = useMemeTokenBalance(walletAddress as string, tokenSelect)
  const [tab, setTab] = useState<number>(0)
  const [price, setPrice] = useState<string>('0')
  const [calculatorPrice, setCalculatorPrice] = useState<string>('0')
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)
  const [txHash, setTxHash] = useState<string | undefined>()

  const {
    isPending: isSendingTx,
    error: errSendTx,
    writeContractAsync,
  } = useWriteContract()

  const { data: transactionReceipt, isFetching: isFetchingTxReceipt } =
    useWaitForTransactionReceipt({
      hash: txHash as `0x${string}`,
      confirmations: 1,
    })

  const handleChangeTab = (value: number) => {
    setTab(value)
    setPrice('0')
    setCalculatorPrice('0')
  }

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    // remove commas
    value = value.replace(/,/g, '')
    // Only allow digits and at most one dot
    value = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1')
    // If the value starts with a dot, prepend a zero
    if (value.startsWith('.')) {
      value = `0${value}`
    }
    setPrice(value)
    const debouncedFunction = debounce(() => {
      calculatingAmountBuy(value)
    }, 300)
    debouncedFunction()
  }

  const buyMeme = async () => {
    try {
      const request = await buyMemeToken({
        tokenAddress: tokenSelect?.address,
        buyAmount: Number.parseFloat(price),
        signer: signer,
      })
      const hash = await writeContractAsync({ ...request, account: signer })
      setTxHash(hash)
    } catch (error) {
      console.log(111, error)
      toast.error('Transaction failed, please try again', {
        duration: 3000,
      })
    }
  }

  const sellMeme = async () => {
    try {
      const request = await sellMemeToken({
        tokenAddress: tokenSelect?.address,
        sellAmount: Number.parseFloat(price),
        signer: signer,
      })
      const hash = await writeContractAsync({ ...request, account: signer })
      setTxHash(hash)
    } catch (error) {
      toast.error('Transaction failed, please try again', {
        duration: 3000,
      })
    }
  }

  const calculatingAmountBuy = async (value: string) => {
    try {
      if (tab === 0) {
        const request = await calculateCurvedMintReturn({
          buyAmount: Number.parseFloat(value),
          tokenAddress: tokenSelect.address,
        })
        if (request) {
          setCalculatorPrice(
            `${Number.parseFloat(request).toFixed(4)} ${tokenSelect.symbol}`,
          )
        }
      }
      if (tab === 1) {
        const request = await calculateCurvedBurnReturn({
          burnAmount: Number.parseFloat(value),
          tokenAddress: tokenSelect.address,
        })
        if (request) {
          setCalculatorPrice(`${Number.parseFloat(request).toFixed(4)} BERA`)
        }
      }
    } catch (error) {
      console.log(error)
      setCalculatorPrice('0')
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (transactionReceipt) {
      toast.success('Transaction successfully!')
      transactionStore.send({
        type: 'addBeraPumpTransaction',
        txData: transactionReceipt.transactionHash,
      })
      setPrice('0')
      setCalculatorPrice('0')
      setTxHash(undefined)
      queryClient.invalidateQueries({ queryKey: ['memeTokenBalance'] })
      queryClient.invalidateQueries({ queryKey: ['nativeTokenBalance'] })
    }
  }, [transactionReceipt])

  useEffect(() => {
    if (errSendTx) {
      toast.error('Transaction failed, please try again', {
        duration: 3000,
      })
    }
  }, [errSendTx])

  const disableButton = useMemo(() => {
    let result = false
    if (price === '0' || price === '') {
      result = true
    }
    return result
  }, [price, tokenSelect])

  const redirectToBex = () => {
    navigate({
      pathname: 'swap',
      search: createSearchParams({
        activeTab: SwapTab.Swap.toString(),
        tokenFromAddress: '0x0000000000000000000000000000000000000000',
        tokenToAddress: tokenSelect.address,
      }).toString(),
    })
  }

  const handleClickBtn = () => {
    if (!tokenSelect.is_sale) {
      redirectToBex()
    } else if (tab === 0) {
      buyMeme()
    } else {
      sellMeme()
    }
  }

  const loadingBtn = isSendingTx || isFetchingTxReceipt

  return (
    <div
      className="py-[12px] px-[16px] rounded-[16px] bg-[#52A7E71F] flex flex-col gap-[20px] items-center justify-start"
      id="trading"
    >
      <TradeSidebar tab={tab} handleChangeTab={handleChangeTab} />
      <div className="rounded-[12px] bg-[#003F60] p-[16px] flex items-center justify-between w-full">
        <div className="flex flex-col justify-between">
          <NumericFormat
            // autoFocus
            allowedDecimalSeparators={['.', ',']}
            thousandSeparator=","
            allowNegative={false}
            allowLeadingZeros={false}
            value={price}
            onChange={handleChangeAmount}
            inputMode="decimal"
            placeholder="0.00"
            className="focus:border-1 flex h-10 rounded-md border-border text-foreground file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-[#ffffffa0] focus:border-foreground focus:outline-none disabled:cursor-not-allowed disabled:text-muted-foreground ring-offset-none w-full grow border-0 bg-transparent p-0 text-left text-lg font-semibold shadow-none outline-none ring-0 drop-shadow-none focus-visible:ring-0 focus-visible:ring-offset-0"
          />
          <p className="text-[#ffffffa0] text-[12px] font-[400]">
            {calculatorPrice}
          </p>
        </div>
        <div className="flex flex-col gap-[10px] justify-between">
          <div className="h-10 flex items-center justify-center gap-[10px] min-w-[140px]">
            <img
              src={
                tab === 0
                  ? 'https://app.bearcage.xyz/external-assets/telegram-images/%24BERA.png'
                  : tokenSelect?.image_url
              }
              alt="bera coin"
              className="w-[24px] h-[24px] rounded-full"
            />
            <p className="text-[#ffffff] text-[16px] font-bold text-ellipsis  overflow-hidden whitespace-nowrap">
              {tab === 0 ? 'BERA' : tokenSelect?.name}
            </p>
            <img src={Switch} alt="bera coin" className="w-[20px] h-[20px]" />
          </div>
          <div className="flex items-center justify-end gap-[5px]">
            <img src={Wallet} alt="wallet" className="w-[16px] h-[16px]" />
            <p className="text-[#ffffffa0] text-[12px] font-[400]">
              {tab === 0
                ? Math.floor(Number(balanceNativeToken) * 1e8) / 1e8
                : balance}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-[20px] w-full">
        <img src={Chart} className="w-[24px] h-[24px]" alt="chart" />
        <button
          disabled={loadingBtn || disableButton}
          className={`${loadingBtn || disableButton ? 'border-[#FAC80833] bg-[#8C6F0033]' : 'border-[#FAC808] bg-[#8C6F00]'} border-[1.5px] border-solid rounded-[16px] p-[10px] w-[80%] flex items-center justify-center gap-[10px]`}
          onClick={handleClickBtn}
        >
          <p className="text-[#ffffff] text-[16px] font-[700] text-center">
            {tokenSelect.is_sale ? 'Place Trade' : 'Selling'}
          </p>
          {loadingBtn && (
            <svg
              aria-hidden="true"
              className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  )
}

export { TradePlace }
