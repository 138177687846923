import BeraBondsVaultAbi from '@/libs/evm/abi/BeraBondsVault.json'
import type { UserSnapShot } from '@/libs/types'
import {
  http,
  type PrivateKeyAccount,
  createPublicClient,
  erc721Abi,
} from 'viem'
import { berachainTestnetbArtio, mainnet } from 'viem/chains'
import { contractAddressList } from '../contractAddress'

const publicClient = createPublicClient({
  chain:
    import.meta.env.VITE_NODE_ENV === 'production'
      ? mainnet
      : berachainTestnetbArtio,
  transport: http(),
})

export async function checkIsApproved({
  walletAddress,
}: {
  walletAddress: string
}) {
  const result = await publicClient.readContract({
    address: contractAddressList.BeraBonds,
    abi: erc721Abi,
    functionName: 'isApprovedForAll',
    args: [walletAddress as `0x${string}`, contractAddressList.BeraBondsVault],
  })
  return result
}

export async function approveSendNft({
  signer,
}: {
  signer: PrivateKeyAccount
}) {
  const { request } = await publicClient.simulateContract({
    address: contractAddressList.BeraBonds,
    abi: erc721Abi,
    functionName: 'setApprovalForAll',
    args: [contractAddressList.BeraBondsVault, true],
    account: signer,
  })
  return request
}

export async function stakeNft({
  userSnapshot,
  signature,
  signer,
}: {
  userSnapshot: UserSnapShot
  signature: string
  signer: PrivateKeyAccount
}) {
  const formatUserSnapshot = {
    userId: BigInt(userSnapshot.userId),
    tokenId: BigInt(userSnapshot.tokenId),
    totalBeca: BigInt(userSnapshot.totalBeca),
    totalTokenCreated: BigInt(userSnapshot.totalTokenCreated),
    totalBeraVolume: BigInt(userSnapshot.totalBeraVolume),
    totalTransactions: BigInt(userSnapshot.totalTransactions),
    totalCompletedToken: BigInt(userSnapshot.totalCompletedToken),
  }
  const { request } = await publicClient.simulateContract({
    address: contractAddressList.BeraBondsVault,
    abi: BeraBondsVaultAbi,
    functionName: 'stakeNft',
    args: [formatUserSnapshot, signature],
    account: signer,
  })
  return request
}

export async function unStakeNft({
  nftId,
  signer,
}: {
  nftId: number
  signer: PrivateKeyAccount
}) {
  const { request } = await publicClient.simulateContract({
    address: contractAddressList.BeraBondsVault,
    abi: BeraBondsVaultAbi,
    functionName: 'unstakeNft',
    args: [nftId],
    account: signer,
  })
  return request
}

export async function checkIsStaked(userId: string) {
  const result = await publicClient.readContract({
    address: contractAddressList.BeraBondsVault,
    abi: BeraBondsVaultAbi,
    functionName: 'isStaked',
    args: [userId],
  })
  console.log({ result })
  return result
}

export async function getVerifier() {
  const result = await publicClient.readContract({
    address: contractAddressList.BeraBondsVault,
    abi: BeraBondsVaultAbi,
    functionName: 'verifier',
  })
  console.log({ result })
  return result
}

export async function getStakedNft(walletAddress: string) {
  const nftIds = (await publicClient.readContract({
    address: contractAddressList.BeraBondsVault,
    abi: BeraBondsVaultAbi,
    functionName: 'getStakedList',
    args: [walletAddress],
  })) as string[]
  const formattedNftId = nftIds.map((id) => Number(id))
  return formattedNftId
}
