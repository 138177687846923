import type { User } from '@/libs/types'
import { createStore } from '@xstate/store'

const authStore = createStore(
  {
    user: null as User | null,
  },
  {
    setUser: (_, { user }: { user: User }) => ({
      user: user,
    }),
  },
)

export { authStore }
