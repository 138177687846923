import { authStore } from '@/store/AuthStore'
import { useQuery } from '@tanstack/react-query'
import { useInitData } from '@telegram-apps/sdk-react'
import { useEffect } from 'react'
import { getMe } from '../utils/user/getMe'

export function useProfile() {
  const initData = useInitData()

  const startParam = initData?.startParam

  const { data, ...res } = useQuery({
    queryKey: ['me', startParam],
    queryFn: () => getMe(startParam),
  })

  useEffect(() => {
    if (data) {
      authStore.send({
        type: 'setUser',
        user: data,
      })
    }
  }, [data])

  return { userInfo: data ?? null, ...res }
}
