import CloseModalSvg from '@/assets/svg/close-modal.svg'
import SuccessSvg from '@/assets/svg/transaction-success.svg'
import { Button as ButtonAria, Text } from 'react-aria-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { Drawer } from 'vaul'

type Props = {
  title: string
  open: boolean
  setOpen: (open: boolean) => void
  isSendingTx: boolean
  onClose: () => void
}

const ModalConfirmTransaction: React.FC<Props> = ({
  open,
  setOpen,
  title,
  isSendingTx = true,
  onClose,
}) => {
  return (
    <Drawer.Root
      shouldScaleBackground={false}
      setBackgroundColorOnScale={false}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-blue/40 backdrop-blur-sm" />
        <Drawer.Content className=" flex flex-col rounded-t-[10px] h-[40%] mt-24 fixed bottom-0 left-0 right-0 ">
          <div className="bg-[#003F60] rounded-2xl flex-1">
            <div className="flex flex-col max-w-md mx-auto gap-5">
              <Drawer.Title className="flex justify-between font-medium border-b border-b-[#1A5E90] px-4 py-[14px]">
                <Text className="text-xl text-white font-semibold">
                  {title}
                </Text>
                <ButtonAria onPress={onClose}>
                  <img
                    src={CloseModalSvg}
                    className="w-[28px] h-[28px]"
                    alt="Close"
                  />
                </ButtonAria>
              </Drawer.Title>
              <div className="h-screen flex flex-1 justify-center items-center">
                {isSendingTx ? (
                  <ClipLoader
                    color={'#FFDE5E'}
                    loading={true}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    size={100}
                  />
                ) : (
                  <img src={SuccessSvg} alt="success" className="w-16 h-16" />
                )}
              </div>
              {isSendingTx ? (
                <Text className="text-center text-yellow text-xl font-semibold">
                  Processing...
                </Text>
              ) : (
                <Text className="text-center text-white text-xl font-semibold">
                  Successful!
                </Text>
              )}
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}

export { ModalConfirmTransaction }
