import { useProfile, useWarningClose } from '@/libs/hooks'
import { useSetupApp } from '@/libs/hooks/useSetupApp'
import { routes } from '@/navigation/routes.tsx'
import { useIntegration } from '@telegram-apps/react-router-integration'
import {
  initNavigator,
  useLaunchParams,
  useMiniApp,
} from '@telegram-apps/sdk-react'
import { AppRoot } from '@telegram-apps/telegram-ui'
import { type FC, useEffect, useMemo } from 'react'
import { Navigate, Route, Router, Routes } from 'react-router-dom'

export const App: FC = () => {
  const lp = useLaunchParams()
  const miniApp = useMiniApp()

  // Create a new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator('app-navigation-state'), [])
  const [location, reactNavigator] = useIntegration(navigator)

  useWarningClose()
  useProfile()
  useSetupApp(reactNavigator)

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach()
    return () => navigator.detach()
  }, [navigator])

  return (
    <AppRoot
      appearance={miniApp.isDark ? 'dark' : 'light'}
      platform={['macos', 'ios'].includes(lp.platform) ? 'ios' : 'base'}
    >
      <Router location={location} navigator={reactNavigator}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AppRoot>
  )
}
