import { useMutation } from '@tanstack/react-query'
import { useInitData } from '@telegram-apps/sdk-react'
import type { AxiosError } from 'axios'
import { createOrUpdateWallet } from '../utils/user/createOrUpdateWallet'

export const useCreateWalletMutation = () => {
  const initData = useInitData()
  const startParam = initData?.startParam

  return useMutation({
    mutationFn: (data: {
      walletAddress: string
      createType: 'create' | 'import'
    }) => createOrUpdateWallet({ ...data, refCode: startParam }),
    onError: (err: AxiosError<{ errors?: Record<string, string> }>) => {
      const errors = err.response?.data?.errors
      if (!errors) return

      console.log('update ref error', errors)
    },
  })
}
