import WalletDefaultAvt from '@/assets/Images/wallet-default.png'
import CheckedSvg from '@/assets/svg/ic-checked.svg'
import WalletConnectSvg from '@/assets/svg/walletconnect.svg'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import { useWalletAddress } from '@/libs/hooks'
import type { WalletAccount } from '@/libs/types'
import { formatWalletAddress } from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { Button, Text } from 'react-aria-components'
import { isAddressEqual } from 'viem'
import { useAccount, useConnect, useDisconnect } from 'wagmi'

type ListWalletProps = {
  open: boolean
  setOpen: (open: boolean) => void
}

const ChooseWallets: React.FC<ListWalletProps> = ({ open, setOpen }) => {
  const activeWalletAddress = useWalletAddress()
  const { accounts } = useSelector(walletStore, (state) => state.context)
  const { address, isConnected: isConnectedWithWC } = useAccount()
  const { connectors, connect } = useConnect()
  const { disconnect } = useDisconnect()
  const queryClient = useQueryClient()
  const activeWallet = (account: WalletAccount) => {
    if (isConnectedWithWC) {
      disconnect()
    }
    walletStore.send({
      type: 'activeWallet',
      account,
    })
    queryClient.invalidateQueries({
      queryKey: ['list-tokens'],
    })

    setOpen(false)
  }

  const connectWallet = () => {
    connect({ connector: connectors[0] })
    setOpen(false)
  }

  return (
    <>
      <BottomSheetModal
        open={open}
        title="Switch wallet"
        onClose={() => setOpen(false)}
        heightPercent={0.52}
      >
        <div className="flex flex-col px-4 py-5 gap-3">
          {accounts.map((account) => {
            const isActiveWallet =
              isAddressEqual(
                activeWalletAddress as `0x${string}`,
                account.address,
              ) && !isConnectedWithWC
            return (
              <Button
                key={account.address}
                className={`flex items-center justify-between px-3 py-4 border-[1.5px] rounded-xl ${
                  isActiveWallet ? 'border-yellow' : 'border-white'
                }
             )}`}
                onPress={() => activeWallet(account)}
              >
                <div className="flex items-center">
                  <img
                    src={WalletDefaultAvt}
                    alt="wallet"
                    className="w-6 h-6"
                  />
                  <div className="flex flex-col ml-3">
                    <Text className="text-[14px] font-semibold text-left">
                      {account.name}
                    </Text>
                    <Text className="text-[12px] font-semibold opacity-64">
                      {formatWalletAddress(account.address)}
                    </Text>
                  </div>
                </div>
                {isActiveWallet && (
                  <img src={CheckedSvg} alt="checked" className="w-6 h-6" />
                )}
              </Button>
            )
          })}
          <Button
            key="wallet-connect"
            className={`flex items-center justify-between px-3 py-4 border-[1.5px] rounded-xl ${
              isConnectedWithWC ? 'border-yellow' : 'border-white'
            }
             )}`}
            onPress={connectWallet}
          >
            <div className="flex items-center">
              <img src={WalletConnectSvg} alt="wallet" className="w-6 h-6" />
              <div className="flex flex-col ml-3">
                <Text className="text-[14px] font-semibold text-left">
                  Wallet Connect
                </Text>
                {isConnectedWithWC && (
                  <Text className="text-[12px] font-semibold opacity-64">
                    {formatWalletAddress(address as string)}
                  </Text>
                )}
              </div>
            </div>
            {isConnectedWithWC && (
              <img src={CheckedSvg} alt="checked" className="w-6 h-6" />
            )}
          </Button>
        </div>
      </BottomSheetModal>
    </>
  )
}

export { ChooseWallets }
