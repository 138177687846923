import BearGif from '@/assets/gifs/bear.gif'
import StackImage from '@/assets/svg/Stack-Image.svg'
import PlaySvg from '@/assets/svg/play-ic.svg'
import PumpSvg from '@/assets/svg/pump.svg'
import StackIconBanner from '@/assets/svg/stack-icon-banner.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import BearPumpImg from '@/assets/webps/bera-pump.webp'
import { WalletButton } from '@/components/Button'
import { Tabs } from '@/libs/types'
import { BERA_LOGO } from '@/libs/utils/constans'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import { bottomTabStore } from '@/store/BottomTabStore'
import { useNavigate } from 'react-router-dom'

interface WalletBannersProps {
  point: number
  beraBalance: string | number
}

export const useWalletBanners = ({
  point,
  beraBalance,
}: WalletBannersProps) => {
  const navigate = useNavigate()
  // const { mutateAsync: turnOnNotify } = useMutationTurnOnNotify()
  // const queryClient = useQueryClient()
  // const utils = useUtils()

  const redirectToGame = () => {
    handleReactGASendEvent('PlayGameButtonClick')
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Game,
    })
  }

  const redirectToPump = () => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab: Tabs.Meme,
    })
  }

  // const turnOnNotifyHandler = async () => {
  //   await turnOnNotify()
  //   queryClient.invalidateQueries({
  //     queryKey: ['whitelist-info'],
  //   })
  //   utils.openLink('https://discord.com/invite/bearcage')
  // }

  const slideData = [
    // {
    //   id: 4,
    //   title: 'BeraBonds',
    //   description: 'The Only 3,333 Proof of BearCage Contributors',
    //   imageUrl: NotificationImage,
    //   className: 'top-3 right-[0px] w-[190px]',
    //   balance: point,
    //   logo: BERA_LOGO,
    //   backgroundColor: 'bg-[#FFA27B]',
    //   btnComponent: (
    //     <>
    //       <WalletButton
    //         className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm flex items-center justify-center gap-[5px]"
    //         color="brown"
    //         onPress={() => navigate({ pathname: '/mint' })}
    //       >
    //         <p className="m-[0px] p-[0px]">Mint Now</p>
    //         <img src={Hammer} alt="hammer" className="w-[20px] h-[20px]" />
    //       </WalletButton>
    //     </>
    //   ),
    //   customBalance: (
    //     <div className="flex mt-1 items-center">
    //       <img src={BlueTick} alt="point" className="w-[16px] h-[16px]" />
    //       <div className="flex flex-col items-start justify-start">
    //         <Text className="text-rgba font-[700] text-[12px] ml-1">
    //           {getEligibleText()}
    //         </Text>
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   id: 5,
    //   title: 'BeraBonds',
    //   description: 'The Only 3,333 Proof of BearCage Contributors',
    //   imageUrl: NotificationImage,
    //   className: 'top-3 right-[0px] w-[190px]',
    //   balance: point,
    //   logo: BERA_LOGO,
    //   backgroundColor: 'bg-[#FFA27B]',
    //   btnComponent: (
    //     <>
    //       <WalletButton
    //         className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm flex items-center justify-center gap-[5px]"
    //         color="brown"
    //         onPress={() => navigate({ pathname: '/mint' })}
    //       >
    //         <p className="m-[0px] p-[0px]">Eligible Check</p>
    //       </WalletButton>
    //     </>
    //   ),
    //   customBalance: <div className="flex mt-1 items-center" />,
    // },
    {
      id: 6,
      title: 'BeraBonds',
      description: 'The Only 3,333 Proof of BearCage Contributors',
      imageUrl: StackImage,
      className: 'top-3 right-[0px] w-[190px]',
      balance: point,
      logo: BERA_LOGO,
      backgroundColor: 'bg-[#FFA27B]',
      btnComponent: (
        <>
          <WalletButton
            className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm flex items-center justify-center gap-[5px]"
            color="brown"
            rightIcon={
              <img
                src={StackIconBanner}
                alt="stack icon banner"
                className="w-5 h-5"
              />
            }
            onPress={() => navigate({ pathname: '/stake' })}
          >
            <p className="m-[0px] p-[0px]">Stake</p>
          </WalletButton>
        </>
      ),
      customBalance: <div className="flex mt-1 items-center" />,
    },
    {
      id: 1,
      title: 'Pump On',
      description: 'Earn $BERA by pumping your own token now!',
      imageUrl: BearPumpImg,
      className: 'top-2 right-[0px] w-[200px]',
      balance: beraBalance,
      logo: BERA_LOGO,
      btnComponent: (
        <WalletButton
          className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm"
          color="brown"
          rightIcon={<img src={PumpSvg} alt="pump" className="w-5 h-5" />}
          onPress={redirectToPump}
        >
          Pump Now
        </WalletButton>
      ),
    },
    {
      id: 2,
      title: 'Bear Hunting',
      description: 'Guess BTC price and earn $BECA now!',
      imageUrl: BearGif,
      balance: point,
      logo: BeraCoinImg,
      btnComponent: (
        <WalletButton
          className="absolute bottom-3 right-[14px] text-[#44180B] h-8 rounded-xl text-xs shadow-sm"
          color="white"
          leftIcon={<img src={PlaySvg} alt="play" className="w-4 h-4" />}
          onPress={redirectToGame}
        >
          Play Game
        </WalletButton>
      ),
    },
  ]

  // const BannerClaim = {
  //   id: 3,
  //   title: 'BeraBonds',
  //   description: 'The Only 3,333 Proof of BearCage Contributors',
  //   imageUrl: WLImage,
  //   className: 'top-2 right-[0px] w-[190px]',
  //   balance: point,
  //   logo: BERA_LOGO,
  //   btnComponent: (
  //     <WalletButton
  //       className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm"
  //       color="brown"
  //       rightIcon={<img src={PumpSvg} alt="pump" className="w-5 h-5" />}
  //       onPress={() => navigate('/claim')}
  //     >
  //       Claim WL
  //     </WalletButton>
  //   ),
  //   customBalance: (
  //     <div className="flex mt-1 items-center">
  //       <img src={BeraCoinImg} alt="point" className="w-[20px] h-[20px]" />
  //       <Text className="text-rgba font-[700] text-[14px] ml-1">
  //         {formatNumber(point, 0)} $BECA
  //       </Text>
  //     </div>
  //   ),
  // }

  // const BannerGrab = {
  //   id: 3,
  //   title: 'BeraBonds',
  //   description: 'The Only 3,333 Proof of BearCage Contributors',
  //   imageUrl: WLImage,
  //   className: 'top-2 right-[0px] w-[190px]',
  //   balance: point,
  //   logo: BERA_LOGO,
  //   btnComponent: (
  //     <WalletButton
  //       className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm"
  //       color="brown"
  //       onPress={() => {
  //         bottomTabStore.send({
  //           type: 'setCurrentTab',
  //           tab: Tabs.Tasks,
  //         })
  //       }}
  //     >
  //       Grab more $BECA
  //     </WalletButton>
  //   ),
  //   customBalance: (
  //     <div className="flex mt-1 items-center">
  //       <img src={BeraCoinImg} alt="point" className="w-[20px] h-[20px]" />
  //       <div className="flex flex-col items-start justify-start">
  //         <Text className="text-rgba font-[700] text-[14px] ml-1">
  //           {formatNumber(point, 0)} $BECA
  //         </Text>
  //         {Number(whiteListInfo?.requirementPoint) > Number(point) && (
  //           <Text className="text-rgba font-[400] text-[10px] ml-1">
  //             Need{' '}
  //             {formatNumber(
  //               Number(whiteListInfo?.requirementPoint) - Number(point),
  //               0,
  //             )}{' '}
  //             more
  //           </Text>
  //         )}
  //       </div>
  //     </div>
  //   ),
  // }

  // const NotificationStatus = {
  //   id: 3,
  //   title: 'BeraBonds',
  //   description: 'The Only 3,333 Proof of \nBearCage Contributors',
  //   imageUrl: NotificationImage,
  //   className: 'top-3 right-[0px] w-[190px]',
  //   balance: point,
  //   logo: BERA_LOGO,
  //   backgroundColor: 'bg-[#FFA27B]',
  //   btnComponent: (
  //     <WalletButton
  //       className="absolute bottom-3 right-[14px] text-brown h-8 rounded-xl text-[15px] shadow-sm"
  //       color="brown"
  //       onPress={turnOnNotifyHandler}
  //     >
  //       Turn On Notification
  //     </WalletButton>
  //   ),
  //   customBalance: (
  //     <div className="flex mt-[10px] items-center">
  //       <img src={BlueTick} alt="point" className="w-[16px] h-[16px]" />
  //       <div className="flex flex-col items-start justify-start">
  //         <Text className="text-rgba font-[700] text-[12px] ml-1">
  //           Eligible For WL (GTD)
  //         </Text>
  //       </div>
  //     </div>
  //   ),
  // }

  const getActiveBanner = () => {
    return null
  }

  const getSlides = () => {
    const activeBanner = getActiveBanner()
    if (activeBanner) {
      return [activeBanner, ...slideData]
    }
    return slideData
  }

  return { getSlides }
}
