import { z } from 'zod'

export const createMemeSchema = z.object({
  name: z.string().min(1),
  symbol: z.string().min(1),
  description: z.string().optional(),
  website: z.string().optional(),
  x_url: z.string().optional(),
  telegram_url: z.string().optional(),
  owner_address: z.string().min(1),
})

export type TCreateMemeSchema = z.infer<typeof createMemeSchema>
