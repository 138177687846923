import CloseModalSvg from '@/assets/svg/close-modal.svg'
import { useViewport } from '@telegram-apps/sdk-react'
import { Button as ButtonAria, Text } from 'react-aria-components'
import { Drawer } from 'vaul'

type Props = {
  title: string
  open: boolean
  onClose: () => void
  children: React.ReactNode
  heightPercent?: number
}

const BottomSheetModal: React.FC<Props> = ({
  open,
  title,
  onClose,
  children,
  heightPercent = 0.4,
}) => {
  const vp = useViewport()
  const height = vp?.height && vp.height * heightPercent
  return (
    <Drawer.Root
      shouldScaleBackground={false}
      setBackgroundColorOnScale={false}
      noBodyStyles
      open={open}
      onClose={onClose}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-blue/40 backdrop-blur-sm z-50" />
        <Drawer.Content
          className=" flex flex-col rounded-t-[10px] fixed bottom-5 left-3 right-3 z-50"
          style={{
            height: height,
          }}
        >
          <div className="bg-[#003F60] rounded-2xl h-full overflow-hidden">
            <div className="flex flex-col max-w-md mx-auto">
              <Drawer.Title className="flex justify-between font-medium border-b border-b-[#1A5E90] px-4 py-[14px]">
                <Text className="text-xl text-white font-semibold">
                  {title}
                </Text>
                <ButtonAria onPress={onClose}>
                  <img
                    src={CloseModalSvg}
                    className="w-[28px] h-[28px]"
                    alt="Close"
                  />
                </ButtonAria>
              </Drawer.Title>
              <div
                className="overflow-y-auto"
                style={{
                  height: `calc(${height}px - 64px)`,
                }}
              >
                {children}
              </div>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}

export { BottomSheetModal }
