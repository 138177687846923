interface Props {
  tab: number,
  handleChangeTab:(value: number) =>  void
}

export function TradeSidebar (props: Props) {
  const SidebarOption = [
     'Buy', 'Sell'
  ]
  return (<div className="w-full rounded-[24px] h-[50px] bg-[#003F60] flex p-[5px]">
      {SidebarOption.map((element: string, index: number) => {
        const colorBackground = index === props.tab ? index === 0 ? "bg-[#52C41A]" : "bg-[#F5222D]" : "bg-[transparent]"
          return (
              <div key={element} onClick={() => props.handleChangeTab(index)} onKeyDown={() => {}} className={`rounded-3xl w-3/6 flex justify-center items-center ${colorBackground}`} >
                  <p className="text-sm font-bold text-[#ffffff] uppercase">{element}</p>
              </div>
          )
      })}
  </div>)
}